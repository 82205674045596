import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  Grid,
  MenuItem,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { cross_default_icon } from "../../constants/icons";
import IconButtons from "../../components/IconButtons/IconButtons";
import SimpleAutoComplete from "../../components/SimpleAutoComplete/SimpleAutoComplete";
import { status } from "./AttendeeUtils";
import { font_13_icon } from "../../utils/utils";
import AutoCompleteTagInput from "../../components/AutoCompleteTagInput/AutoCompleteTagInput";
import { useSelector } from "react-redux";

const FilterAttendee = ({
  open,
  onClose,
  filterAttendees,
  handleChangeFilterAttendee,
  handleFilterAttendeeSubmit,
  handleFilterAttendeeRemove,
  isModuleId,
  filtersForAttendees,
  isHr,
  isSPOC,
  isMentor,
}) => {
  const statusOptions = useSelector(
    (state) => state?.utility?.constants?.attendeeStatuses
  );
  const exerciseStatus = useSelector(
    (state) => state?.utility?.constants?.sessionExerciseStatuses
  );
  return (
    <>
      <Drawer
        className="filter-drawer-container"
        anchor="right"
        open={open}
        onClose={onClose}
      >
        <Grid container className="filter-main-container">
          <Grid item md={12} pt={2} className="drawer-input-container">
            <Box className="filter-head-close">
              <IconButtons
                handleClick={onClose}
                height={font_13_icon}
                width={font_13_icon}
                image={cross_default_icon}
                tooltip="Close Filter"
              />
            </Box>
            <Box className="filter-head filter-padding">
              <Typography variant="font_16" className="filter-title ">
                Filters
              </Typography>
            </Box>
            <Box className="filter-padding textfield-box">
              <TextField
                size="small"
                label="Attendee Name/Email"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                value={filterAttendees?.attendeeName}
                onChange={(e) =>
                  handleChangeFilterAttendee("attendeeName", e?.target?.value)
                }
              />
              <TextField
                disabled={!isSPOC && isMentor}
                size="small"
                label="Mentor Name/Email"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                value={filterAttendees?.mentorName}
                onChange={(e) =>
                  handleChangeFilterAttendee("mentorName", e?.target?.value)
                }
              />
              <SimpleAutoComplete
                label="Status"
                placeholder="Select Status"
                options={statusOptions}
                onChange={(event, newValue) =>
                  handleChangeFilterAttendee("status", newValue)
                }
                getOptionLabel={(option) => option?.value}
                value={filterAttendees?.status}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.value === filterAttendees?.status?.value
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.value}`}</Box>
                      </Box>
                    </li>
                  );
                }}
              />
              {isModuleId ? (
                <>
                  <Box mb={1} mt={1} className="width-100">
                    <AutoCompleteTagInput
                      label="Exercise Status"
                      placeholder={
                        filterAttendees?.exerciseStatuses?.length
                          ? null
                          : "Select Exercise Status"
                      }
                      options={exerciseStatus}
                      selectedValue={filterAttendees?.exerciseStatuses}
                      getOptionLabel={(option) => option?.value}
                      filterOptions={(options, params) => {
                        // <<<--- inject the Select All option
                        const filter = createFilterOptions();
                        const filtered = filter(options, params);
                        if (exerciseStatus.length === 0) {
                          return [{ value: "No options", all: false }];
                        }
                        return [
                          { value: "Select All", all: true },
                          ...filtered,
                        ];
                      }}
                      onChange={(event, newValue) => {
                        const idCounts = newValue.reduce((counts, { key }) => {
                          counts[key] = (counts[key] || 0) + 1;
                          return counts;
                        }, {});
                        const uniqueNewValue = newValue.filter(({ key }) => {
                          return idCounts[key] === 1;
                        });
                        if (newValue.find((option) => option.all)) {
                          return handleChangeFilterAttendee(
                            "exerciseStatuses",
                            filterAttendees?.exerciseStatuses?.length ===
                              exerciseStatus?.length
                              ? []
                              : exerciseStatus
                          );
                        }
                        handleChangeFilterAttendee(
                          "exerciseStatuses",
                          uniqueNewValue
                        );
                      }}
                      renderOption={(props, option, value) => {
                        // Check if option is "No options" and render accordingly
                        if (option.value === "No options") {
                          return (
                            <Box className="auto-complete-no-options">
                              <Box>{option?.value}</Box>
                            </Box>
                          );
                        }

                        const optionChecked =
                          (option.all &&
                            filterAttendees?.exerciseStatuses?.length ===
                              exerciseStatus?.length) ||
                          filterAttendees?.exerciseStatuses?.find(
                            (e) => e?.key === option?.key
                          ) != null;
                        return (
                          <li
                            {...props}
                            key={props?.key}
                            className={
                              optionChecked
                                ? "multi-tag-edit-list-selected"
                                : "multi-tag-style"
                            }
                          >
                            <Box className="multi-tag-input-list-padding">
                              <Checkbox
                                className="auto-complete-checkbox-list"
                                size="small"
                                checked={optionChecked}
                                color="secondary"
                              />
                              {`${option?.value}`}
                            </Box>
                          </li>
                        );
                      }}
                    />
                  </Box>
                  <Box mb={1} mt={1} className="width-100">
                    <AutoCompleteTagInput
                      label="Batches"
                      placeholder={
                        filterAttendees?.batchIds?.length
                          ? null
                          : "Select Batch"
                      }
                      options={filtersForAttendees?.data?.batches?.results}
                      selectedValue={filterAttendees?.batchIds}
                      getOptionLabel={(option) => option?.name}
                      filterOptions={(options, params) => {
                        // <<<--- inject the Select All option
                        const filter = createFilterOptions();
                        const filtered = filter(options, params);
                        if (
                          filtersForAttendees?.data?.batches?.results.length ===
                          0
                        ) {
                          return [{ name: "No options", all: false }];
                        }

                        return [{ name: "Select All", all: true }, ...filtered];
                      }}
                      onChange={(event, newValue) => {
                        const idCounts = newValue?.reduce((counts, { id }) => {
                          counts[id] = (counts[id] || 0) + 1;
                          return counts;
                        }, {});

                        const uniqueNewValue = newValue?.filter(({ id }) => {
                          return idCounts[id] === 1;
                        });
                        if (newValue.find((option) => option.all)) {
                          return handleChangeFilterAttendee(
                            "batchIds",
                            filterAttendees?.batchIds?.length ===
                              filtersForAttendees?.data?.batches?.results
                                ?.length
                              ? []
                              : filtersForAttendees?.data?.batches?.results
                          );
                        }
                        handleChangeFilterAttendee("batchIds", uniqueNewValue);
                      }}
                      renderOption={(props, option, value) => {
                        // Check if option is "No options" and render accordingly
                        if (option.name === "No options") {
                          return (
                            <Box className="auto-complete-no-options">
                              <Box>{option?.name}</Box>
                            </Box>
                          );
                        }
                        const optionChecked =
                          (option.all &&
                            filterAttendees?.batchIds?.length ===
                              filtersForAttendees?.data?.batches?.results
                                ?.length) ||
                          filterAttendees?.batchIds?.find(
                            (e) => e?.id === option?.id
                          ) != null;
                        return (
                          <li
                            {...props}
                            key={props?.id}
                            className={
                              optionChecked
                                ? "multi-tag-edit-list-selected"
                                : "multi-tag-style"
                            }
                          >
                            <Box className="multi-tag-input-list-padding">
                              <Checkbox
                                className="auto-complete-checkbox-list"
                                size="small"
                                checked={optionChecked}
                                color="secondary"
                              />
                              {`${option?.name}`}
                            </Box>
                          </li>
                        );
                      }}
                    />
                  </Box>
                  {(isSPOC || isHr) && (
                    <Box mb={1} mt={1} className="width-100">
                      <AutoCompleteTagInput
                        disabled={isSPOC}
                        label="Bootcamps"
                        placeholder={
                          filterAttendees?.bootcampId?.length
                            ? null
                            : "Select Bootcamp"
                        }
                        options={filtersForAttendees?.data?.bootcamps?.results}
                        selectedValue={filterAttendees?.bootcampId}
                        getOptionLabel={(option) => `${option?.name}`}
                        filterOptions={(options, params) => {
                          // <<<--- inject the Select All option
                          const filter = createFilterOptions();
                          const filtered = filter(options, params);
                          if (
                            filtersForAttendees?.data?.bootcamps?.results
                              .length === 0
                          ) {
                            return [
                              {
                                name: "No options",
                                all: false,
                              },
                            ];
                          }
                          return [
                            { name: "Select All", all: true },
                            ...filtered,
                          ];
                        }}
                        onChange={(event, newValue) => {
                          const idCounts = newValue.reduce((counts, { id }) => {
                            counts[id] = (counts[id] || 0) + 1;
                            return counts;
                          }, {});

                          const uniqueNewValue = newValue.filter(({ id }) => {
                            return idCounts[id] === 1;
                          });

                          if (newValue.find((option) => option.all)) {
                            return handleChangeFilterAttendee(
                              "bootcampId",
                              filterAttendees?.bootcampId?.length ===
                                filtersForAttendees?.data?.bootcamps?.results
                                  ?.length
                                ? []
                                : filtersForAttendees?.data?.bootcamps?.results
                            );
                          }
                          handleChangeFilterAttendee(
                            "bootcampId",
                            uniqueNewValue
                          );
                        }}
                        renderOption={(props, option, value) => {
                          // Check if option is "No options" and render accordingly
                          if (option.name === "No options") {
                            return (
                              <Box className="auto-complete-no-options">
                                <Box>{option?.name}</Box>
                              </Box>
                            );
                          }
                          const optionChecked =
                            (option.all &&
                              filterAttendees?.bootcampId?.length ===
                                filtersForAttendees?.data?.bootcamps?.results
                                  ?.length) ||
                            filterAttendees?.bootcampId?.find(
                              (e) => e?.id === option?.id
                            ) != null;
                          return (
                            <li
                              {...props}
                              key={props?.id}
                              className={
                                optionChecked
                                  ? "multi-tag-edit-list-selected"
                                  : "multi-tag-style"
                              }
                            >
                              <Box className="multi-tag-input-list-padding">
                                <Checkbox
                                  className="auto-complete-checkbox-list"
                                  size="small"
                                  checked={optionChecked}
                                  color="secondary"
                                />
                                {`${option?.name}`}
                              </Box>
                            </li>
                          );
                        }}
                      />
                    </Box>
                  )}
                  {isHr && (
                    <Box mb={1} mt={1} className="width-100">
                      <AutoCompleteTagInput
                        label="Competency"
                        placeholder={
                          filterAttendees?.competencyIds?.length
                            ? null
                            : "Select Competency"
                        }
                        options={
                          filtersForAttendees?.data?.competencies?.results
                        }
                        selectedValue={filterAttendees?.competencyIds}
                        getOptionLabel={(option) => `${option?.name}`}
                        filterOptions={(options, params) => {
                          // <<<--- inject the Select All option
                          const filter = createFilterOptions();
                          const filtered = filter(options, params);
                          if (
                            filtersForAttendees?.data?.competencies?.results
                              .length === 0
                          ) {
                            return [{ name: "No options", all: false }];
                          }
                          return [
                            { name: "Select All", all: true },
                            ...filtered,
                          ];
                        }}
                        onChange={(event, newValue) => {
                          const idCounts = newValue.reduce((counts, { id }) => {
                            counts[id] = (counts[id] || 0) + 1;
                            return counts;
                          }, {});

                          const uniqueNewValue = newValue.filter(({ id }) => {
                            return idCounts[id] === 1;
                          });
                          if (newValue.find((option) => option.all)) {
                            return handleChangeFilterAttendee(
                              "competencyIds",
                              filterAttendees?.competencyIds?.length ===
                                filtersForAttendees?.data?.competencies?.results
                                  ?.length
                                ? []
                                : filtersForAttendees?.data?.competencies
                                    ?.results
                            );
                          }
                          handleChangeFilterAttendee(
                            "competencyIds",
                            uniqueNewValue
                          );
                        }}
                        renderOption={(props, option, value) => {
                          // Check if option is "No options" and render accordingly
                          if (option.name === "No options") {
                            return (
                              <Box className="auto-complete-no-options">
                                <Box>{option?.name}</Box>
                              </Box>
                            );
                          }

                          const optionChecked =
                            (option.all &&
                              filterAttendees?.competencyIds?.length ===
                                filtersForAttendees?.data?.competencies?.results
                                  ?.length) ||
                            filterAttendees?.competencyIds?.find(
                              (e) => e?.id === option?.id
                            ) != null;
                          return (
                            <li
                              {...props}
                              key={props?.id}
                              className={
                                optionChecked
                                  ? "multi-tag-edit-list-selected"
                                  : "multi-tag-style"
                              }
                            >
                              <Box className="multi-tag-input-list-padding">
                                <Checkbox
                                  className="auto-complete-checkbox-list"
                                  size="small"
                                  checked={optionChecked}
                                  color="secondary"
                                />
                                {`${option?.name}`}
                              </Box>
                            </li>
                          );
                        }}
                      />
                    </Box>
                  )}
                </>
              ) : null}
            </Box>
          </Grid>
          <Grid item md={12} className="filter-padding filter-btn-container">
            <Button
              onClick={handleFilterAttendeeSubmit}
              variant="contained"
              color="secondary"
              className="block-button filter-btn margin-right-1"
            >
              <Typography variant="outlineBtnLabel">Apply Filters</Typography>
            </Button>

            <Button
              onClick={handleFilterAttendeeRemove}
              variant="outlined"
              color="secondary"
              className="block-button filter-btn"
            >
              <Typography variant="outlineBtnLabel">Reset</Typography>
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

export default FilterAttendee;
