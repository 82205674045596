import { configureStore } from "@reduxjs/toolkit";
import security from "./security";
import locationAndRoom from "./locationAndRooms";
import topic from "./topic";
import users from "./users";
import bootcamp from "./bootcamp";
import sessionList from "./sessions";
import questionList from "./questions";
import bootcampModuleView from "./bootcampModuleView";
import exercisesList from "./exercises";
import detailsTab from "./SessionView/detailsTab";
import attendeesTab from "./SessionView/attendeesTab";
import exerciseStatusTab from "./SessionView/exerciseStatusTab";
import presentersTab from "./SessionView/presentersTab";
import attendees from "./AttendeesPage/attendees";
import commentsList from "./comments";
import emailModal from "./emailModal";
import utility from "./utility";
import bootcampModule from "./bootcampModule";

export const store = configureStore({
  reducer: {
    security: security,
    bootcamp: bootcamp,
    bootcampModule: bootcampModule,
    locationAndRoom: locationAndRoom,
    topic: topic,
    users: users,
    sessionList: sessionList,
    detailsTab: detailsTab,
    attendeesTab: attendeesTab,
    exerciseStatusTab: exerciseStatusTab,
    presentersTab: presentersTab,
    questionList: questionList,
    exercisesList: exercisesList,
    commentsList: commentsList,
    bootcampModuleView: bootcampModuleView,
    attendees: attendees,
    sendEmail: emailModal,
    utility: utility,
  },
  // devTools: process.env.NODE_ENV !== "production",
});