import React from "react";
import IconButtons from "../../components/IconButtons/IconButtons";
import {
  chat_active_icon,
  edit_active_icon,
  edit_disabled_icon,
  edit_reviewer_disabled_icon,
  edit_reviewer_icon,
  file_upload_disabled_icon,
  file_upload_icon,
  reopen_disabled_icon,
  reopen_icon,
  review_disabled_icon,
  review_icon,
} from "../../constants/icons";
import { exerciseStatusChip, font_13_icon } from "../../utils/utils";
import Constants, { HelpTexts } from "../../constants/constants";
import { useSelector } from "react-redux";

const { IN_REVIEW, REOPENED, PENDING, REVIEWED } = Constants;
const {
  ASSIGNED_REVIEWER_CAN_REOPEN,
  ASSIGNED_REVIEWER_CAN_EDIT_SCORE,
  ASSIGNED_REVIEWER_CAN_REVIEW,
} = HelpTexts;

const ExerciseActions = ({
  isAttendee,
  handleCommentOpen,
  handleSubmitOpen,
  submitReviewOpen,
  handleEditReviewModalOpen,
  handleReopenModalOpen,
  exerciseId,
  status,
  moduleStatus,
  attendeeDeleted,
  exercise,
  isBootcampCompleted,
  handleEditExerciseTabOpen,
}) => {
  const isExerciseSubmissionDisabled =
    moduleStatus !== "ACTIVE" && moduleStatus !== "COMPLETED";

  const security = useSelector((state) => state?.security);
  const userPermission = security?.permission;
  const userId = security?.loggedInUser?.id;

  const isMentor =
    userPermission?.mentor &&
    (exercise?.exercise?.attendee?.leadMentor?.id === userId ||
      exercise?.exercise?.attendee?.coMentor?.id === userId ||
      exercise?.exercise?.attendee?.attendee?.leadMentor?.id === userId ||
      exercise?.exercise?.attendee?.attendee?.coMentor?.id === userId);
  const isReviewer =
    userPermission?.exerciseReviewer &&
    exercise?.exercise?.reviewer?.id === userId;
  const disableExerciseReviewActions =
    isMentor &&
    exercise?.exercise?.reviewer &&
    exercise?.exercise?.reviewer?.id !== userId;
  const exerciseState = exerciseStatusChip(status)?.name;

  return (
    <>
      <IconButtons
        height={font_13_icon}
        width={font_13_icon}
        image={chat_active_icon}
        classList="margin-right-1"
        handleClick={() => handleCommentOpen(exerciseId, !attendeeDeleted)}
        tooltip="Show Comments"
      />
      {isAttendee && (
        <IconButtons
          height={font_13_icon}
          width={font_13_icon}
          image={file_upload_icon}
          classList="margin-right-1"
          handleClick={(event) => handleSubmitOpen(event, exerciseId)}
          tooltip="Submit Exercise"
          disabledImage={file_upload_disabled_icon}
          disabled={
            attendeeDeleted ||
            isBootcampCompleted ||
            isExerciseSubmissionDisabled ||
            (status !== PENDING && status !== REOPENED)
          }
          disabledTooltip={
            attendeeDeleted
              ? "Attendee has been deleted"
              : isBootcampCompleted
              ? "Bootcamp is completed"
              : status !== PENDING && status !== REOPENED
              ? `Exercise cannot be submitted in ${exerciseState.toLowerCase()} state`
              : null
          }
        />
      )}
      {isMentor && (
        <IconButtons
          height={font_13_icon}
          width={font_13_icon}
          image={edit_reviewer_icon}
          tooltip="Edit Reviewer"
          classList="margin-right-1"
          disabledImage={edit_reviewer_disabled_icon}
          disabled={
            attendeeDeleted || isBootcampCompleted || status === REVIEWED
          }
          handleClick={() =>
            handleEditExerciseTabOpen(
              exercise?.exercise?.id,
              exercise?.exercise?.reviewer,
              exercise?.exercise?.session?.id
            )
          }
          disabledTooltip={
            attendeeDeleted
              ? "Attendee has been deleted"
              : isBootcampCompleted
              ? "Bootcamp is completed"
              : status === REVIEWED
              ? "Cannot change the reviewer as this is reviewed"
              : null
          }
        />
      )}
      {!isAttendee && (
        <>
          <IconButtons
            height={font_13_icon}
            width={font_13_icon}
            image={edit_active_icon}
            classList="margin-right-1"
            handleClick={() => handleEditReviewModalOpen(exerciseId)}
            tooltip="Edit Exercise Score"
            disabledImage={edit_disabled_icon}
            disabled={
              attendeeDeleted ||
              (!isMentor ? !isReviewer : disableExerciseReviewActions) ||
              isBootcampCompleted ||
              !(status === REVIEWED)
            }
            disabledTooltip={
              attendeeDeleted
                ? "Attendee has been deleted"
                : isBootcampCompleted
                ? "Bootcamp is completed"
                : (!isMentor ? !isReviewer : disableExerciseReviewActions)
                ? ASSIGNED_REVIEWER_CAN_EDIT_SCORE
                : !(status === REVIEWED)
                ? `Score cannot be edited in ${exerciseState.toLowerCase()} state`
                : null
            }
          />
          <IconButtons
            height={font_13_icon}
            width={font_13_icon}
            image={review_icon}
            classList="margin-right-1"
            handleClick={() => submitReviewOpen(exerciseId)}
            tooltip="Review Exercise"
            disabledImage={review_disabled_icon}
            disabled={
              attendeeDeleted ||
              (!isMentor ? !isReviewer : disableExerciseReviewActions) ||
              isBootcampCompleted ||
              !(status === IN_REVIEW)
            }
            disabledTooltip={
              attendeeDeleted
                ? "Attendee has been deleted"
                : isBootcampCompleted
                ? "Bootcamp is completed"
                : (!isMentor ? !isReviewer : disableExerciseReviewActions)
                ? ASSIGNED_REVIEWER_CAN_REVIEW
                : !(status === IN_REVIEW)
                ? `Review is not allowed in ${exerciseState.toLowerCase()} state`
                : null
            }
          />
          <IconButtons
            height={font_13_icon}
            width={font_13_icon}
            image={reopen_icon}
            classList="margin-right-1"
            handleClick={() => handleReopenModalOpen(exerciseId)}
            tooltip="Reopen Exercise"
            disabledImage={reopen_disabled_icon}
            disabled={
              attendeeDeleted ||
              (!isMentor ? !isReviewer : disableExerciseReviewActions) ||
              isBootcampCompleted ||
              !(status === REVIEWED || status === IN_REVIEW)
            }
            disabledTooltip={
              attendeeDeleted
                ? "Attendee has been deleted"
                : isBootcampCompleted
                ? "Bootcamp is completed"
                : (!isMentor ? !isReviewer : disableExerciseReviewActions)
                ? ASSIGNED_REVIEWER_CAN_REOPEN
                : !(status === REVIEWED || status === IN_REVIEW)
                ? `Reopening is not allowed in ${exerciseState.toLowerCase()} state`
                : null
            }
          />
        </>
      )}
    </>
  );
};

export default ExerciseActions;
