import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { cross_default_icon } from "../../constants/icons";
import AutoCompleteTagInput from "../../components/AutoCompleteTagInput/AutoCompleteTagInput";
import DateTimePickerComponent from "../../components/DateTimePickerComponent/DateTimePickerComponent";
import dayjs from "dayjs";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { getMaxDate, shouldDisableWeekends } from "../../utils/utils";
import Constants from "../../constants/constants";

const { ACTIVE, COMPLETED } = Constants;

const compareFormFields = (initial, current) => {
  const fieldsToCheck = [
    "name",
    "startDate",
    "endDate",
    "description",
    "bootcampIds",
  ];

  return fieldsToCheck.some((field) => {
    if (field === "bootcampIds") {
      if (initial[field].length !== current[field].length) return true;
      const initialIds = initial[field].map((item) => item.id).sort();
      const currentIds = current[field].map((item) => item.id).sort();
      return !initialIds.every((id, index) => id === currentIds[index]);
    } else if (field === "startDate" || field === "endDate") {
      return !dayjs(initial[field]).isSame(dayjs(current[field]));
    } else if (field === "name") {
      return initial[field]?.trim() !== current[field]?.trim();
    }
    return initial[field] !== current[field];
  });
};

const CreateBootcampModule = ({
  open,
  onClose,
  handleBootcampModuleDetailsChange,
  getBootcampData,
  handleBootcampModuleSubmit,
  bootcampModuleDetails,
  isEdit,
  bootcampModuleApiError,
  moduleSpeChar,
  attendeesListLoading,
  loader,
  bootcamp,
  bootcampModuleList,
  moduleNameLengthError,
  disabledBootcampsId,
  isModuleActive,
}) => {
  const getBootcampinfo = useSelector((state) => {
    return state?.bootcamp;
  });

  const [moduleStartDate, setModuleStartDate] = useState(null);
  const [moduleEndDate, setModuleEndDate] = useState(null);
  const [sessionStartDateWarning, setSessionStartDateWarning] = useState("");
  const [sessionEndDateWarning, setSessionEnddDateWarning] = useState("");

  const moduleOptionLoading = getBootcampinfo?.moduleOptionLoading;

  const getBootcampOptionsLoader = getBootcampinfo?.getBootcampOptionsLoader;

  const initialErrorState = {
    name: "",
    startDate: "",
    endDate: "",
    bootcampIds: "",
    startAndEndDate: "",
  };
  const [errors, setErrors] = useState(initialErrorState);

  const handleBootcampModuleDateChanges = (name, value) => {
    if (name == "startDate" && isEdit) {
      const startDate =
        moduleStartDate === null
          ? bootcampModuleDetails?.startDate
          : moduleStartDate;
      if (moduleStartDate === null) {
        setModuleStartDate(bootcampModuleDetails?.startDate);
      }
      if (dayjs(startDate).isBefore(dayjs(value), "day")) {
        setSessionStartDateWarning("Session Dates Will Need to Be Moved");
      } else {
        setSessionStartDateWarning("");
      }
    } else if (name == "endDate" && isEdit) {
      const endDate =
        moduleEndDate === null ? bootcampModuleDetails?.endDate : moduleEndDate;
      if (moduleEndDate === null) {
        setModuleEndDate(bootcampModuleDetails?.endDate);
      }
      if (endDate !== null && dayjs(endDate).isAfter(dayjs(value), "day")) {
        setSessionEnddDateWarning("Session Dates Will Need to Be Moved");
      } else {
        setSessionEnddDateWarning("");
      }
    }
    handleBootcampModuleDetailsChange(name, value);
    setErrors((prev) => {
      return {
        ...prev,
        [name]: "",
        startAndEndDate: "",
      };
    });
  };

  const validateFields = () => {
    const { name, startDate, endDate, bootcampIds } = bootcampModuleDetails;
    const newErrors = {
      name: name ? "" : "Name is required",
      startDate: startDate ? "" : "Start Date is required",
      endDate: endDate ? "" : "End Date is required",
      bootcampIds:
        bootcampIds && bootcampIds.length > 0 ? "" : "Bootcamps are required",
      startAndEndDate:
        startDate && endDate
          ? validateDate(startDate, endDate, bootcampIds)
          : "",
    };
    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === "");
  };

  const validateDate = (date1, date2, bootcamps) => {
    let error = [];
    if (
      bootcamps &&
      bootcamps?.length &&
      dayjs(date1).isValid() &&
      dayjs(date2).isValid()
    ) {
      bootcamps?.forEach((it) => {
        return it?.modules?.results
          ?.filter(
            (it) => !(isEdit && it?.id === bootcampModuleDetails?.moduleId)
          )
          .forEach((it2) => {
            if (
              (!dayjs(date1).isBefore(dayjs(it2?.startDate), "date") &&
                !dayjs(date1).isAfter(dayjs(it2?.endDate), "date")) ||
              (!dayjs(date2).isBefore(dayjs(it2?.startDate), "date") &&
                !dayjs(date2).isAfter(dayjs(it2?.endDate), "date")) ||
              (!dayjs(it2?.startDate).isBefore(dayjs(date1), "date") &&
                !dayjs(it2?.startDate).isAfter(dayjs(date2), "date")) ||
              (!dayjs(it2?.endDate).isBefore(dayjs(date1), "date") &&
                !dayjs(it2?.endDate).isAfter(dayjs(date2), "date"))
            ) {
              error.push(
                `${it2?.name} of Bootcamp ${it?.name}, Start Date: ${dayjs(
                  it2?.startDate
                )
                  .tz()
                  .format("YYYY-MM-DD")}, End Date: ${dayjs(it2?.endDate)
                  .tz()
                  .format("YYYY-MM-DD")}`
              );
            }
          });
      });
    }
    if (error?.length === 0) {
      error = "";
    } else {
      error = [
        `Cannot ${
          isEdit ? `Edit` : `Add`
        } Module, Overlaps with following Module/s:`,
        ...error,
      ];
    }
    return error;
  };

  const shoudlDisableBootcampModuleDates = (date) => {
    return bootcampModuleList
      ?.filter((it) => !(isEdit && it?.id === bootcampModuleDetails?.moduleId))
      .some(
        (it) =>
          !dayjs(date)
            .tz()
            .isBefore(dayjs(it?.startDate).tz().startOf("day")) &&
          !dayjs(date).tz().isAfter(dayjs(it?.endDate).tz().endOf("day"))
      );
  };

  const handleSubmit = () => {
    if (validateFields()) {
      handleBootcampModuleSubmit();
      setModuleStartDate(null);
      setModuleEndDate(null);
      setSessionStartDateWarning("");
      setSessionEnddDateWarning("");
    }
  };

  const handleOnClose = () => {
    setErrors(initialErrorState);
    setModuleStartDate(null);
    setModuleEndDate(null);
    setSessionStartDateWarning("");
    setSessionEnddDateWarning("");
    onClose();
  };

  const [initialModuleDetails, setInitialModuleDetails] = useState(
    bootcampModuleDetails
  );

  useEffect(() => {
    setInitialModuleDetails(bootcampModuleDetails);
  }, [bootcampModuleDetails.moduleId]);

  const moduleDetailsChanged = compareFormFields(
    initialModuleDetails,
    bootcampModuleDetails
  );

  // Handler function to delete the chip
  const handleChipDelete = (optionToDelete) => {
    const newBootcampIds = bootcampModuleDetails?.bootcampIds?.filter(
      (option) => option?.id !== optionToDelete?.id
    );
    handleBootcampModuleDetailsChange("bootcampIds", newBootcampIds);
  };

  return (
    <>
      {(moduleOptionLoading ||
        getBootcampOptionsLoader ||
        attendeesListLoading ||
        loader) && <Loader />}
      <Dialog
        className="modal-drawer-container"
        open={open}
        onClose={handleOnClose}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "1rem",
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            zIndex: 1300,
            maxWidth: "29.75rem",
          },
        }}
      >
        <DialogTitle className="dialog-title">
          {isEdit ? "Edit Module" : "Create Module"}
        </DialogTitle>
        <DialogContent
          className="dialog-content"
          sx={{ marginBottom: "0 !important" }}
        >
          <Box className="width-100">
            <Box sx={{ marginBottom: "0.75rem" }}>
              {errors?.startAndEndDate?.length > 0 && (
                <Typography
                  sx={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {errors.startAndEndDate.map((string, index) => (
                    <React.Fragment key={index}>
                      {string}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
              )}
              <TextField
                label="Name"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                size="small"
                value={bootcampModuleDetails?.name}
                onChange={(e) => {
                  handleBootcampModuleDetailsChange("name", e?.target?.value);
                  setErrors((prev) => {
                    return {
                      ...prev,
                      name: "",
                    };
                  });
                }}
                required={true}
                error={
                  !!errors.name ||
                  bootcampModuleApiError ||
                  moduleSpeChar ||
                  moduleNameLengthError
                }
                helperText={
                  moduleSpeChar
                    ? "Cannot contain special characters"
                    : moduleNameLengthError
                    ? "Maximum 50 characters allowed"
                    : errors?.name
                }
              />
            </Box>
            <Box sx={{ marginBottom: "0.75rem" }}>
              <DateTimePickerComponent
                label="Start Date"
                format="DD/MM/YYYY"
                onChange={(e) => {
                  handleBootcampModuleDateChanges("startDate", e);
                }}
                value={bootcampModuleDetails?.startDate}
                required={true}
                error={
                  !!errors.startDate ||
                  bootcampModuleApiError ||
                  sessionStartDateWarning
                }
                helperText={errors?.startDate || sessionStartDateWarning}
                views={["year", "day"]}
                minDate={getMaxDate([
                  bootcamp?.startDate,
                  dayjs().add(1, "day"),
                ])}
                disabled={
                  isEdit &&
                  !dayjs(bootcampModuleDetails?.startDate).isAfter(
                    dayjs(),
                    "date"
                  )
                }
                viewRenderers={{
                  hours: null,
                  minutes: null,
                  seconds: null,
                }}
                shouldDisableDate={(date) => {
                  return (
                    shouldDisableWeekends(date) ||
                    shoudlDisableBootcampModuleDates(date)
                  );
                }}
                closeOnSelect={true}
                disableHighlightToday={true}
              />
            </Box>
            <Box sx={{ marginBottom: "0.75rem" }}>
              <DateTimePickerComponent
                label="End Date"
                format="DD/MM/YYYY"
                onChange={(e) => {
                  handleBootcampModuleDateChanges("endDate", e);
                }}
                value={bootcampModuleDetails?.endDate}
                required={true}
                error={
                  !!errors.endDate ||
                  bootcampModuleApiError ||
                  sessionEndDateWarning
                }
                helperText={errors?.endDate || sessionEndDateWarning}
                views={["year", "day"]}
                minDate={getMaxDate([
                  dayjs(bootcampModuleDetails?.startDate).add(1, "day"),
                  dayjs().add(1, "day"),
                ])}
                disabled={
                  isEdit &&
                  !dayjs(bootcampModuleDetails?.endDate).isAfter(
                    dayjs().startOf("day")
                  )
                }
                viewRenderers={{
                  hours: null,
                  minutes: null,
                  seconds: null,
                }}
                shouldDisableDate={(date) => {
                  return (
                    shouldDisableWeekends(date) ||
                    shoudlDisableBootcampModuleDates(date)
                  );
                }}
                closeOnSelect={true}
                disableHighlightToday={true}
              />
            </Box>
            <Box sx={{ marginBottom: "0.75rem" }}>
              <TextField
                multiline
                minRows={4}
                label="Description"
                color="secondary"
                fullWidth
                className="filter-inputs"
                variant="outlined"
                size="small"
                margin="dense"
                value={bootcampModuleDetails?.description}
                onChange={(e) =>
                  handleBootcampModuleDetailsChange(
                    "description",
                    e?.target?.value
                  )
                }
              />
            </Box>
            <Box sx={{ marginBottom: "0.75rem" }}>
              <AutoCompleteTagInput
                label="Bootcamps"
                placeholder={
                  bootcampModuleDetails?.bootcampIds?.length
                    ? null
                    : "Select Bootcamp"
                }
                getOptionLabel={(option) => option.name}
                options={getBootcampData}
                selectedValue={bootcampModuleDetails?.bootcampIds}
                filterOptions={(options, params) => {
                  // <<<--- inject the Select All option
                  const filter = createFilterOptions();
                  const filtered = filter(options, params);
                  if (getBootcampData.length === 0) {
                    return [{ name: "No options", all: false }];
                  }
                  return [
                    { name: "Select All", startDate: dayjs(), all: true },
                    ...filtered,
                  ];
                }}
                onChange={(event, newValue) => {
                  const idCounts = newValue.reduce((counts, { id }) => {
                    counts[id] = (counts[id] || 0) + 1;
                    return counts;
                  }, {});

                  const uniqueNewValue = newValue.filter(({ id }) => {
                    return idCounts[id] === 1;
                  });
                  if (newValue.find((option) => option.all)) {
                    return handleBootcampModuleDetailsChange(
                      "bootcampIds",
                      bootcampModuleDetails?.bootcampIds?.length ===
                        getBootcampData?.length
                        ? []
                        : getBootcampData
                    );
                  }
                  if (isEdit && event?.keyCode === 8) {
                    const lastItem =
                      bootcampModuleDetails?.bootcampIds[
                        bootcampModuleDetails?.bootcampIds?.length - 1
                      ];
                    if (disabledBootcampsId?.includes(lastItem?.id)) {
                      return;
                    }
                  }
                  handleBootcampModuleDetailsChange(
                    "bootcampIds",
                    uniqueNewValue
                  );
                  setErrors((prev) => {
                    return {
                      ...prev,
                      bootcampIds: "",
                      startAndEndDate: "",
                    };
                  });
                }}
                required={true}
                error={!!errors.bootcampIds || bootcampModuleApiError}
                helperText={errors.bootcampIds}
                disabled={
                  isModuleActive ||
                  !dayjs(bootcampModuleDetails?.startDate).isValid()
                }
                renderOption={(props, option, { selected }) => {
                  // Check if option is "No options" and render accordingly
                  if (option.name === "No options") {
                    return (
                      <Box className="auto-complete-no-options">
                        <Box>{option?.name}</Box>
                      </Box>
                    );
                  }
                  const optionChecked =
                    (option.all &&
                      bootcampModuleDetails?.bootcampIds?.length ===
                        getBootcampData?.length) ||
                    bootcampModuleDetails?.bootcampIds?.find(
                      (e) => e?.id === option?.id
                    ) != null;
                  const optionsDisabled =
                    isEdit && disabledBootcampsId?.includes(option?.id);
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        optionsDisabled
                          ? "multi-tag-edit-list-selected-disabled"
                          : optionChecked
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box className="multi-tag-input-list-padding">
                        <Checkbox
                          className="auto-complete-checkbox-list"
                          size="small"
                          checked={optionChecked}
                          color="secondary"
                        />
                        <Box className="flex-options">
                          <Box>{option?.name}</Box>
                          {option?.name === "Select All" ? null : (
                            <Box>
                              {option?.startDate
                                ? dayjs(option?.startDate)?.format("DD/MM/YYYY")
                                : null}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </li>
                  );
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const showCrossIcon = !(
                      isEdit && disabledBootcampsId?.includes(option?.id)
                    );
                    return (
                      <Chip
                        {...getTagProps(index)}
                        key={index}
                        label={option?.name}
                        onDelete={
                          isEdit
                            ? showCrossIcon
                              ? () => handleChipDelete(option)
                              : undefined
                            : !isEdit && option?.id !== bootcamp?.id
                            ? () => handleChipDelete(option)
                            : undefined
                        }
                      />
                    );
                  })
                }
                getOptionDisabled={(option) => {
                  return isEdit && disabledBootcampsId?.includes(option?.id);
                }}
                disableClearable={
                  !isEdit &&
                  bootcampModuleDetails?.bootcampIds?.length === 1 &&
                  bootcampModuleDetails?.bootcampIds[0]?.id === bootcamp?.id
                }
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "0rem 2rem 1.875rem" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleOnClose}
            className="block-button"
          >
            <Typography variant="outlineBtnLabel">Cancel</Typography>
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            className="block-button"
            disabled={isEdit && !moduleDetailsChanged}
          >
            <Typography variant="outlineBtnLabel">
              {isEdit ? "Update" : "Save"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateBootcampModule;
