import {
  asset_active_icon,
  asset_default_icon,
  claims_active_icon,
  claims_default_icon,
  growth_active_icon,
  growth_default_icon,
  money_active_icon,
  money_default_icon,
  nw_small_logo2,
  referal_active_icon,
  referal_default_icon,
  skill_matrix_active_icon,
  skill_matrix_default_icon,
  timesheet_active_icon,
  timesheet_default_icon,
} from "../../constants/icons";

export const AppDrawerLinks = [
  {
    name: "Newers World",
    linkTo: "https://newersworld.tothenew.com/#/home",
    defaultImage: nw_small_logo2,
    hoverImage: nw_small_logo2,
  },
  {
    name: "Growth",
    linkTo: "https://growth.tothenew.com/home/profile",
    defaultImage: growth_default_icon,
    hoverImage: growth_active_icon,
  },
  {
    name: "Referral",
    linkTo:
      "https://docs.google.com/forms/d/e/1FAIpQLSdPhiKYdBZTqCmOUwjjWa6RT1WCc7msDBPdtDEwJ1_IXykffw/closedform",
    defaultImage: referal_default_icon,
    hoverImage: referal_active_icon,
  },
  {
    name: "Investment Declaration",
    linkTo: "https://investment-declaration.tothenew.com",
    defaultImage: money_default_icon,
    hoverImage: money_active_icon,
  },
  {
    name: "Asset Desk",
    linkTo: "https://asset-desk.tothenew.com",
    defaultImage: asset_default_icon,
    hoverImage: asset_active_icon,
  },
  {
    name: "Reimbursement Claims",
    linkTo: "https://reimbursement.tothenew.com/#/home",
    defaultImage: claims_default_icon,
    hoverImage: claims_active_icon,
  },
  {
    name: "Skill Matrix",
    linkTo: "https://skillmatrix.tothenew.com",
    defaultImage: skill_matrix_default_icon,
    hoverImage: skill_matrix_active_icon,
  },
];
