import React, { useEffect, useRef, useState } from "react";
import "./commentModal.scss";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import IconButtons from "./../IconButtons/IconButtons";
import {
  circle_save_change_icon,
  cross_red_icon,
  delete_active_icon,
  delete_disabled_icon,
  edit_active_icon,
  edit_disabled_icon,
} from "../../constants/icons";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCommentsList,
  deleteComment,
  editComment,
} from "../../store/comments";
import FlipCard from "react-card-flip";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
import { backendBaseUrl } from "../../config/config";
import Loader from "../Loader/Loader";
import { font_13_icon } from "../../utils/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
const tz = "Asia/Kolkata";

const CommentModal = ({
  open,
  handleClose,
  handleSubmit,
  exerciseId,
  comments,
  handleInputComment,
  commentDetails,
  openComment,
  canEditDeleteComments,
  canAddComment,
  isBootcampCompleted,
}) => {
  //////////// handle view more ////////////////////////////////
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const onResourceViewClick = (url) =>
    window.open(`${backendBaseUrl}${url}`, "_blank");
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state?.commentsList?.loading);
  const [loader, setLoader] = useState(false);
  const [isCommentEmpty, setIsCommentEmpty] = useState(false);
  const initialCommentInfo = {
    commentId: null,
    commentDesc: "",
    deleteCommentId: null,
  };
  const [commentInfo, setCommentInfo] = useState(initialCommentInfo);
  const [showResourceTables, setShowResourceTables] = useState({});
  const [isFlipped, setIsFlipped] = useState({});
  const [frontCardHeight, setFrontCardHeight] = useState(0);
  const [expandedComments, setExpandedComments] = useState({});

  const handleToggleCommentExpand = (commentId) => {
    setExpandedComments((prevExpanded) => ({
      ...prevExpanded,
      [commentId]: !prevExpanded[commentId],
    }));
  };

  const onSubmit = async () => {
    setIsCommentEmpty(!commentDetails?.trim());
    setLoader(true);
    setExpandedComments(false);
    await handleSubmit(commentDetails?.trim());
    setLoader(false);
  };

  const handleInputChange = (comment) => {
    setIsCommentEmpty(false);
    handleInputComment(comment);
  };

  const handleDeleteComment = async (commentId) => {
    await dispatch(deleteComment({ commentId, exerciseId }));
    setCommentInfo(initialCommentInfo);
    openComment(false);
  };

  const handleEdit = (commentId, commentDesc) => {
    setCommentInfo({ ...initialCommentInfo, commentId, commentDesc });
  };

  const handleInputEditComment = (editedValue) => {
    setCommentInfo({ ...commentInfo, commentDesc: editedValue });
  };

  const handleOnClose = () => {
    setCommentInfo(initialCommentInfo);
    setExpandedComments(false);
    setShowResourceTables({});
    setIsCommentEmpty(false);
    setIsFlipped(false);
    handleClose();
    dispatch(clearCommentsList());
  };

  const handleRowEditClose = () => {
    setCommentInfo(initialCommentInfo);
  };

  const submitRowEdit = async () => {
    const { commentId, commentDesc } = commentInfo;
    await dispatch(
      editComment({ commentId, comment: commentDesc, exerciseId })
    );
    setCommentInfo(initialCommentInfo);
  };

  const toggleResourceTable = (commentId) => {
    setShowResourceTables((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const handleCardFlip = (commentId, type) => {
    if (type === "delete") {
      setCommentInfo({ ...initialCommentInfo, deleteCommentId: commentId });
    } else {
      setCommentInfo(initialCommentInfo);
    }
    setIsFlipped((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
    const frontCard = document.getElementById(`comment-front-${commentId}`);
    if (frontCard) {
      setFrontCardHeight(frontCard.offsetHeight);
    }
  };

  /////////////////// Scroll to bottom on mount ///////////////////////////////////
  const commentContainerRef = useRef(null);
  useEffect(() => {
    if (commentContainerRef.current) {
      commentContainerRef.current.scrollTop =
        commentContainerRef.current.scrollHeight;
    }
  }, [comments]);
  //////////////////////////////////////////////////////////////////////////////////

  const isAddCommentDisabled =
    commentInfo?.commentId ||
    commentInfo?.deleteCommentId ||
    isBootcampCompleted;

  return (
    <>
      {isLoading && <Loader />}
      <Dialog
        open={open}
        onClose={handleOnClose}
        PaperProps={{
          style: {
            minHeight: "30.125rem",
            height: "30.125rem",
            width: "29rem",
            zIndex: 1300,
            borderRadius: "1rem",
            margin: 0,
            padding: 0,
          },
        }}
      >
        <>
          <DialogTitle className="comment-dialog-title">
            <Typography variant="font_16_bold">Add Comment</Typography>
          </DialogTitle>
          <DialogContent
            sx={{
              minHeight: "20rem",
              zIndex: 1300,
              borderRadius: "1rem",
              margin: 0,
              padding: "1rem 1.4rem 1.5rem 2rem !important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box className="comment-main-container" ref={commentContainerRef}>
              {comments?.length > 0
                ? comments?.map((comment, index) => {
                    const wordCount = comment?.comment.length;
                    const countLines = comment?.comment?.split(/\r|\r\n|\n/);
                    const lineLength = countLines?.length;

                    return (
                      <FlipCard
                        key={index}
                        isFlipped={isFlipped[comment.id]}
                        flipDirection="vertical"
                      >
                        <Box
                          id={`comment-front-${comment.id}`}
                          className={`comment-show-container ${
                            commentInfo?.deleteCommentId && "disabledBox"
                          }`}
                        >
                          <Box className="comment-show-head">
                            <Typography variant="font_12_bold_600">
                              {comment?.createdBy?.fullName}
                            </Typography>
                            <Typography variant="font_12" className="grey-text">
                              {dayjs(comment?.dateCreated)
                                ?.tz(tz)
                                ?.format("DD-MMM-YYYY h:mm A")}
                            </Typography>
                          </Box>
                          <Box className="comment-show-body">
                            <Box className="comment-description">
                              {comment?.id === commentInfo?.commentId ? (
                                <TextField
                                  autoFocus
                                  multiline
                                  fullWidth
                                  id="editComment"
                                  type="text"
                                  variant="outlined"
                                  color="secondary"
                                  value={commentInfo?.commentDesc}
                                  onChange={(e) =>
                                    handleInputEditComment(e?.target?.value)
                                  }
                                />
                              ) : (
                                <>
                                  <Typography variant="font_12">
                                    <Collapse
                                      className={
                                        !(wordCount > 100 || lineLength > 3)
                                          ? "adjusted-height"
                                          : null
                                      }
                                      in={expandedComments[comment.id]}
                                      collapsedSize={
                                        wordCount > 100 || lineLength > 3
                                          ? 35
                                          : "auto"
                                      }
                                    >
                                      <Box
                                        className="ellipsis"
                                        sx={{
                                          whiteSpace: "pre-wrap",
                                          wordBreak: "break-word",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        {wordCount > 100 || lineLength > 3
                                          ? expandedComments[comment.id]
                                            ? comment?.comment
                                            : `${comment?.comment?.substring(
                                                0,
                                                100
                                              )}...`
                                          : comment?.comment}
                                      </Box>
                                    </Collapse>
                                    {(wordCount > 100 || lineLength > 3) && (
                                      <Typography
                                        variant="font_9_bold"
                                        color="secondary"
                                        onClick={() =>
                                          handleToggleCommentExpand(comment.id)
                                        }
                                        cursor="pointer"
                                        sx={{ fontWeight: 600 }}
                                      >
                                        {expandedComments[comment.id]
                                          ? "VIEW LESS"
                                          : "VIEW MORE"}
                                      </Typography>
                                    )}
                                  </Typography>
                                </>
                              )}
                            </Box>
                            {comment?.id === commentInfo?.commentId ? (
                              <Box className="comment-action-buttons">
                                <IconButtons
                                  height={font_13_icon}
                                  width={font_13_icon}
                                  image={circle_save_change_icon}
                                  classList="padding-right-1"
                                  handleClick={submitRowEdit}
                                />
                                <IconButtons
                                  height={font_13_icon}
                                  width={font_13_icon}
                                  image={cross_red_icon}
                                  handleClick={handleRowEditClose}
                                  classList="padding-right-1"
                                />
                              </Box>
                            ) : canEditDeleteComments ? (
                              <Box className="comment-action-buttons">
                                <IconButtons
                                  width={font_13_icon}
                                  height={font_13_icon}
                                  image={edit_active_icon}
                                  handleClick={() =>
                                    handleEdit(comment?.id, comment?.comment)
                                  }
                                  classList="padding-right-2"
                                  disabled={isBootcampCompleted}
                                  disabledImage={edit_disabled_icon}
                                  disabledTooltip={
                                    isBootcampCompleted
                                      ? "Bootcamp is completed"
                                      : ""
                                  }
                                />
                                <IconButtons
                                  width={font_13_icon}
                                  height={font_13_icon}
                                  image={delete_active_icon}
                                  handleClick={() =>
                                    handleCardFlip(comment?.id, "delete")
                                  }
                                  disabled={isBootcampCompleted}
                                  disabledImage={delete_disabled_icon}
                                  disabledTooltip={
                                    isBootcampCompleted
                                      ? "Bootcamp is completed"
                                      : ""
                                  }
                                />
                              </Box>
                            ) : null}
                          </Box>
                          {comment?.resources?.results.length > 0 && (
                            <Box onClick={() => toggleResourceTable(comment.id)}>
                              <Typography
                                variant="font_9_bold"
                                color="secondary"
                                cursor="pointer"
                                sx={{ fontWeight: 600 }}
                              >
                                {showResourceTables[comment.id]
                                  ? "Hide Submissions"
                                  : "View Submissions"}
                              </Typography>
                              <ExpandMore
                                expand={showResourceTables[comment.id]}
                                sx={{ padding: 0 }}
                              >
                                <ExpandMoreIcon
                                  color="secondary"
                                  sx={{
                                    fontSize: "1rem",
                                  }}
                                />
                              </ExpandMore>
                            </Box>
                          )}
                          <Collapse in={showResourceTables[comment.id]}>
                            <Typography key={index} mt={1}>
                              {comment?.resources?.results?.length > 0 &&
                                (() => {
                                  let attachmentCount = 0;
                                  let urlCount = 0;

                                  return comment?.resources?.results?.map(
                                    (resource, index) => {
                                      let label = "";
                                      if (resource?.format === "FILE") {
                                        attachmentCount++;
                                        label = `Attachment ${attachmentCount}`;
                                      } else if (resource?.format === "URL") {
                                        urlCount++;
                                        label = `URL ${urlCount}`;
                                      } else {
                                        label = `Resource ${index + 1}`;
                                      }

                                      return (
                                        <React.Fragment key={index}>
                                          <BootstrapTooltip
                                            title={resource?.name}
                                          >
                                            <Button
                                              onClick={onResourceViewClick.bind(
                                                this,
                                                resource?.url
                                              )}
                                              sx={{
                                                textTransform: "none",
                                                padding:
                                                  "0.2rem 0.4rem 0.2rem 0rem",
                                                minWidth: "auto",
                                              }}
                                              color="secondary"
                                            >
                                              <Typography variant="font_9_bold">
                                                {label}
                                                {index <
                                                  comment?.resources?.results
                                                    ?.length -
                                                    1 && ", "}
                                              </Typography>
                                            </Button>
                                          </BootstrapTooltip>
                                        </React.Fragment>
                                      );
                                    }
                                  );
                                })()}
                            </Typography>
                          </Collapse>
                        </Box>
                        <Box
                          className="show-delete"
                          style={{
                            height: `${frontCardHeight}px`,
                          }}
                        >
                          <Typography
                            className="delete-dialog-text"
                            variant="font_12_bold_700"
                          >
                            Are you sure you want to delete this comment?
                          </Typography>
                          <Box>
                            <Button
                              disabled={false}
                              variant="outlined"
                              color="secondary"
                              onClick={() => handleCardFlip(comment.id, "cancel")}
                              className="delTagButtonStyle"
                              disableFocusRipple
                              disableRipple
                              disableTouchRipple
                              disableElevation
                            >
                              <Typography variant="outlineBtnLabel">
                                Cancel
                              </Typography>
                            </Button>

                            <Button
                              disabled={false}
                              color="secondary"
                              variant="contained"
                              onClick={() => handleDeleteComment(comment.id)}
                              className="delTagButtonStyle"
                              disableFocusRipple
                              disableRipple
                              disableTouchRipple
                              disableElevation
                            >
                              <Typography variant="outlineBtnLabel">
                                Yes
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      </FlipCard>
                    );
                  })
                : comments?.length === 0 && (
                    <Grid container className="no-data-container">
                      <NoDataAvailable
                        imgHeight={4}
                        imgWidth={4.5}
                        message="No Comments Available"
                      />
                    </Grid>
                  )}
            </Box>
            {canAddComment && (
              <Box sx={{ paddingRight: "0.6rem" }}>
                <TextField
                  multiline
                  minRows={3}
                  disabled={isAddCommentDisabled}
                  autoFocus
                  id="name"
                  shrink={false}
                  size="small"
                  label="Comment"
                  type="text"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  value={commentDetails}
                  onChange={(e) => handleInputChange(e?.target?.value)}
                  color="secondary"
                  className="comment-area"
                  error={isCommentEmpty}
                  helperText={isCommentEmpty ? "Comment cannot be empty" : ""}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions className="comment-dialog-action">
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={handleOnClose}
              className="block-button"
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disableElevation
            >
              <Typography variant="outlineBtnLabel">Cancel</Typography>
            </Button>
            {canAddComment && (
              <Button
                fullWidth
                disabled={isAddCommentDisabled}
                color="secondary"
                variant="contained"
                onClick={onSubmit}
                className="addTagButtonStyle"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                disableElevation
              >
                <Typography variant="outlineBtnLabel">Save</Typography>
              </Button>
            )}
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

export default CommentModal;
