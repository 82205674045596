import React, { useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import calendarIcon from "../../assets/icons/calendar.svg";

export const CalendarIcon = () => {
  return <img src={calendarIcon} alt="calendar-icon"></img>;
};

const DateTimePickerComponent = ({
  label,
  value,
  onChange,
  views,
  error,
  helperText,
  required,
  disablePast,
  minDate,
  maxDate,
  classList,
  format,
  disabled,
  disableHighlightToday,
  viewRenderers,
  closeOnSelect,
  enableClear,
  shouldDisableDate,
  defaultValue,
  minTime,
  selectedSections,
  minDateTime,
  maxDateTime
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <DateTimePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disableToolbar
        orientation="portrait"
        ampm={true}
        value={value}
        onChange={onChange}
        label={label}
        format={format}
        formatDensity="dense"
        views={views}
        className={`width-100 ${classList}`}
        disablePast={disablePast}
        minDate={minDate}
        maxDate={maxDate}
        minDateTime={minDateTime}
        maxDateTime={maxDateTime}
        closeOnSelect={closeOnSelect}
        showTime={{ user12hours: true }}
        disableHighlightToday={disableHighlightToday}
        viewRenderers={viewRenderers}
        disabled={disabled}
        slots={{ openPickerIcon: CalendarIcon }}
        sx={{
          "& .MuiIconButton-root": {
            padding: 0,
          },
        }}
        slotProps={{
          textField: {
            label: label,
            variant: "outlined",
            color: "secondary",
            size: "small",
            margin: "dense",
            required: required,
            error: error,
            helperText: helperText,
            readOnly: true,
            disabled: disabled,
            onClick: () => setOpen(disabled ? false : true),
          },
          openPickerIcon: {
            onClick: () => {
              setOpen(disabled ? false : true);
            },
          },
          actionBar: { actions: [enableClear && "clear", "accept"] },
          popper: {
            placement: "left",
          },
        }}
        shouldDisableDate={shouldDisableDate}
        defaultValue={defaultValue}
        minTime={minTime}
        selectedSections={selectedSections}
      />
    </>
  );
};

export default DateTimePickerComponent;
