import React from "react";
import "./Attendees.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Checkbox,
  createFilterOptions,
} from "@mui/material";
import SimpleAutoComplete from "../../components/SimpleAutoComplete/SimpleAutoComplete";
import AutoCompleteTagInput from "../../components/AutoCompleteTagInput/AutoCompleteTagInput";
import Loader from "../../components/Loader/Loader";
import {
  filterOptionsWithSelectAllDesignations,
  sortByFullName,
} from "../../utils/utils";

const AddAttendee = ({
  open,
  title,
  handleClose,
  handleSubmit,
  addAttendeeDetails,
  handleAddAttendeeChange,
  loader,
  competencyList,
  disabled,
  errors,
  userNDesignationLoading,
}) => {
  const attendeesWithAllDesignation = [].concat(
    ...(addAttendeeDetails?.competencyIds?.designations?.results
      ?.filter((it) => !!it)
      ?.map((it) => it?.users?.results)
      ?.filter((it) => !!it) || [])
  );
  const filteredOptions = addAttendeeDetails?.designationIds
    ? addAttendeeDetails?.designationIds?.users?.results
    : attendeesWithAllDesignation;

  const userOptions = sortByFullName(filteredOptions);

  return (
    <>
      {(loader || userNDesignationLoading) && <Loader />}

      <Dialog
        open={open}
        onClose={handleClose}
        className="modal-drawer-container"
        PaperProps={{
          style: {
            minHeight: "21rem",
            width: "27.125rem",
            zIndex: 1300,
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            borderRadius: "1rem",
          },
        }}
      >
        <DialogTitle className="dialog-title">{title}</DialogTitle>
        <DialogContent className="dialog-content">
          <Box className="width-100">
            <SimpleAutoComplete
              disabled={disabled}
              label="Competency"
              placeholder="Select Competency"
              options={competencyList}
              onChange={(event, newValue) =>
                handleAddAttendeeChange("competencyIds", newValue)
              }
              getOptionLabel={(option) => option?.name}
              value={addAttendeeDetails?.competencyIds}
              error={!!errors?.competencyIds}
              helperText={errors?.competencyIds}
              renderOption={(props, option, value) => {
                return (
                  <li
                    {...props}
                    key={props?.id}
                    className={
                      option?.id === addAttendeeDetails?.competencyIds?.id
                        ? "multi-tag-edit-list-selected"
                        : "multi-tag-style"
                    }
                  >
                    <Box lineHeight={1.1}>
                      <Box>{`${option?.name}`}</Box>
                    </Box>
                  </li>
                );
              }}
            />
            <SimpleAutoComplete
              label="Designation"
              placeholder="Select Designation"
              options={addAttendeeDetails?.competencyIds?.designations?.results}
              onChange={(event, newValue) =>
                handleAddAttendeeChange("designationIds", newValue)
              }
              getOptionLabel={(option) => option?.name}
              filterOptions={(options, params) =>
                filterOptionsWithSelectAllDesignations(
                  options,
                  params,
                  addAttendeeDetails?.competencyIds?.designations?.results
                    ?.length,
                  attendeesWithAllDesignation
                )
              }
              value={
                addAttendeeDetails?.designationIds
                  ? addAttendeeDetails?.designationIds
                  : { name: "All", all: true }
              }
              disableClearable={true}
              renderOption={(props, option, value) => {
                return (
                  <li
                    {...props}
                    key={props?.id}
                    className={
                      option?.id === addAttendeeDetails?.designationIds?.id
                        ? "multi-tag-edit-list-selected"
                        : "multi-tag-style"
                    }
                  >
                    <Box lineHeight={1.1}>
                      <Box>{`${option?.name}`}</Box>
                    </Box>
                  </li>
                );
              }}
            />
            <Box className="width-100">
              <AutoCompleteTagInput
                label="Users"
                placeholder={
                  addAttendeeDetails?.attendees?.length
                    ? null
                    : "Search Name/Email"
                }
                options={userOptions}
                selectedValue={addAttendeeDetails?.attendees}
                filterOptions={(options, params) => {
                  const filter = createFilterOptions({
                    matchFrom: "any",
                    stringify: (option) => option.fullName + option.email,
                  });
                  const filtered = filter(options, params);
                  if (userOptions.length === 0) {
                    return [{ fullName: "No options", all: false }];
                  }
                  if (params.inputValue === "") {
                    return [{ fullName: "Select All", all: true }, ...filtered];
                  }
                  return filtered;
                }}
                getOptionLabel={(option) => `${option?.fullName}`}
                disabled={!addAttendeeDetails?.competencyIds}
                onChange={(event, newValue) => {
                  const idCounts = newValue.reduce((counts, { id }) => {
                    counts[id] = (counts[id] || 0) + 1;
                    return counts;
                  }, {});
                  const uniqueNewValue = newValue.filter(({ id }) => {
                    return idCounts[id] === 1;
                  });
                  if (newValue.find((option) => option.all)) {
                    return handleAddAttendeeChange(
                      "attendees",
                      addAttendeeDetails?.attendees?.length ===
                        userOptions?.length
                        ? []
                        : userOptions
                    );
                  }
                  handleAddAttendeeChange("attendees", uniqueNewValue);
                }}
                error={!!errors?.attendees}
                helperText={errors?.attendees}
                renderOption={(props, option, value) => {
                  // Check if option is "No options" and render accordingly
                  if (option.fullName === "No options") {
                    return (
                      <Box className="auto-complete-no-options">
                        <Box>{option?.fullName}</Box>
                      </Box>
                    );
                  }

                  const optionChecked =
                    (option.all &&
                      addAttendeeDetails?.attendees?.length ===
                        userOptions?.length) ||
                    addAttendeeDetails?.attendees?.find(
                      (e) => e?.id === option?.id
                    ) != null;
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        optionChecked
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box className="multi-tag-input-list-padding">
                        <Checkbox
                          className="auto-complete-checkbox-list"
                          size="small"
                          checked={optionChecked}
                          color="secondary"
                        />
                        <Box className="flex-options">
                          <Box>{`${option?.fullName}`}</Box>
                          {option?.fullName === "Select All" ? null : (
                            <Typography
                              variant="caption"
                              className="filter-title"
                            >
                              {`${option?.email}`}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </li>
                  );
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "0rem 2rem 1.875rem" }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            className="add-role-btn"
            disabled={loader}
          >
            <Typography variant="font_12_bold_700">Cancel</Typography>
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            className="add-role-btn"
            disabled={loader}
          >
            <Typography variant="font_12_bold_700">Save</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddAttendee;
