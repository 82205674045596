import React, { useState } from "react";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TablePagination from "../../components/TablePagination/TablePagination";
import IconButtons from "../../components/IconButtons/IconButtons";
import {
  delete_active_icon,
  delete_disabled_icon,
  down_sort_arrow_icon,
  edit_active_icon,
  edit_disabled_icon,
  up_sort_arrow_icon,
} from "../../constants/icons";
import {
  font_13_icon,
  font_9,
  getNewersWorldProfileUrl,
} from "../../utils/utils";
import ImageModal from "../../components/ImageModal";
import { status } from "./AttendeeUtils";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import InfoIcon from "@mui/icons-material/Info";
import { ExerciseStatusLegends } from "../../components/LegendItem/LegendItem";
import UserTableAvatar from "../../components/UserTableAvatar/UserTableAvatar";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { sortInAscending, sortInDescending } from "../../constants/constants";
import UserModuleBootcamp from "../../components/UserModuleBootcamp/UserModuleBootcamp";

const AttendeeTable = ({
  attendeesList,
  handlePageChange,
  currentPage,
  totalPages,
  handleSorting,
  totalResult,
  showColumnFlag,
  handleEditTraineeOpen,
  handleAttendeeDelete,
  canViewActions,
  sortBy,
  orderBy,
  handleDeleteModalOpen,
  isHr,
}) => {
  const security = useSelector((state) => state?.security);
  const params = useParams();
  const moduleId = params?.moduleId;

  const attendees = useSelector((state) => {
    return state?.attendees;
  });
  const moduleView = useSelector((state) => {
    return state?.bootcampModuleView;
  });

  const bootcampIsCompleted = attendees?.bootcamp?.data?.status === "COMPLETED";
  const moduleIsCompleted =
    moduleView?.moduleData?.data?.status === "COMPLETED";
  return (
    <>
      <Box className="attendees-status-table">
        <TableContainer className="table-container">
          <Table stickyHeader>
            <TableHead className="table-head-container">
              <TableRow className="table-head-row">
                <TableCell
                  align="left"
                  sx={{ minWidth: "13rem" }}
                  className="padding-left-1"
                >
                  <Typography
                    variant="font_13_bold"
                    className="flex-table-sort-head"
                  >
                    <Box className="table-sort-head">Name & Email</Box>
                    <Box className="table-sort-icon">
                      <IconButtons
                        height={
                          sortBy === "attendeeName" && orderBy === "ASC"
                            ? null
                            : font_9
                        }
                        width={
                          sortBy === "attendeeName" && orderBy === "ASC"
                            ? null
                            : font_9
                        }
                        image={up_sort_arrow_icon}
                        handleClick={() => handleSorting("ASC", "attendeeName")}
                        disabled={
                          sortBy === "attendeeName" && orderBy === "ASC"
                        }
                        tooltip={sortInAscending}
                      />
                      <IconButtons
                        height={
                          sortBy === "attendeeName" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        width={
                          sortBy === "attendeeName" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        image={down_sort_arrow_icon}
                        handleClick={() =>
                          handleSorting("DESC", "attendeeName")
                        }
                        disabled={
                          sortBy === "attendeeName" && orderBy === "DESC"
                        }
                        tooltip={sortInDescending}
                      />
                    </Box>
                  </Typography>
                </TableCell>
                {isHr && moduleId && (
                  <TableCell
                    align="left"
                    sx={{ minWidth: "13rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Bootcamp</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "bootcampName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "bootcampName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "bootcampName")
                          }
                          disabled={
                            sortBy === "bootcampName" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "bootcampName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "bootcampName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("DESC", "bootcampName")
                          }
                          disabled={
                            sortBy === "bootcampName" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                )}
                {isHr && moduleId && (
                  <TableCell
                    align="left"
                    sx={{ minWidth: "13rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Competency</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "competencyName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "competencyName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "competencyName")
                          }
                          disabled={
                            sortBy === "competencyName" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "competencyName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "competencyName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("DESC", "competencyName")
                          }
                          disabled={
                            sortBy === "competencyName" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                )}
                <TableCell
                  align="left"
                  sx={{ minWidth: "13rem" }}
                  className="padding-left-1"
                >
                  <Typography
                    variant="font_13_bold"
                    className="flex-table-sort-head"
                  >
                    <Box className="table-sort-head">Mentor</Box>
                    <Box className="table-sort-icon">
                      <IconButtons
                        height={
                          sortBy === "leadMentorName" && orderBy === "ASC"
                            ? null
                            : font_9
                        }
                        width={
                          sortBy === "leadMentorName" && orderBy === "ASC"
                            ? null
                            : font_9
                        }
                        image={up_sort_arrow_icon}
                        handleClick={() =>
                          handleSorting("ASC", "leadMentorName")
                        }
                        disabled={
                          sortBy === "leadMentorName" && orderBy === "ASC"
                        }
                        tooltip={sortInAscending}
                      />
                      <IconButtons
                        height={
                          sortBy === "leadMentorName" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        width={
                          sortBy === "leadMentorName" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        image={down_sort_arrow_icon}
                        handleClick={() =>
                          handleSorting("DESC", "leadMentorName")
                        }
                        disabled={
                          sortBy === "leadMentorName" && orderBy === "DESC"
                        }
                        tooltip={sortInDescending}
                      />
                    </Box>
                  </Typography>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ minWidth: "13rem" }}
                  className="padding-left-1"
                >
                  <Typography
                    variant="font_13_bold"
                    className="flex-table-sort-head"
                  >
                    <Box className="table-sort-head">Co-Mentor</Box>
                    <Box className="table-sort-icon">
                      <IconButtons
                        height={
                          sortBy === "coMentorName" && orderBy === "ASC"
                            ? null
                            : font_9
                        }
                        width={
                          sortBy === "coMentorName" && orderBy === "ASC"
                            ? null
                            : font_9
                        }
                        image={up_sort_arrow_icon}
                        handleClick={() => handleSorting("ASC", "coMentorName")}
                        disabled={
                          sortBy === "coMentorName" && orderBy === "ASC"
                        }
                        tooltip={sortInAscending}
                      />
                      <IconButtons
                        height={
                          sortBy === "coMentorName" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        width={
                          sortBy === "coMentorName" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        image={down_sort_arrow_icon}
                        handleClick={() =>
                          handleSorting("DESC", "coMentorName")
                        }
                        disabled={
                          sortBy === "coMentorName" && orderBy === "DESC"
                        }
                        tooltip={sortInDescending}
                      />
                    </Box>
                  </Typography>
                </TableCell>
                {showColumnFlag ? null : (
                  <TableCell
                    align="center"
                    sx={{ minWidth: "10rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Batch</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "batchName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "batchName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() => handleSorting("ASC", "batchName")}
                          disabled={sortBy === "batchName" && orderBy === "ASC"}
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "batchName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "batchName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() => handleSorting("DESC", "batchName")}
                          disabled={
                            sortBy === "batchName" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                )}
                <TableCell
                  align="center"
                  sx={{ minWidth: "10rem" }}
                  className="padding-left-1"
                >
                  <Typography
                    variant="font_13_bold"
                    className="flex-table-sort-head"
                  >
                    <Box className="table-sort-head">Status</Box>
                    <Box className="table-sort-icon">
                      <IconButtons
                        height={
                          sortBy === "status" && orderBy === "ASC"
                            ? null
                            : font_9
                        }
                        width={
                          sortBy === "status" && orderBy === "ASC"
                            ? null
                            : font_9
                        }
                        image={up_sort_arrow_icon}
                        handleClick={() => handleSorting("ASC", "status")}
                        disabled={sortBy === "status" && orderBy === "ASC"}
                        tooltip={sortInAscending}
                      />
                      <IconButtons
                        height={
                          sortBy === "status" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        width={
                          sortBy === "status" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        image={down_sort_arrow_icon}
                        handleClick={() => handleSorting("DESC", "status")}
                        disabled={sortBy === "status" && orderBy === "DESC"}
                        tooltip={sortInDescending}
                      />
                    </Box>
                  </Typography>
                </TableCell>
                {showColumnFlag ? null : (
                  <>
                    <TableCell align="center" sx={{ minWidth: "15.813rem" }}>
                      <Box className="double-head-table">
                        <Typography
                          variant="font_11_bold_700"
                          className="double-head-table-title"
                        >
                          EXERCISES
                        </Typography>
                        <Box className="double-head-table-sub">
                          <Typography
                            variant="font_13_bold"
                            className="width-15"
                          >
                            Status{" "}
                            <BootstrapTooltip
                              title={<ExerciseStatusLegends />}
                              placement="right"
                            >
                              <IconButton
                                sx={{
                                  color: "rgb(0, 0, 0)",
                                  padding: "0 0.125rem",
                                  marginBottom: "0.125rem",
                                }}
                              >
                                <InfoIcon sx={{ fontSize: 18 }} />
                              </IconButton>
                            </BootstrapTooltip>
                          </Typography>
                          <Typography
                            variant="font_13_bold"
                            className="width-10"
                          >
                            Score
                          </Typography>
                          <Typography
                            variant="font_13_bold"
                            className="width-10"
                          >
                            %
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell align="center" sx={{ minWidth: "10rem" }}>
                      <Box className="double-head-table">
                        <Typography
                          variant="font_11_bold_700"
                          className="double-head-table-title"
                        >
                          ASSESSMENT
                        </Typography>
                        <Box className="double-head-table-sub">
                          <Typography
                            variant="font_13_bold"
                            className="width-10"
                          >
                            Score
                          </Typography>
                          <Typography
                            variant="font_13_bold"
                            className="width-10"
                          >
                            %
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell align="center" sx={{ minWidth: "10rem" }}>
                      <Box className="double-head-table double-head-table-border-right">
                        <Typography
                          variant="font_11_bold_700"
                          className="double-head-table-title"
                        >
                          TOTAL
                        </Typography>
                        <Box className="double-head-table-sub">
                          <Typography
                            variant="font_13_bold"
                            className="width-10"
                          >
                            Score
                          </Typography>
                          <Typography
                            variant="font_13_bold"
                            className="width-10"
                          >
                            %
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </>
                )}
                {canViewActions && (
                  <TableCell align="center" sx={{ minWidth: "6rem" }}>
                    <Typography variant="font_13_bold">Actions</Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {attendeesList?.map((attendee, index) => {
                const bootcampattendee = !moduleId
                  ? attendee
                  : attendee?.attendee;

                const moduleMentor = attendee?.leadMentor;
                const bootcampMentor = attendee?.attendee?.leadMentor;
                const moduleCoMentor = attendee?.coMentor;
                const bootcampCoMentor = attendee?.attendee?.coMentor;

                const attendeeName = bootcampattendee?.attendee?.fullName;
                const attendeeEmail = bootcampattendee?.attendee?.email;
                const attendeeEmployeeCode =
                  bootcampattendee?.attendee?.employeeCode;
                const attendeePicture =
                  bootcampattendee?.attendee?.profilePictureUrl;
                const bootcampName = bootcampattendee?.bootcamp?.name;
                const competencyName =
                  bootcampattendee?.bootcamp?.competency?.name;

                const batchName = attendee?.batch?.name;
                const isSpocOfAttendeeBootcamp = params?.moduleId
                  ? attendee?.attendee?.bootcamp?.permission?.spoc
                  : canViewActions;

                const userDeleted =
                  bootcampattendee?.attendee?.deleted ||
                  bootcampattendee?.deleted;
                const disableActions =
                  userDeleted ||
                  (!moduleId
                    ? bootcampIsCompleted
                    : moduleIsCompleted || bootcampIsCompleted);

                return (
                  <TableRow className="table-body-row" hover key={index}>
                    <TableCell align="left" className="padding-left-1">
                      <UserTableAvatar
                        imgAlt={attendeeName}
                        imgSrc={attendeePicture}
                        href={attendeeEmployeeCode}
                        label={attendeeName}
                        subLabel={attendeeEmail}
                        deleted={userDeleted}
                      />
                    </TableCell>
                    {isHr && moduleId && (
                      <TableCell align="left" className="padding-left-1">
                        <Typography variant="font_12">
                          {bootcampName}
                        </Typography>
                      </TableCell>
                    )}
                    {isHr && moduleId && (
                      <TableCell align="left" className="padding-left-1">
                        <Typography variant="font_12">
                          {competencyName}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell align="left" className="padding-left-1">
                      <UserModuleBootcamp
                        module={moduleMentor}
                        moduleId={moduleId}
                        bootcamp={bootcampMentor}
                      />
                    </TableCell>
                    <TableCell align="left" className="padding-left-1">
                      <UserModuleBootcamp
                        module={moduleCoMentor}
                        moduleId={moduleId}
                        bootcamp={bootcampCoMentor}
                      />
                    </TableCell>
                    {showColumnFlag ? null : (
                      <TableCell align="left" className="padding-left-1">
                        {attendee?.batch ? (
                          <Typography variant="font_12">{batchName}</Typography>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    )}
                    <TableCell align="left" className="padding-left-1">
                      <Chip
                        className="same-size-chip"
                        variant={status.getVariant(attendee?.status)}
                        label={status.getTitle(attendee?.status)}
                      />
                    </TableCell>
                    {showColumnFlag ? null : (
                      <>
                        <TableCell align="center">
                          <Box className="double-head-table-body">
                            <Typography
                              variant="font_13_bold"
                              className="width-15"
                            >
                              <Chip
                                className="margin-right-half chip-padding-left-right"
                                label={attendee?.countOfExercises?.PENDING}
                                variant="BLUE"
                              />
                              <Chip
                                className="margin-right-half chip-padding-left-right"
                                label={attendee?.countOfExercises?.IN_REVIEW}
                                variant="YELLOW"
                              />
                              <Chip
                                className="margin-right-half chip-padding-left-right"
                                label={attendee?.countOfExercises?.REVIEWED}
                                variant="GREEN"
                              />
                              <Chip
                                className="chip-padding-left-right"
                                label={attendee?.countOfExercises?.REOPENED}
                                variant="RED"
                              />
                            </Typography>
                            <Typography
                              variant="font_13_bold"
                              className="width-10"
                              color="secondary"
                            >
                              {`${attendee?.exercisesMarking?.obtained}/${attendee?.exercisesMarking?.maximum}`}
                            </Typography>
                            <Typography
                              variant="font_13_bold"
                              className="width-10"
                              color="secondary"
                            >
                              {`${attendee?.exercisesMarking?.percentage}`}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box className="double-head-table-body">
                            <Typography
                              variant="font_13_bold"
                              className="width-10"
                              color="secondary"
                            >
                              {`${attendee?.assessmentsMarking?.obtained}/${attendee?.assessmentsMarking?.maximum}`}
                            </Typography>
                            <Typography
                              variant="font_13_bold"
                              className="width-10"
                              color="secondary"
                            >
                              {`${attendee?.assessmentsMarking?.percentage}`}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box className="double-head-table-body">
                            <Typography
                              variant="font_13_bold"
                              className="width-10"
                              color="secondary"
                            >
                              {`${attendee?.totalMarking?.obtained}/${attendee?.totalMarking?.maximum}`}
                            </Typography>
                            <Typography
                              variant="font_13_bold"
                              className="width-10"
                              color="secondary"
                            >
                              {`${attendee?.totalMarking?.percentage}`}
                            </Typography>
                          </Box>
                        </TableCell>
                      </>
                    )}
                    {canViewActions && isSpocOfAttendeeBootcamp && (
                      <TableCell align="center">
                        <IconButtons
                          width={font_13_icon}
                          height={font_13_icon}
                          image={edit_active_icon}
                          classList="margin-right-1"
                          handleClick={() => handleEditTraineeOpen(attendee)}
                          tooltip="Edit Attendee"
                          disabled={disableActions}
                          disabledImage={edit_disabled_icon}
                          disabledTooltip={
                            userDeleted
                              ? "Attendee is deleted"
                              : bootcampIsCompleted
                              ? "Bootcamp is completed"
                              : moduleIsCompleted
                              ? "Module is completed"
                              : null
                          }
                        />
                        {!moduleId && (
                          <IconButtons
                            width={font_13_icon}
                            height={font_13_icon}
                            image={delete_active_icon}
                            tooltip="Delete Attendee"
                            handleClick={() => handleDeleteModalOpen(attendee)}
                            disabled={disableActions}
                            disabledImage={delete_disabled_icon}
                            disabledTooltip={
                              userDeleted
                                ? "Attendee is deleted"
                                : bootcampIsCompleted
                                ? "Bootcamp is completed"
                                : moduleIsCompleted
                                ? "Module is completed"
                                : null
                            }
                          />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Grid container className="table-pagination-container">
        <Typography variant="font_16">Total {totalResult} Records</Typography>
        <TablePagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>
    </>
  );
};

export default AttendeeTable;
