import React, { useEffect, useState } from "react";
import "./questionModal.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { hasDetailsChanged } from "../../utils/utils";

const QuestionModal = ({
  open,
  handleClose,
  questionDetails,
  handleQuestionSubmit,
  questionDetailsChange,
  isEditQuestion,
  questionApiError,
  error,
}) => {
  const loader = useSelector((state) => state?.questionList.loading);

  /////////////////////// To disable update button if data isn't changed ///////////////////////
  const [initialQuestionDetails, setInitialQuestionDetails] =
    useState(questionDetails);

  useEffect(() => {
    setInitialQuestionDetails(questionDetails);
  }, [questionDetails?.questionId]);

  const questionDetailsChanged = hasDetailsChanged(
    initialQuestionDetails,
    questionDetails
  );

  return (
    <>
      {loader && <Loader />}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            height: "auto",
            width: "60rem",
            zIndex: 1300,
            borderRadius: "1rem",
            boxShadow: "0px 6px 30px 0px rgba(0, 0, 0, 0.05)",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="add-question-modal-title">
          {isEditQuestion ? "Edit Question" : "Create Question"}
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "1rem 2rem 0 !important",
            marginBottom: "1.75rem",
          }}
        >
          <TextField
            autoFocus
            multiline
            minRows={5}
            maxRows={7}
            id="name"
            shrink={false}
            size="small"
            label="Add Question"
            placeholder="Add Question"
            type="text"
            fullWidth
            margin="dense"
            variant="outlined"
            value={questionDetails.exercise[0]}
            onChange={(e) => questionDetailsChange(e?.target?.value)}
            color="secondary"
            error={!!error.question || questionApiError}
            helperText={error.question}
            required={true}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "0rem 2rem 1.875rem" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            className="block-button"
          >
            <Typography variant="outlineBtnLabel">Cancel</Typography>
          </Button>
          <Button
            onClick={handleQuestionSubmit}
            variant="contained"
            color="secondary"
            className="block-button"
            disabled={isEditQuestion && !questionDetailsChanged}
          >
            <Typography variant="outlineBtnLabel">
              {isEditQuestion ? "Update" : "Save"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuestionModal;
