import React, { useEffect, useMemo, useState } from "react";
import "./users.scss";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import ListUsersTable from "../../components/ListUsersTable/ListUsersTable";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserRole,
  clearUserData,
  deleteUserRole,
  getFiltersForUsers,
  getUserRoles,
  getUsers,
  syncData,
} from "../../store/users";
import FilterDrawer from "../../components/FilterDrawer/FilterDrawer";
import IconButtons from "../../components/IconButtons/IconButtons";
import { filter_active_icon } from "../../constants/icons";
import SingleDropdownModal from "../../components/SingleDropdownModal/SingleDropdownModal";
import DeleteModal from "./../../components/DeleteModal/DeleteModal";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import Loader from "../../components/Loader/Loader";
import {
  ROLE_TYPES,
  calculateFilterCount,
  filterRolesDropDown,
  font_15,
} from "../../utils/utils";
import { getLoggedInUser } from "../../store/security";
import { getRoles } from "@testing-library/react";

const initialFilter = {
  nameOrEmail: "",
  BU: null,
  competency: null,
  designation: [],
  reportingManagerNameOrEmail: "",
  mentorNameOrEmail: "",
  role: null,
};
const Users = () => {
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state?.users);
  const roleOptions = useSelector(
    (state) => state?.utility?.constants?.roleTypes
  );
  const allRoles = usersData?.roles?.data?.results;
  const userLoading = usersData?.userLoading;
  const dataSyncing = usersData?.syncDataLoading;
  const [isLoading, setIsLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const handleFilterOpen = () => {
    setOpenFilter(true);
    if (!usersData?.filtersForUsers?.data?.competencies?.results) {
      dispatch(getFiltersForUsers());
    }
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  const modalApiLoad = usersData?.modalApiLoading;

  const [filterActivated, setFilterActivated] = useState(false);
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilter, setIsFilter] = useState(initialFilter);
  const [userId, setUserId] = useState(null);
  const [userRoleId, setUserRoleId] = useState(null);
  const [roleAddModal, setRoleAddModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handlePageChange = (value) => {
    setOffset(value - 1);
    setCurrentPage(value);
  };
  const [filterCount, setFilterCount] = useState(0);
  const [getUserRolesMenu, setGetUserRolesMenu] = useState(null);
  const [getUserName, setGetUserName] = useState(null);
  const totalResult = usersData?.userData?.data?.totalResults;
  const security = useSelector((state) => state?.security);
  const userCompetency = security?.loggedInUser?.competency;
  const roles = security?.roles;
  const isSpocOnly =
    !ROLE_TYPES.isHr(roles) &&
    !ROLE_TYPES.isCompetencyLead(roles) &&
    !ROLE_TYPES.isAdmin(roles) &&
    ROLE_TYPES.isCompetencySpoc(roles);
  const isHr = ROLE_TYPES.isHr(roles);
  const isSpoc = ROLE_TYPES.isCompetencySpoc(roles);
  const isAdmin = ROLE_TYPES.isAdmin(roles);

  const [firstFilterOpen, setFirstFilterOpen] = useState(true);

  const handleFilterChange = (name, value) => {
    setIsFilter((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "competency" && value === null && { designation: [] }),
    }));
  };

  const handleFilterRemove = async () => {
    setOffset(0);
    setIsFilter(initialFilter);
    setIsLoading(true);
    if (filterCount) {
      await dispatch(
        getUsers({
          offset: 0,
          nameOrEmail: "",
          BU: null,
          competency: isSpocOnly
            ? security?.loggedInUser?.competency?.id
            : null,
          designation: null,
          reportingManagerNameOrEmail: "",
          mentorNameOrEmail: "",
          role: null,
        })
      );
      setCurrentPage(1);
      setFilterCount(isSpocOnly ? 1 : 0);
      setFilterActivated(false);
    }
    setIsLoading(false);
    setOpenFilter(false);
  };

  const handleFilterSubmit = async () => {
    setOffset(0);
    const filterCount = calculateFilterCount(isFilter);

    if (filterCount || filterActivated) {
      await dispatch(
        getUsers({
          offset: 0,
          nameOrEmail: isFilter?.nameOrEmail.trim(),
          BU: isFilter?.BU?.id || null,
          competency: isSpocOnly
            ? security?.loggedInUser?.competency?.id
            : isFilter?.competency?.id,
          designation: isFilter?.designation?.map((item) => item.id),
          reportingManagerNameOrEmail:
            isFilter?.reportingManagerNameOrEmail.trim(),
          mentorNameOrEmail: isFilter?.mentorNameOrEmail.trim(),
          role: isFilter?.role?.id || null,
        })
      );
      setFilterCount(filterCount);
      setFilterActivated(filterCount);
      setCurrentPage(1);
    }
    setOpenFilter(false);
  };

  const handleSubmitData = async () => {
    setIsLoading(true);
    await dispatch(
      getUsers({
        offset: offset,
        nameOrEmail: isFilter?.nameOrEmail.trim(),
        BU: isFilter?.BU?.id || null,
        competency: isSpocOnly
          ? security?.loggedInUser?.competency?.id
          : isFilter?.competency?.id,
        designation: isFilter?.designation?.map((item) => item.id),
        reportingManagerNameOrEmail:
          isFilter?.reportingManagerNameOrEmail.trim(),
        mentorNameOrEmail: isFilter?.mentorNameOrEmail.trim(),
        role: isFilter?.role?.id || null,
      })
    );
    setIsLoading(false);
  };

  const handleRoleDelete = async () => {
    const deleteId = {
      userId: userId,
      roleId: userRoleId,
    };
    await dispatch(deleteUserRole(deleteId));
    handleSubmitData();
    setDeleteModalOpen(false);
  };

  const handleSyncData = async () => {
    await dispatch(syncData());
  };

  //////////////   Filter Roles dropdown menu////////////////////////////
  const filterAddRoleMenu = async (roles, competency) => {
    const rolesIds = roles?.map((e) => e?.role);
    const getRolesList = await filterRolesDropDown(competency, roleOptions);
    const filterRolesList = getRolesList?.filter(
      (e) => !rolesIds?.includes(e?.key)
    );
    await setGetUserRolesMenu(filterRolesList);
  };

  //////////////////////////////////////////////////////////////////////
  const handleUserAddModalOpen = async (
    userName,
    userId,
    roles,
    competency
  ) => {
    setGetUserName(userName);
    await filterAddRoleMenu(roles?.results, competency);
    setUserId(userId);
    setUserRoleId(null);
    setRoleAddModal(true);
  };
  const handleUserAddModalClose = () => {
    setRoleAddModal(false);
    setUserRoleId(null);
  };

  const handleUserRoleAdd = (roleId) => {
    setAddRoleError(false);
    setUserRoleId(roleId);
  };

  const [addRoleError, setAddRoleError] = useState(false);
  const handleUserRoleSubmit = async () => {
    setIsLoading(true);
    const addRole = {
      userId: userId,
      roleId: allRoles?.filter((e) => e?.role === userRoleId?.key)[0]?.id,
      offset,
      isFilter,
    };

    if (addRole?.roleId) {
      await dispatch(addUserRole(addRole));
      setIsLoading(false);
      setRoleAddModal(false);
      setUserRoleId(null);
      handleSubmitData();
    } else {
      setAddRoleError(true);
    }
  };

  const handleDeleteModalOpen = (userId, roleId, userName) => {
    setGetUserName(userName);
    setUserId(userId);
    setUserRoleId(roleId);
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    if (
      isSpocOnly &&
      security?.loggedInUser?.competency?.id &&
      usersData?.filtersForUsers?.data?.competencies?.results
    ) {
      setIsFilter((prev) => ({
        ...prev,
        competency:
          usersData?.filtersForUsers?.data?.competencies?.results.filter(
            (it) => it?.id === security?.loggedInUser?.competency?.id
          )[0],
      }));
    }
  }, [usersData?.filtersForUsers?.data?.competencies?.results, openFilter]);

  const [sortBy, setSortBy] = useState("name");
  const [orderBy, setOrderBy] = useState("ASC");

  const handleSorting = (order, sort) => {
    setSortBy(sort);
    setOrderBy(order);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (isAdmin) {
        await dispatch(getUserRoles());
      }
      await dispatch(
        getUsers({
          offset,
          nameOrEmail: isFilter?.nameOrEmail,
          BU: isFilter?.BU?.id,
          competency: isSpocOnly
            ? security?.loggedInUser?.competency?.id
            : isFilter?.competency?.id,
          designation: isFilter?.designation?.map((item) => item.id),
          reportingManagerNameOrEmail: isFilter?.reportingManagerNameOrEmail,
          mentorNameOrEmail: isFilter?.mentorNameOrEmail,
          role: isFilter?.role?.id,
          sortBy: sortBy,
          sortOrder: orderBy,
        })
      );

      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, sortBy, orderBy, offset]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  useEffect(() => {
    setFilterCount(isSpocOnly ? 1 : filterCount);
    return () => {
      dispatch(clearUserData());
    };
  }, []);

  if ((userLoading && isLoading) || modalApiLoad) return <Loader />;
  return (
    <>
      <Grid container className="main-container">
        <Grid item className="header-container" md={12}>
          <Typography variant="font_21_bold">Users</Typography>
          <Box className="header-right-icons">
            <Button
              disabled={dataSyncing}
              variant="outlined"
              color="secondary"
              onClick={handleSyncData}
              sx={{ height: "1.875rem", marginRight: "1rem" }}
            >
              <Typography
                color={dataSyncing ? "$grey95" : "secondary"}
                variant="outlineBtnLabel"
              >
                sync data
              </Typography>
            </Button>
            <Box
              className="filterButton margin-left-1"
              sx={{
                border: filterActivated
                  ? "2px solid #de1186"
                  : "1px solid #de118680",
              }}
            >
              <IconButtons
                height={font_15}
                width={font_15}
                image={filter_active_icon}
                handleClick={handleFilterOpen}
                tooltip="Add Filters"
                count={filterCount}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} className="body-container">
          <Grid container className="table-parent-container">
            <Paper
              elevation={0}
              className="flex-container-column user-paper-container"
            >
              {!userLoading && !usersData?.userData?.data?.results?.length ? (
                <Grid container className="no-data-container">
                  <NoDataAvailable
                    imgHeight={4}
                    imgWidth={4.5}
                    message="No Users Available"
                  />
                </Grid>
              ) : (
                usersData?.userData?.data?.results?.length > 0 && (
                  <ListUsersTable
                    getUsersData={usersData?.userData}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    handleUserAddModalOpen={handleUserAddModalOpen}
                    handleDeleteModalOpen={handleDeleteModalOpen}
                    handleSorting={handleSorting}
                    sortBy={sortBy}
                    orderBy={orderBy}
                    allRoles={allRoles}
                    roleOptions={roleOptions}
                  />
                )
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <FilterDrawer
        open={openFilter}
        onClose={handleFilterClose}
        handleFilterChange={handleFilterChange}
        handleFilterRemove={handleFilterRemove}
        handleFilterSubmit={handleFilterSubmit}
        filterValue={isFilter}
        usersData={usersData}
        disabled={!isAdmin}
        isAdmin={isAdmin}
        modalApiLoad={modalApiLoad}
      />

      <SingleDropdownModal
        id="roles"
        placeholder={`Select to add Role for ${getUserName}`}
        title={`Add Role for ${getUserName}`}
        open={roleAddModal}
        handleClose={handleUserAddModalClose}
        menuItems={getUserRolesMenu}
        dropdownValue={userRoleId}
        handleDropdownChange={handleUserRoleAdd}
        handleSubmit={handleUserRoleSubmit}
        error={addRoleError}
      />
      <DeleteModal
        title={`Are you sure you want to delete user role?`}
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        handleDelete={handleRoleDelete}
      />
    </>
  );
};

export default Users;
