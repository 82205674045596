import React, { useEffect, useRef, useState } from "react";
import "./sessions.scss";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import SessionsTable from "./SessionsTable";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  addResource,
  addSession,
  clearSessionResources,
  clearSessions,
  deleteResource,
  deleteSession,
  editSession,
  getAllTopicOptions,
  getResourceBySessionId,
  getSessionFilterTopic,
  getSessionList,
} from "../../store/sessions";
import SessionCreate from "./SessionCreate";
import dayjs from "dayjs";
import SendEmailModal from "../../components/SendEmailModal/SendEmailModal";
import ResourceTableModal from "../../components/ResourceTableModal/ResourceTableModal";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import Loader from "../../components/Loader/Loader";
import AddResourceModal from "../../components/AddResourceModal/AddResourceModal";
import Constants, { EventStatus, HelpTexts } from "../../constants/constants";
import { getBootcampModuleView } from "../../store/bootcampModuleView";
import {
  ROLE_TYPES,
  calculateFilterCount,
  checkDateTimeStatus,
  checkSpecialChar,
  font_13_icon,
  isValidUrlFormat,
  regex,
} from "../../utils/utils";
import { getTopicById } from "../../store/topic";
import IconButtons from "../../components/IconButtons/IconButtons";
import { filter_active_icon } from "../../constants/icons";
import SessionFilter from "./SessionFilter";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
const tz = "Asia/Kolkata";

const initialSessionDetails = {
  sessionId: null,
  name: null,
  agenda: null,
  moduleId: null,
  topicId: null,
  containsExercise: false,
  maxExerciseScore: 0,
  startDate: null,
  duration: 8,
  associateExerciseIds: [],
  exerciseDueDate: null,
  bootcampId: null,
};
const { COMPLETED } = Constants;
const { CONCLUDED_MODULE, CONCLUDED_BOOTCAMP } = HelpTexts;
const { ONGOING } = EventStatus;

const Sessions = () => {
  const navigate = useNavigate();
  const params = useParams();
  const viaTopic = !!params?.topicId || false;
  const moduleId = +params?.moduleId || false;
  const bootcampId = params?.bootcampId;
  const dispatch = useDispatch();
  const session = useSelector((state) => {
    return state?.sessionList;
  });
  const topicData = useSelector((state) => {
    return state?.topic;
  });

  const loading = session?.listLoading;
  const isResourceLoading = session?.resourcesLoading;
  const addSessionloading = session?.addSessionloading;
  const editSessionloading = session?.editSessionloading;
  const topicOptionLoad = session?.topicLoading;
  const moduleView = useSelector((state) => {
    return state?.bootcampModuleView;
  });
  const disableEmail = moduleView?.moduleData?.data?.batches?.totalResults > 0;
  const isModuleSpoc = moduleView?.moduleData?.data?.permission?.spoc;
  const moduleStatus = moduleView?.moduleData?.data?.status;
  const enableCreateSession = !viaTopic && isModuleSpoc;
  const [isLoading, setIsLoading] = useState(false);

  const sessionList = session?.sessionList?.data?.results;
  const module = moduleView?.moduleData?.data;
  const allTopicOptions = session?.getAllTopicOptions?.data?.results;
  const exerciseOptions = session?.exerciseOptions?.data?.results;

  const security = useSelector((state) => state?.security);
  const roles = security?.roles;

  const viaModule = !!params?.moduleId || false;
  const topicName = topicData?.singleTopicData?.data?.name;
  const moduleBootcamps = moduleView?.moduleData?.data?.bootcamps?.results;
  const moduleName = moduleView?.moduleData?.data?.name;
  const [bootcampName, setBootcampName] = useState(null);
  const [isBootcampCompleted, setIsBootcampCompleted] = useState(null);
  const [currentBootcampId, setCurrentBootcampId] = useState(null);
  const isFirstRun = useRef(true);

  const isHr = ROLE_TYPES?.isHr(roles);

  useEffect(() => {
    if (!isFirstRun.current) {
      const matchedBootcamp = moduleBootcamps?.find((item) => {
        return item.id === +bootcampId;
      });
      if (matchedBootcamp) {
        setBootcampName(matchedBootcamp?.name);
        setIsBootcampCompleted(matchedBootcamp?.status === COMPLETED);
        setCurrentBootcampId(matchedBootcamp?.id);
      } else if (moduleBootcamps) {
        ////////////////// Incorrect URL Check //////////////////
        navigate("/error", { replace: true });
      }
    }
    isFirstRun.current = false;
  }, [moduleBootcamps]);

  const breadcrumbs = viaTopic
    ? [{ label: "Topics", url: "/topic" }, { label: "Sessions" }]
    : [
        { label: "Home", url: "/" },
        {
          label: bootcampName ? bootcampName : "Bootcamp",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: moduleName ? moduleName : "Module",
          url: `/bootcamp/${bootcampId}/module/${moduleId}` || "/",
          isModule: true,
          moduleId: moduleId,
        },
        { label: "Sessions" },
      ];

  //////////////////////////////////////////////////////////////////
  ////////////////////// handle  Page Change //////////////////////

  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const totalResult = session?.sessionList?.data?.totalResults;

  const handlePageChange = (value) => {
    setOffset(value - 1);
    setCurrentPage(value);
  };
  /////////////////////////////////////////////////////////////////
  /////////// Handle Resource ////////////////////////////////////
  const [openResourceTable, setOpenResourceTable] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [accessible, setAccessible] = useState(false);
  const handleResourceTableOpen = async (e, id, isAllowed, accessible) => {
    await dispatch(clearSessionResources());
    dispatch(getResourceBySessionId(id));
    setOpenResourceTable(true);
    setAccessible(accessible);
    setIsAllowed(isAllowed);
  };

  const handleResourceTableClose = () => {
    setOpenResourceTable(false);
    setIsAllowed(false);
  };

  /////////// Handle Delete ////////////////////////////////////
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [getListId, setGetListId] = useState(0);
  const [deleteFor, setDeleteFor] = useState("");
  const handleDeleteModalOpen = (id, name) => {
    setGetListId(id);
    setDeleteFor(name);
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    if (deleteFor === "Resource") {
      await dispatch(
        deleteResource({
          getListId,
          offset,
          moduleId: params?.moduleId,
          topicId: params?.topicId,
        })
      );
    } else if (deleteFor === "SessionList") {
      await dispatch(
        deleteSession({ getListId, offset, moduleId: params?.moduleId })
      );
    }
    setOpenResourceTable(false);
    setIsAllowed(false);
    setDeleteModalOpen(false);
  };

  //////////////////// validate session /////////////////////////
  const initialErrorState = {
    sessionId: null,
    name: null,
    agenda: null,
    moduleId: null,
    topicId: null,
    containsExercise: null,
    maxExerciseScore: null,
    startDate: null,
    duration: null,
    associateExerciseIds: [],
    exerciseDueDate: null,
  };
  const [errors, setErrors] = useState(initialErrorState);
  const [apiError, setApiError] = useState(false);

  const validateFields = (sessionDetails) => {
    const {
      name,
      moduleId,
      topicId,
      startDate,
      endDate,
      duration,
      maxExerciseScore,
      containsExercise,
      exerciseDueDate,
      agenda,
    } = sessionDetails;
    const isOnGoing = checkDateTimeStatus(startDate, endDate) === ONGOING;
    const newErrors = {
      name: name ? "" : "Name is required",
      moduleId: moduleId ? "" : "Module is required",
      topicId: topicId ? "" : "Topic is required",
      startDate: startDate
        ? dayjs(startDate).isBefore(dayjs()) && (!isEditSession || !isOnGoing)
          ? "Start date and time must be in the future"
          : ""
        : "Start Date is required",
      maxExerciseScore: containsExercise
        ? maxExerciseScore === "" || maxExerciseScore === undefined
          ? "Max exercise score is required"
          : maxExerciseScore <= 0
          ? "Must be greater than 0"
          : ""
        : "",
      exerciseDueDate: containsExercise
        ? exerciseDueDate
          ? dayjs(exerciseDueDate)?.isBefore(dayjs(startDate)) ||
            dayjs(exerciseDueDate)?.isBefore(dayjs())
            ? "The due date should follow the start date and be in the future"
            : ""
          : "Exercise due date is required"
        : "",
      duration:
        duration === "" || duration === undefined
          ? "Duration is required"
          : duration <= 0
          ? "Must be greater than 0"
          : duration > 8
          ? "Should not be greater than 8"
          : "",
      agenda: agenda && agenda !== "<p><br></p>" ? "" : "Agenda is required",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  /////////// Create Session ////////////////////////////////////
  const [openCreateSession, setOpenCreateSession] = useState(false);
  const handleCreateSessionOpen = () => {
    setApiError(false);
    setIsEditSession(false);
    setOpenCreateSession(true);
    if (isModuleSpoc) {
      dispatch(getAllTopicOptions());
    }
    setSessionLengthError(false);
    setSessionSpecialChar(false);
    setSeatAlphabetError1(false);
    setSeatAlphabetError2(false);
  };
  const handleCreateSessionClose = () => {
    setOpenCreateSession(false);
    setIsSessionSpoc(false);
    setIsEditSession(false);
    setSessionDetails(initialSessionDetails);
    setErrors(initialErrorState);
    setSeatAlphabetError1(false);
    setSeatAlphabetError2(false);
    setSessionLengthError(false);
    setSessionSpecialChar(false);
  };

  const [sessionDetails, setSessionDetails] = useState(initialSessionDetails);
  const [seatAlphabetError1, setSeatAlphabetError1] = useState(false);
  const [seatAlphabetError2, setSeatAlphabetError2] = useState(false);
  const [sessionSpecialChar, setSessionSpecialChar] = useState(false);
  const [sessionLengthError, setSessionLengthError] = useState(false);

  const handleSessionDetailsChange = (name, value) => {
    setApiError(false);
    setErrors((prev) => {
      return {
        ...prev,
        [name]: false,
      };
    });

    if (name === "duration" || name === "maxExerciseScore") {
      if (name === "duration") {
        setSeatAlphabetError1(false);
      }
      if (name === "maxExerciseScore") {
        setSeatAlphabetError2(false);
      }

      if ((value === "" || regex.test(value)) && Number(value) >= 0) {
        setSessionDetails((prev) => {
          return {
            ...prev,
            [name]: value === "" ? "" : Number(value),
          };
        });
      } else if (!Number(value)) {
        if (name === "duration") {
          setSeatAlphabetError1(true);
        }
        if (name === "maxExerciseScore") {
          setSeatAlphabetError2(true);
        }
      }
    } else if (name === "name") {
      if (value?.trim().length <= 50) {
        setSessionSpecialChar(checkSpecialChar(value));
        setSessionLengthError(false);
        setSessionDetails((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      } else {
        setSessionLengthError(true);
      }
    } else if (name === "containsExercise") {
      setSessionDetails((prev) => {
        return {
          ...prev,
          [name]: value,
          maxExerciseScore: sessionDetails?.maxExerciseScore
            ? sessionDetails?.maxExerciseScore
            : 0,
        };
      });
      if (
        !sessionDetails?.exerciseDueDate &&
        value === true &&
        sessionDetails?.startDate !== null
      ) {
        setSessionDetails((prev) => {
          return {
            ...prev,
            exerciseDueDate: dayjs(sessionDetails?.startDate)
              ?.set("hour", 22)
              ?.set("minute", 0),
          };
        });
      }
    } else if (name === "startDate") {
      if (!sessionDetails?.startDate) {
        setSessionDetails((prev) => {
          return {
            ...prev,
            startDate: dayjs(value)?.set("hour", 10)?.set("minute", 0),
          };
        });
      } else {
        setSessionDetails((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }

      if (
        !sessionDetails?.exerciseDueDate &&
        sessionDetails?.containsExercise
      ) {
        setSessionDetails((prev) => {
          return {
            ...prev,
            exerciseDueDate: dayjs(value)?.set("hour", 22)?.set("minute", 0),
          };
        });
      } else if (
        sessionDetails?.exerciseDueDate &&
        sessionDetails?.containsExercise
      ) {
        setSessionDetails((prev) => {
          return {
            ...prev,
            exerciseDueDate:
              value > sessionDetails?.exerciseDueDate
                ? dayjs(value)?.set("hour", 22)?.set("minute", 0)
                : sessionDetails?.exerciseDueDate,
          };
        });
      }
    } else if (name === "topicId") {
      if (value?.agenda) {
        setErrors((prev) => {
          return {
            ...prev,
            agenda: "",
          };
        });
      }
      setSessionDetails((prev) => {
        const newTopicAgenda = value?.agenda || "";
        return {
          ...prev,
          [name]: value,
          agenda: newTopicAgenda,
        };
      });
    } else {
      setSessionDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };
  ///////////////////////////////////////////////////////////////
  ///////////////////// Edit Session///////////////////////////////
  const [isEditSession, setIsEditSession] = useState(false);
  const [isSessionSpoc, setIsSessionSpoc] = useState(false);
  const handleEditSession = (session, isSessionSpoc) => {
    setIsEditSession(true);
    setIsSessionSpoc(isSessionSpoc);
    setSessionDetails({
      sessionId: session?.id,
      name: session?.name,
      agenda: session?.agenda,
      moduleId: session?.module,
      topicId: session?.topic,
      containsExercise: session?.containsExercise,
      startDate: dayjs(session?.startDate).isValid()
        ? dayjs(session?.startDate)
        : null,
      endDate: dayjs(session?.endDate).isValid()
        ? dayjs(session?.endDate)
        : null,
      duration: dayjs(session?.endDate).diff(dayjs(session?.startDate), "hour"),
      associateExerciseIds: session?.exercises,
      ...(session?.containsExercise && {
        maxExerciseScore: session?.maxExerciseScore,
        exerciseDueDate: dayjs(session?.exerciseSubmissionDueDate).isValid()
          ? dayjs(session?.exerciseSubmissionDueDate)
          : null,
      }),
      bootcampId: bootcampId,
    });
    setOpenCreateSession(true);
  };
  /////////////////////////////////////////////////////////////////
  ////////////////////// handle Session Submit  //////////////////
  const handleSubmitSession = async () => {
    const startDateFormat = dayjs(sessionDetails?.startDate)?.tz(tz)?.format();
    const endDateFormat = dayjs(sessionDetails?.startDate)
      ?.tz(tz)
      ?.add(sessionDetails?.duration, "hour")
      ?.format();

    const exerciseDueDate = dayjs(sessionDetails?.exerciseDueDate).isValid()
      ? dayjs(sessionDetails?.exerciseDueDate)?.tz(tz)?.format()
      : null;

    const data = {
      sessionId: sessionDetails?.sessionId,
      name: sessionDetails?.name,
      containsExercise: sessionDetails?.containsExercise,
      agenda: sessionDetails?.agenda,
      moduleId: module?.id || null,
      topicId: sessionDetails?.topicId?.id || null,
      startDate: startDateFormat !== "Invalid Date" ? startDateFormat : null,
      endDate: endDateFormat !== "Invalid Date" ? endDateFormat : null,
      associateExerciseIds: sessionDetails?.associateExerciseIds?.id,
      ...(sessionDetails?.containsExercise && {
        maxExerciseScore: sessionDetails?.maxExerciseScore,
        exerciseDueDate:
          startDateFormat !== "Invalid Date" ? exerciseDueDate : null,
      }),
      duration: sessionDetails?.duration,
      bootcampId: bootcampId,
    };

    let apiValue;
    if (validateFields(data)) {
      if (isEditSession) {
        apiValue = await dispatch(editSession(data));
      } else {
        apiValue = await dispatch(addSession(data));
      }

      if (apiValue?.error?.message === "Rejected") {
        setApiError(true);
      } else {
        setOpenCreateSession(false);
        setIsSessionSpoc(false);
        setSessionDetails(initialSessionDetails);
        navigate(
          `/bootcamp/${bootcampId}/module/${moduleId}/sessions/${apiValue?.payload?.data?.id}`
        );
      }
    }
  };

  /////////////////////////////////////////////////////////////
  //////////// handle view more ////////////////////////////////
  const [viewMore, setViewMore] = useState(false);
  const [viewMoreId, setViewMoreId] = useState(null);

  const handleViewMoreClick = (value) => {
    if (viewMoreId === value) {
      setViewMoreId(-1);
      setViewMore(false);
    } else {
      setViewMoreId(value);
      setViewMore(true);
    }
  };
  /////////////////////////////////////////////////////////////
  //////////////// handle SEnd Email Modal /////////////////////
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openEmailModuleId, setOpenEmailModuleId] = useState();
  const [openEmailSessionId, setOpenEmailSessionId] = useState();
  const [openEmailBatches, setOpenEmailBatches] = useState([]);

  const handleOpenSendEmailModal = (data) => {
    const batches = data?.presenters?.results?.map(({ batch }) => batch);
    setOpenEmailModuleId(data?.module?.id);
    setOpenEmailSessionId(data?.id);
    setOpenEmailBatches(batches);
    setOpenEmailModal(true);
  };
  const handleCloseSendEmailModal = () => {
    setOpenEmailModal(false);
    setOpenEmailModuleId();
    setOpenEmailSessionId();
    setOpenEmailBatches([]);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  ////////////////// Incorrect URL Check //////////////////
  const [bootcampModuleView, setBootcampModuleView] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let apiValue, topicApiValue;

      if (params?.moduleId && !isNaN(+params?.moduleId)) {
        apiValue = await dispatch(
          getBootcampModuleView({ bootcampId, moduleId: params?.moduleId })
        );
        if (
          apiValue?.error?.message === "Rejected" &&
          apiValue.payload.includes("Access Denied")
        ) {
          navigate("/error", {
            replace: true,
            state: { isAccessDenied: true },
          });
          return;
        }
        setBootcampModuleView(apiValue);
      } else if (viaTopic && !isNaN(+params?.topicId)) {
        topicApiValue = await dispatch(getTopicById({ id: params?.topicId }));
        if (
          topicApiValue?.error?.message === "Rejected" &&
          topicApiValue.payload.includes(
            "no non-deleted topic found for the Id"
          )
        ) {
          navigate("/error", { replace: true });
          return;
        }
        setBootcampModuleView(true);
      } else if (isNaN(+params?.topicId)) {
        navigate("/error", { replace: true });
        return;
      }
    };

    if (security?.loggedInUser?.id) {
      fetchData();
    }

    return () => {
      dispatch(clearSessions());
    };
  }, [dispatch]);

  ///////////////////////////////////////////////////////////////

  useEffect(() => {
    const fetchData = async () => {
      let apiValue;
      setIsLoading(true);
      apiValue = await dispatch(
        getSessionList({
          bootcampId: params?.bootcampId,
          moduleId: params?.moduleId,
          topicId: params?.topicId,
          offset,
        })
      );
      if (
        apiValue?.error?.message === "Rejected" &&
        apiValue.payload.includes("Access Denied")
      ) {
        navigate("/error", {
          replace: true,
          state: { isAccessDenied: true },
        });
        return;
      }
      setIsLoading(false);
    };
    if (bootcampModuleView !== null && !bootcampModuleView?.error) {
      fetchData();
    }
  }, [dispatch, offset, bootcampModuleView]);

  const handleExercise = (id) => {
    if (viaModule) {
      navigate(
        `/bootcamp/${bootcampId}/module/${moduleId}/sessions/${id}/questions`
      );
    } else {
      navigate(`/topic/${params?.topicId}/sessions/${id}/questions`);
    }
  };

  /////////////////////////////////////////////////////////////
  ////////////////// Add Resource Modal ///////////////////////
  const [resourceApiError, setResourceApiError] = useState(false);
  const [openResourceModal, setOpenResourceModal] = useState(false);
  const initialResourceDetails = {
    json: {
      id: "",
      name: "",
      format: "URL",
      type: Constants.SESSION,
      url: null,
    },
    files: null,
    offset: 0,
  };
  const [resourceDetails, setResourceDetails] = useState(
    initialResourceDetails
  );
  const resourceOptions = [
    {
      id: 0,
      value: "URL",
      label: "URL",
    },
    {
      id: 1,
      value: "FILE",
      label: "File",
    },
  ];

  const handleResourceModalOpen = (id) => {
    setResourceApiError(false);
    setGetListId(id);
    setOpenResourceModal(true);
  };
  const handleResourceModalClose = () => {
    setResourceApiError(false);
    setOpenResourceModal(false);
    setResourceDetails(initialResourceDetails);
  };

  const handleResourceDataChange = (name, value) => {
    setResourceDetails((previousDetails) => {
      if (name.startsWith("json.")) {
        const nestedProperty = name.split(".")[1];
        return {
          ...previousDetails,
          json: {
            ...previousDetails.json,
            [nestedProperty]: value,
          },
          moduleId: params?.moduleId,
          topicId: params?.topicId,
          offset,
        };
      } else {
        return {
          ...previousDetails,
          [name]: value,
        };
      }
    });
  };

  const handleResourceSubmit = async () => {
    let apiValue;
    const isValidUrl = () => {
      return (
        !resourceDetails?.json?.url ||
        isValidUrlFormat(resourceDetails?.json?.url)
      );
    };
    if (isValidUrl()) {
      resourceDetails.json.id = getListId;
      apiValue = await dispatch(addResource(resourceDetails));
    }

    if (apiValue?.error?.message === "Rejected") {
      setResourceApiError(true);
    } else {
      setOpenResourceModal(false);
      setResourceDetails(initialResourceDetails);
    }
  };
  ////////////////////////////////////////////////////////////////////
  ////// filter SEssion Topic //////////////////////////////
  const initialFilterSession = {
    bootcampId: null,
    topicId: null,
    name: "",
    moduleId: null,
    startDate: null,
    endDate: null,
  };
  const filterOptions = {
    bootcampIds: session?.topicSessionFilter?.data?.bootcamps?.results,
  };

  const filterLoading = session?.filterLoading;

  const [filterOpen, setFilterOpen] = useState(false);
  const [filterSession, setFilterSession] = useState(initialFilterSession);
  const [filterCount, setFilterCount] = useState(0);
  const [filterActivated, setFilterActivated] = useState(false);
  const handleFilterOpen = async () => {
    setFilterOpen(true);
    await dispatch(getSessionFilterTopic({ topicId: Number(params?.topicId) }));
  };
  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  const handleFilterSessionSubmit = async () => {
    setIsLoading(true);
    const filterCount = calculateFilterCount(filterSession);

    const data = {
      ...filterSession,
      topicId: Number(params?.topicId),
      bootcampId: filterSession?.bootcampId?.id,
      moduleId: filterSession?.moduleId?.id,
      startDate: dayjs(filterSession?.startDate).isValid()
        ? dayjs(filterSession?.startDate)
            ?.set("hour", 0)
            .set("minute", 0)
            .set("second", 0)
            ?.toISOString()
        : null,
      endDate: dayjs(filterSession?.endDate).isValid()
        ? dayjs(filterSession?.endDate)
            ?.set("hour", 23)
            .set("minute", 59)
            .set("second", 59)
            ?.toISOString()
        : null,
      offset: 0,
    };

    if (filterCount || filterActivated) {
      await dispatch(getSessionList(data));
      setFilterCount(filterCount);
      setFilterActivated(filterCount);
    }
    setFilterOpen(false);
    setIsLoading(false);
  };
  const handleFilterSessionRemove = async () => {
    setIsLoading(true);
    await setFilterSession(initialFilterSession);
    if (filterCount) {
      await dispatch(
        getSessionList({
          moduleId: params?.moduleId,
          topicId: params?.topicId,
          offset: 0,
        })
      );
      setFilterCount(0);
      setFilterActivated(false);
    }
    setFilterOpen(false);
    setIsLoading(false);
  };

  const handleChangeFilterSession = (name, value) => {
    if (name === "bootcampId") {
      setFilterSession({ ...filterSession, [name]: value, moduleId: null });
    } else {
      setFilterSession({ ...filterSession, [name]: value });
    }
  };
  /////////////////////////////////////////////////////////////////////////////////

  if ((loading && isLoading) || isLoading) return <Loader />;

  return (
    <Grid container className="main-container">
      <BreadCrumb breadcrumbs={breadcrumbs} />

      <Grid item className="header-container" md={12}>
        <Typography variant="font_21_bold">
          {viaTopic ? topicName : moduleName}
        </Typography>

        <Box className="header-right-icons">
          <BootstrapTooltip
            title={
              isBootcampCompleted
                ? CONCLUDED_BOOTCAMP
                : moduleStatus === COMPLETED
                ? CONCLUDED_MODULE
                : null
            }
          >
            <Box>
              {enableCreateSession && (
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  className="action-head-btn"
                  onClick={handleCreateSessionOpen}
                  disabled={isBootcampCompleted || moduleStatus === COMPLETED}
                >
                  <Typography variant="outlineBtnLabel">
                    Create Session
                  </Typography>
                </Button>
              )}
            </Box>
          </BootstrapTooltip>
          {viaTopic ? (
            <Box
              className="filterButton margin-left-1"
              sx={{
                border: filterActivated
                  ? "2px solid #de1186"
                  : "1px solid #de118680",
              }}
            >
              <IconButtons
                tooltip="Add Filters"
                width={font_13_icon}
                height={font_13_icon}
                image={filter_active_icon}
                handleClick={handleFilterOpen}
                count={filterCount}
              />
            </Box>
          ) : null}
        </Box>
      </Grid>

      <Grid item md={12} className="body-container">
        <Grid container className="table-parent-container">
          <Paper
            elevation={0}
            className="flex-container-column session-paper-container"
          >
            {!loading && !sessionList?.length ? (
              <Grid container className="no-data-container">
                <NoDataAvailable
                  imgHeight={4}
                  imgWidth={4.5}
                  message="No Sessions Available"
                />
              </Grid>
            ) : (
              <SessionsTable
                sessionList={sessionList}
                handleExercise={handleExercise}
                viewMore={viewMore}
                handleViewMoreClick={handleViewMoreClick}
                viewMoreId={viewMoreId}
                handleEditSession={handleEditSession}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                totalRecords={totalResult}
                handleResourceTableOpen={handleResourceTableOpen}
                viaTopic={viaTopic}
                handleOpenSendEmailModal={handleOpenSendEmailModal}
                handleDeleteModalOpen={handleDeleteModalOpen}
                handleResourceModalOpen={handleResourceModalOpen}
                isModuleSpoc={isModuleSpoc}
                disableEmail={disableEmail}
                isHr={isHr}
                module={module}
                isBootcampCompleted={isBootcampCompleted}
              />
            )}
          </Paper>
        </Grid>
      </Grid>

      <SessionCreate
        open={openCreateSession}
        onClose={handleCreateSessionClose}
        sessionDetails={sessionDetails}
        handleSessionDetailsChange={handleSessionDetailsChange}
        allTopicOptions={allTopicOptions}
        exerciseOptions={exerciseOptions}
        handleSubmitSession={handleSubmitSession}
        isEditSession={isEditSession}
        loader={addSessionloading || editSessionloading || topicOptionLoad}
        errors={errors}
        module={module}
        apiError={apiError}
        isSessionSpoc={isSessionSpoc}
        sessionSpecialChar={sessionSpecialChar}
        sessionLengthError={sessionLengthError}
        numberInputError1={seatAlphabetError1}
        numberInputError2={seatAlphabetError2}
      />

      <SendEmailModal
        open={openEmailModal}
        handleClose={handleCloseSendEmailModal}
        moduleId={openEmailModuleId}
        sessionId={openEmailSessionId}
        batches={openEmailBatches}
      />

      <AddResourceModal
        open={openResourceModal}
        onClose={handleResourceModalClose}
        value={resourceDetails}
        options={resourceOptions}
        handleChange={handleResourceDataChange}
        handleResourceSubmit={handleResourceSubmit}
        resourceApiError={resourceApiError}
      />

      <ResourceTableModal
        open={openResourceTable}
        handleClose={handleResourceTableClose}
        isAllowed={isAllowed}
        accessible={accessible}
        tableData={session?.resources?.data}
        handleDeleteModalOpen={handleDeleteModalOpen}
        viaSession={Constants.SESSION}
        viaTopic={viaTopic}
        isModuleConcluded={module?.status === Constants.COMPLETED}
        isResourceLoading={isResourceLoading}
      />

      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        handleDelete={handleDelete}
      />

      <SessionFilter
        open={filterOpen}
        onClose={handleFilterClose}
        filterSession={filterSession}
        handleFilterSessionSubmit={handleFilterSessionSubmit}
        handleFilterSessionRemove={handleFilterSessionRemove}
        handleChangeFilterSession={handleChangeFilterSession}
        filterOptions={filterOptions}
        filterLoading={filterLoading}
      />
    </Grid>
  );
};

export default Sessions;
