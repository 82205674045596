import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import IconButtons from "../../components/IconButtons/IconButtons";
import { filter_active_icon } from "../../constants/icons";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import ExercisesTable from "./ExercisesTable";
import { useParams } from "react-router-dom";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import {
  addSubmitDetails,
  getExercisesList,
  getFiltersData,
  getOptionsForEditReviewer,
} from "../../store/exercises";
import FilterExercises from "./FilterExercises";
import CommentModal from "../../components/CommentModal/CommentModal";
import { addComment, getCommentList } from "../../store/comments";
import SubmitModal from "../../components/SubmitModal/SubmitModal";
import ReviewModal from "../../components/ReviewModal/ReviewModal";
import Loader from "../../components/Loader/Loader";
import { calculateFilterCount, font_13_icon, regex } from "../../utils/utils";
import ReviewerEdit from "../SessionView/ExerciseStatusTab/ReviewerEdit";

const initialEditReviewer = {
  competencyId: null,
  designationId: null,
  reviewerId: null,
  exerciseId: null,
};

const Exercises = () => {
  const security = useSelector((state) => state?.security);
  const userCompetency = security?.loggedInUser?.competency;

  const dispatch = useDispatch();
  const params = useParams();
  const isAttendee = params?.user === "attendee" ? true : false;

  const getBootcampData = useSelector((state) => {
    return state?.exercisesList?.bootcampData;
  });

  const exercisesList = useSelector((state) => state?.exercisesList);
  const commentsList = useSelector((state) => state?.commentsList);
  const exercisesLoading = exercisesList?.loading;
  const filtersDataLoading = exercisesList?.filtersDataLoading;
  const addSubmitDetailsLoading = exercisesList?.addSubmitDetailsLoading;
  const [isLoading, setIsLoading] = useState(false);
  const filtersDataList = exercisesList?.FiltersDataList?.data;
  const submittedByList = filtersDataList?.submittedByUsers?.results?.map(
    (user) => user
  );

  const bootcampList = filtersDataList?.bootcamps?.results?.map(
    ({ id, name, modules, status, competency, startDate }) => ({
      id,
      name,
      modules,
      status,
      competency,
      startDate,
    })
  );
  const initialBootcampId = bootcampList?.[0]?.id;

  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (value) => {
    setOffset(value - 1);
    setCurrentPage(value);
  };
  const totalResult = exercisesList?.exercisesList?.data?.totalResults;
  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  const breadcrumbs = [
    {
      label: "Exercises",
    },
    { label: isAttendee ? "As Attendee" : "As Reviewer" },
  ];

  //////////////////////////////////////////////////////
  ////// filter Exercise //////////////////////////////

  const initialFilterExercises = {
    status: null,
    asAttendee: isAttendee,
    submittedById: null,
    bootcampId: bootcampList?.[0] || null,
    moduleId: null,
    sessionId: null,
    offset,
  };

  const [filterCount, setFilterCount] = useState(0);
  const [filterActivated, setFilterActivated] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterExercises, setFilterExercises] = useState(
    initialFilterExercises
  );

  //////// checking bootcamp status need for permission ////////
  const currentBootcamp = filterExercises?.bootcampId;
  const isBootcampCompleted = currentBootcamp?.status === "COMPLETED";
  ////////////////////////

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };
  const handleFilterClose = () => {
    setFilterOpen(false);
    initialFilterExercises.asAttendee = isAttendee;
    setFilterExercises({
      ...initialFilterExercises,
      bootcampId: filterExercises?.bootcampId,
    });
  };

  const handleFilterExercisesSubmit = async () => {
    const { bootcampId, moduleId, sessionId, submittedById, status } =
      filterExercises;

    const filterCount = calculateFilterCount({
      bootcampId,
      moduleId,
      sessionId,
      submittedById,
      status,
    });
    setOffset(0);
    const data = {
      ...filterExercises,
      status: status?.key || null,
      bootcampId: bootcampId?.id || null,
      moduleId: moduleId?.id || null,
      sessionId: sessionId?.id || null,
      submittedById: submittedById?.id || null,
      offset: 0,
      sortBy,
      sortOrder: orderBy,
    };

    if (filterCount || filterActivated) {
      dispatch(getExercisesList(data));
      setFilterCount(filterCount);
      setFilterActivated(filterCount);
      initialFilterExercises.asAttendee = isAttendee;
      initialFilterExercises.bootcampId = bootcampId;
      setFilterExercises(filterExercises);
    }
    setFilterOpen(false);
    setCurrentPage(1);
  };
  const handleFilterExercisesRemove = async () => {
    initialFilterExercises.asAttendee = isAttendee;
    await setFilterExercises({ ...initialFilterExercises });
    if (filterCount || filterActivated) {
      await dispatch(
        getExercisesList({
          bootcampId: initialBootcampId,
          asAttendee: isAttendee,
          offset,
          sortBy: sortBy,
          sortOrder: orderBy,
        })
      );
      setFilterCount(1);
      setFilterActivated(true);
    }
    setFilterOpen(false);
  };

  const handleChangeFilterExercises = (name, value) => {
    const updatedFilters = { ...filterExercises, [name]: value };
    if (name === "bootcampId") {
      updatedFilters.moduleId = null;
      updatedFilters.sessionId = null;
    } else if (name === "moduleId") {
      updatedFilters.sessionId = null;
    }
    setFilterExercises(updatedFilters);
  };

  //////////////////// Comment Modal ////////////////////////////
  const [openComment, setOpenComment] = useState(false);
  const [isSpoc, setIsSpoc] = useState(false);
  const [exerciseId, setExerciseId] = useState(null);
  const [comments, setComments] = useState(null);
  const [commentDetails, setCommentDetails] = useState("");
  const [canAddComment, setCanAddComment] = useState(true);

  useEffect(() => {
    setComments(commentsList?.commentsList?.data?.results);
  }, [commentsList]);
  const handleCommentOpen = async (id, isSpoc, canAddComment) => {
    setIsSpoc(isSpoc);
    setOpenComment(true);
    setExerciseId(id);
    setCanAddComment(canAddComment);
    dispatch(getCommentList({ exerciseId: id }));
  };
  const handleCommentClose = () => {
    setCommentDetails("");
    setOpenComment(false);
  };
  const handleSubmit = async (comment) => {
    if (comment) {
      await dispatch(addComment({ exerciseId, comment }));
      setCommentDetails("");
      dispatch(getCommentList({ exerciseId }));
    }
  };
  const handleInputComment = (comment) => {
    setCommentDetails(comment);
  };

  //////////////////// Edit Reviewer Modal ////////////////////////////
  const competencyList =
    exercisesList?.optionsForEditReviewer?.data?.competencies?.results;
  const editReviewerLoading = exercisesList?.editReviewerLoading;
  const [editReviewerDetails, setEditReviewerDetails] =
    useState(initialEditReviewer);
  const [previousReviewerDetails, setPreviousReviewerDetails] =
    useState(initialEditReviewer);
  const [openReviewerEdit, setOpenReviewerEdit] = useState(false);
  const [editReviewerApiError, setEditReviewerApiError] = useState(false);
  const handleEditExerciseTabOpen = async (exerciseId, reviewer, sessionId) => {
    if (!exercisesList?.optionsForEditReviewer?.data?.competencies?.results) {
      await dispatch(getOptionsForEditReviewer({ sessionId }));
    }
    const data = {
      ...editReviewerDetails,
      exerciseId: exerciseId,
      reviewerId: reviewer,
    };
    setEditReviewerDetails(data);
    setPreviousReviewerDetails(data);
    setOpenReviewerEdit(true);
  };
  const handleEditExerciseTabClose = () => {
    setOpenReviewerEdit(false);
    setEditReviewerDetails(initialEditReviewer);
    setPreviousReviewerDetails(initialEditReviewer);
  };

  const handleReviewerChange = (name, value) => {
    setEditReviewerApiError(false);
    setEditReviewerDetails({
      ...editReviewerDetails,
      [name]: value,
    });
  };

  const handleSubmitEditExercise = async () => {
    let apiValue;
    const submitDetails = {
      json: {
        reviewerId: editReviewerDetails?.reviewerId?.id,
        editReviewer: true,
      },
    };
    apiValue = await dispatch(
      addSubmitDetails({
        submitDetails,
        exerciseId: editReviewerDetails?.exerciseId,
        type: "EDIT_REVIEWER",
      })
    );
    if (apiValue?.error?.message === "Rejected") {
      setEditReviewerApiError(true);
    } else {
      handleFilterExercisesSubmit();
      handleEditExerciseTabClose();
    }
  };

  useEffect(() => {
    if (
      openReviewerEdit &&
      exercisesList?.optionsForEditReviewer?.data?.competencies?.results
    ) {
      const defaultCompetency =
        exercisesList?.optionsForEditReviewer?.data?.competencies?.results?.filter(
          (it) => it?.id === currentBootcamp?.competency?.id
        )[0];
      const reviewerCompetency = editReviewerDetails?.reviewerId?.competency
        ? exercisesList?.optionsForEditReviewer?.data?.competencies?.results?.filter(
            (it) => it?.id === editReviewerDetails?.reviewerId?.competency?.id
          )[0]
        : defaultCompetency;
      const reviewerDesignation = editReviewerDetails?.reviewerId?.designation
        ? reviewerCompetency?.designations?.results?.filter(
            (it) => it?.id === editReviewerDetails?.reviewerId?.designation?.id
          )[0]
        : null;

      const data = {
        competencyId: reviewerCompetency,
        designationId: reviewerDesignation,
      };
      setEditReviewerDetails({
        ...editReviewerDetails,
        ...data,
      });
      setPreviousReviewerDetails({
        ...previousReviewerDetails,
        ...data,
      });
    }
  }, [
    openReviewerEdit,
    exercisesList?.optionsForEditReviewer?.data?.competencies?.results,
  ]);

  useEffect(() => {
    const competency = editReviewerDetails?.competencyId?.id;
    const previousCompetency = previousReviewerDetails?.competencyId?.id;
    if (competency && previousCompetency) {
      if (competency === previousCompetency) {
        setEditReviewerDetails({
          ...editReviewerDetails,
          designationId: previousReviewerDetails?.designationId,
          reviewerId: previousReviewerDetails?.reviewerId,
        });
      }
      if (competency !== previousCompetency) {
        setEditReviewerDetails({
          ...editReviewerDetails,
          designationId: null,
          reviewerId: null,
        });
      }
    }
  }, [editReviewerDetails?.competencyId]);

  useEffect(() => {
    const designation = editReviewerDetails?.designationId?.id;
    const previousDesignation = previousReviewerDetails?.designationId?.id;
    if (designation === previousDesignation && !!previousDesignation) {
      setEditReviewerDetails({
        ...editReviewerDetails,
        reviewerId: previousReviewerDetails?.reviewerId,
      });
    }
    if (designation !== previousDesignation && !!designation) {
      setEditReviewerDetails({
        ...editReviewerDetails,
        reviewerId: null,
      });
    }
  }, [editReviewerDetails?.designationId]);

  //////////////////// Submit Modal ////////////////////////////
  const [openSubmit, setOpenSubmit] = useState(false);
  const [exerciseDetails, setExerciseDetails] = useState(null);

  const handleSubmitOpen = async (exerciseDetails) => {
    setExerciseDetails(exerciseDetails);
    setOpenSubmit(true);
  };

  const handleSubmitClose = () => {
    setOpenSubmit(false);
  };

  const handleInputCommentChange = (comment) => {
    setCommentDetails(comment);
  };

  const handleSubmitExercise = async (comment, submitDetails) => {
    if (comment) {
      const { bootcampId, status, submittedById, moduleId, sessionId } =
        filterExercises || {};

      await dispatch(
        addSubmitDetails({
          submitDetails,
          exerciseId: exerciseDetails?.exerciseId,
          type: "SUBMIT",
        })
      );
      await dispatch(
        getExercisesList({
          bootcampId: bootcampId?.id,
          asAttendee: isAttendee,
          offset,
          status: status?.key || null,
          submittedById: submittedById?.id || null,
          moduleId: moduleId?.id || null,
          sessionId: sessionId?.id || null,
          sortBy,
          sortOrder: orderBy,
        })
      );
      setOpenSubmit(false);
    }
  };

  //////////////////// Review Modal ////////////////////////////
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isReopenTextField, setIsReopenTextField] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(false);
  const [reviewDetails, setReviewDetails] = useState({
    score: null,
    comment: null,
    maxExerciseScore: null,
  });

  const [initialExerciseDetails, setInitialExerciseDetails] = useState({
    score: null,
    comment: null,
    maxExerciseScore: null,
  });

  const submitReviewOpen = (exerciseDetails) => {
    setSelectedExercise(exerciseDetails);
    setReviewDetails(exerciseDetails);
    setOpenReviewModal(true);
  };

  const submitReviewClose = () => {
    setReviewDetails((prev) => {
      return {
        ...prev,
        score: null,
        comment: null,
      };
    });
    setInitialExerciseDetails({
      score: null,
      comment: null,
      maxExerciseScore: null,
    });
    setIsEditMode(false);
    setIsReopenTextField(false);
    setOpenReviewModal(false);
  };

  const handleInputReviewInput = (value, name) => {
    if (name === "score") {
      if ((value === "" || regex.test(value)) && Number(value) >= 0) {
        setReviewDetails((prev) => {
          return {
            ...prev,
            [name]: +value,
          };
        });
      }
    } else {
      setReviewDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const submitReview = async (submitDetails) => {
    if (
      (isReopenTextField && submitDetails?.json?.comment) ||
      (submitDetails?.json?.score && submitDetails?.json?.comment)
    ) {
      await dispatch(
        addSubmitDetails({
          submitDetails,
          exerciseId: selectedExercise?.exerciseId,
          type: isEditMode
            ? "EDIT_SCORE"
            : isReopenTextField
            ? "REOPEN"
            : "REVIEW",
        })
      );
      await dispatch(
        getExercisesList({
          bootcampId: filterExercises?.bootcampId?.id,
          asAttendee: isAttendee,
          offset,
          status: filterExercises?.status?.key
            ? filterExercises?.status?.key
            : null,
          submittedById: filterExercises?.submittedById?.id,
          moduleId: filterExercises?.moduleId?.id,
          sessionId: filterExercises?.sessionId?.id,
          sortBy: sortBy,
          sortOrder: orderBy,
        })
      );
      setOpenReviewModal(false);
      setIsEditMode(false);
      setIsReopenTextField(false);
    }
  };

  const handleEditReviewModalOpen = (editDetails) => {
    setSelectedExercise(editDetails);
    setIsEditMode(true);
    setReviewDetails((prev) => {
      return {
        ...prev,
        score: +editDetails?.score,
        comment: editDetails?.comment,
        maxExerciseScore: editDetails?.maxExerciseScore,
      };
    });
    setInitialExerciseDetails({
      ...reviewDetails,
      score: +editDetails?.score,
      comment: editDetails?.comment,
    });
    setOpenReviewModal(true);
  };
  const handleReopenModalOpen = (reopenDetails) => {
    setSelectedExercise(reopenDetails);
    setIsReopenTextField(true);
    setOpenReviewModal(true);
  };

  useEffect(() => {
    setOffset(0);
    setCurrentPage(1);
    setSortBy("exerciseSubmissionDueDate");
    setOrderBy("DESC");
    setFilterExercises(initialFilterExercises);
    setFilterActivated(true);
    setFilterCount(1);
  }, [isAttendee]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        getFiltersData({
          asAttendee: isAttendee,
        })
      );
    };
    if (security?.loggedInUser?.id) {
      fetchData();
    }
  }, [dispatch, isAttendee]);

  const [sortBy, setSortBy] = useState("exerciseSubmissionDueDate");
  const [orderBy, setOrderBy] = useState("DESC");

  const handleSorting = (order, sort) => {
    setSortBy(sort);
    setOrderBy(order);
  };

  const fetchExercises = async () => {
    await dispatch(
      getExercisesList({
        bootcampId: filterExercises?.bootcampId?.id || initialBootcampId,
        asAttendee: isAttendee,
        offset,
        sortBy: sortBy,
        sortOrder: orderBy,
      })
    );
  };
  useEffect(() => {
    if (filterExercises?.bootcampId?.id || initialBootcampId) {
      fetchExercises();
    }
  }, [dispatch, isAttendee, initialBootcampId, sortBy, orderBy, offset]);
  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  useEffect(() => {
    setFilterExercises((prev) => {
      return {
        ...prev,
        bootcampId: bootcampList?.[0] || null,
        asAttendee: isAttendee,
      };
    });
    setFilterCount(1);
    setFilterActivated(true);
  }, [bootcampList?.[0]?.id]);

  if (exercisesLoading || filtersDataLoading || isLoading) return <Loader />;
  return (
    <>
      <Grid container className="main-container">
        <BreadCrumb breadcrumbs={breadcrumbs} />

        <Grid item className="header-container" md={12}>
          <Typography variant="font_21_bold">
            {isAttendee ? "Attendee Exercises" : "Reviewer Exercises"}
          </Typography>
          <Box className="header-right-icons">
            <Box
              className="filterButton margin-left-1"
              sx={{
                border: filterActivated
                  ? "2px solid #de1186"
                  : "1px solid #de118680",
              }}
            >
              <IconButtons
                height={font_13_icon}
                width={font_13_icon}
                image={filter_active_icon}
                handleClick={handleFilterOpen}
                tooltip="Add Filters"
                count={filterCount}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} className="body-container" sx={{ width: "100%" }}>
          <Grid container className="table-parent-container">
            <Paper
              elevation={0}
              className="flex-container-column exercise-paper-container"
            >
              {exercisesList?.exercisesList?.data?.results.length &&
              bootcampList ? (
                <ExercisesTable
                  getExercisesData={exercisesList?.exercisesList}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                  isAttendee={isAttendee}
                  handleSorting={handleSorting}
                  handleCommentOpen={handleCommentOpen}
                  handleSubmitOpen={handleSubmitOpen}
                  submitReviewOpen={submitReviewOpen}
                  handleEditReviewModalOpen={handleEditReviewModalOpen}
                  handleReopenModalOpen={handleReopenModalOpen}
                  sortBy={sortBy}
                  order={orderBy}
                  isBootcampCompleted={isBootcampCompleted}
                  handleEditExerciseTabOpen={handleEditExerciseTabOpen}
                />
              ) : (
                <Grid container className="no-data-container">
                  <NoDataAvailable
                    imgHeight={4}
                    imgWidth={4.5}
                    message="No Exercises Available"
                  />
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <FilterExercises
        open={filterOpen}
        isAttendee={isAttendee}
        onClose={handleFilterClose}
        filterExercises={filterExercises}
        bootcampList={bootcampList}
        submittedByList={submittedByList}
        handleChangeFilterExercises={handleChangeFilterExercises}
        handleFilterExercisesSubmit={handleFilterExercisesSubmit}
        handleFilterExercisesRemove={handleFilterExercisesRemove}
      />
      <CommentModal
        open={openComment}
        commentDetails={commentDetails}
        handleClose={handleCommentClose}
        handleSubmit={(comment) => handleSubmit(comment)}
        handleInputComment={handleInputComment}
        comments={comments}
        exerciseId={exerciseId}
        openComment={setOpenComment}
        canAddComment={canAddComment}
        canEditDeleteComments={isSpoc && canAddComment}
        isBootcampCompleted={isBootcampCompleted}
      />
      <ReviewerEdit
        open={openReviewerEdit}
        handleClose={handleEditExerciseTabClose}
        handleEditReviewerChange={handleReviewerChange}
        competencyList={competencyList}
        editReviewerDetails={editReviewerDetails}
        handleSubmit={handleSubmitEditExercise}
        loader={editReviewerLoading}
        apiError={editReviewerApiError}
      />
      <SubmitModal
        open={openSubmit}
        exerciseDetails={exerciseDetails}
        commentDetails={commentDetails}
        handleInputCommentChange={handleInputCommentChange}
        handleClose={handleSubmitClose}
        handleSubmit={(comment, submitDetails) =>
          handleSubmitExercise(comment, submitDetails)
        }
        loader={addSubmitDetailsLoading}
      />

      <ReviewModal
        open={openReviewModal}
        initialExerciseDetails={initialExerciseDetails}
        reviewDetails={reviewDetails}
        handleInputReviewInput={handleInputReviewInput}
        handleClose={submitReviewClose}
        handleSubmitReview={(comment, submitDetails) =>
          submitReview(comment, submitDetails)
        }
        isEditMode={isEditMode}
        isReopenTextField={isReopenTextField}
      />
    </>
  );
};

export default Exercises;
