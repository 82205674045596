export const apiVersion = "/api/v1";

const Constants = {
  AUTHCOOKIEKEY: (env) => `ttn_bootcamp_auth_cookie_${env}`,
  IMPERSONATECOOKIEKEY: (env) => `ttn_bootcamp_auth_impersonate_cookie_${env}`,
  TOPIC: "TOPIC",
  SESSION: "SESSION",
  ACTIVE: "ACTIVE",
  COMPLETED: "COMPLETED",
  SCHEDULED: "SCHEDULED",
  IN_REVIEW: "IN_REVIEW",
  REVIEWED: "REVIEWED",
  PENDING: "PENDING",
  REOPENED: "REOPENED",
};

export default Constants;

export const attendance = [
  { id: 0, title: "Not Marked", value: "NOT_MARKED", variant: "BLUE" },
  { id: 1, title: "Present", value: "PRESENT", variant: "GREEN" },
  { id: 2, title: "Absent", value: "ABSENT", variant: "RED" },
];

export const TOAST_TYPE = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
};

export const TOAST_TIME = {
  ONE: "1000",
  TWO: "2000",
  THREE: "3000",
  FOUR: "4000",
  FIVE: "5000",
};

export const TOAST_TITLE = {
  ERROR: "Something Went Wrong!",
  SUCCESS: "Task Completed!",
  WARNING: "warning",
  INFO: "Did You Know?",
};

export const LOCAL_STORAGE_KEYS = {
  BOOTCAMP_SWITCH_USER: "BOOTCAMP_SWITCH_USER",
  USER_LOGOUT: "USER_LOGOUT",
};

export const sortInAscending = "Sort in Ascending";
export const sortInDescending = "Sort in Descending";

export const permissionKeys = {
  SPOC: "spoc",
  SESSION_PRESENTER: "sessionPresenter",
  MENTOR: "mentor",
  ATTENDEE: "attendee",
  EXERCISE_REVIEWER: "exerciseReviewer",
};

export const EventStatus = {
  FUTURE: "future",
  ONGOING: "ongoing",
  PAST: "past",
};

export const HelpTexts = {
  CONCLUDED_BOOTCAMP: "Bootcamp is completed",
  ACTIVE_BOOTCAMP: "Bootcamp is active",
  CONCLUDED_MODULE: "Module is completed",
  ACTIVE_MODULE: "Module is active",
  CONCLUDED_SESSION: "Session is completed",
  ACTIVE_SESSION: "Session is already started",
  NO_BATCHES: "Have no batches",
  BATCH_CONTAINS_ATTENDEE: "Cannot delete as batch contains attendees",
  NO_PRESENTERS: "No presenter is assigned yet",
  ATTENDEE_DELETED: "Attendee has been deleted",
  ENTER_VALID_URL: "Enter a valid URL",
  ASSIGNED_REVIEWER_CAN_REVIEW:
    "Only the assigned reviewer can review the exercise",
  ASSIGNED_REVIEWER_CAN_REOPEN:
    "Only the assigned reviewer can reopen the exercise",
  ASSIGNED_REVIEWER_CAN_EDIT_SCORE:
    "Only the assigned reviewer can edit the exercise score",
  ALL_ROLES_ASSIGNED: "All roles assigned to user",
  SELF_REMOVAL_OF_ADMIN_ROLE: "Self removal of the Admin role is not permitted",
  ASSOCIATED_QUESTION_NOT_EDITABLE:
    "The associated question is not editable through sessions",
  RESOURCE_BELONGS_TO_TOPIC_NOT_DELETABLE:
    "This resource cannot be deleted as it belongs to the topic",
};
