import React, { useState } from "react";
import "./filterDrawer.scss";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  Grid,
  MenuItem,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import IconButtons from "../IconButtons/IconButtons";
import { cross_default_icon } from "../../constants/icons";
import Loader from "../Loader/Loader";
import { font_15, ROLE_TYPES } from "./../../utils/utils";
import SimpleAutoComplete from "../SimpleAutoComplete/SimpleAutoComplete";
import AutoCompleteTagInput from "../AutoCompleteTagInput/AutoCompleteTagInput";

const FilterDrawer = ({
  open,
  onClose,
  handleFilterChange,
  handleFilterRemove,
  handleFilterSubmit,
  filterValue,
  disabled,
  isAdmin,
  modalApiLoad,
  usersData,
}) => {
  const roles = usersData?.filtersForUsers?.data?.roles?.results?.filter(
    (e) => e?.role !== "COMPETENCY_LEAD" && e?.role !== "USER"
  );
  const [loader, setLoader] = useState(false);

  const handleFilter = async () => {
    setLoader(true);
    await handleFilterSubmit();
    setLoader(false);
  };

  if (loader) {
    return <Loader />;
  }
  return (
    <>
      {modalApiLoad && <Loader />}
      <Drawer
        className="filter-drawer-container"
        anchor="right"
        open={open}
        onClose={onClose}
      >
        <Grid container className="filter-main-container">
          <Grid item md={12} pt={2} className="drawer-input-container">
            <Box className="filter-head-close">
              <IconButtons
                handleClick={onClose}
                height={font_15}
                width={font_15}
                image={cross_default_icon}
                tooltip="Close Filter"
              />
            </Box>
            <Box className="filter-head filter-padding">
              <Typography variant="font_16" className="filter-title ">
                Filters
              </Typography>
            </Box>
            <Box className="filter-padding textfield-box">
              <TextField
                size="small"
                label="Name/Email"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                value={filterValue?.nameOrEmail}
                onChange={(e) =>
                  handleFilterChange("nameOrEmail", e?.target?.value)
                }
              />
              <SimpleAutoComplete
                label="Business Unit"
                placeholder="Select Business Unit"
                options={
                  usersData?.filtersForUsers?.data?.businessUnits?.results
                }
                onChange={(event, newValue) =>
                  handleFilterChange("BU", newValue)
                }
                getOptionLabel={(option) => option?.name || ""}
                value={filterValue?.BU}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.name === filterValue?.BU?.name
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
              />
              <SimpleAutoComplete
                disabled={disabled}
                label="Competency"
                placeholder="Select Competency"
                options={
                  usersData?.filtersForUsers?.data?.competencies?.results
                }
                onChange={(event, newValue) =>
                  handleFilterChange("competency", newValue)
                }
                getOptionLabel={(option) => option?.name || ""}
                value={filterValue?.competency}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.id === filterValue?.competency?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
              />

              <AutoCompleteTagInput
                label="Designations"
                placeholder={
                  filterValue?.designation?.length ? null : "Select Designation"
                }
                options={filterValue?.competency?.designations?.results}
                selectedValue={
                  !filterValue.competency ? [] : filterValue?.designation
                }
                getOptionLabel={(option) => option?.name}
                filterOptions={(options, params) => {
                  // <<<--- inject the Select All option
                  const filter = createFilterOptions();
                  const filtered = filter(options, params);
                  if (
                    filterValue?.competency?.designations?.results.length === 0
                  ) {
                    return [{ name: "No options", all: false }];
                  }
                  return [{ name: "Select All", all: true }, ...filtered];
                }}
                onChange={(event, newValue) => {
                  const idCounts = newValue.reduce((counts, { id }) => {
                    counts[id] = (counts[id] || 0) + 1;
                    return counts;
                  }, {});
                  const uniqueNewValue = newValue.filter(({ id }) => {
                    return idCounts[id] === 1;
                  });
                  if (newValue.find((option) => option.all)) {
                    return handleFilterChange(
                      "designation",
                      filterValue?.designation?.length ===
                        filterValue?.competency?.designations?.results?.length
                        ? []
                        : filterValue?.competency?.designations?.results
                    );
                  }
                  handleFilterChange("designation", uniqueNewValue);
                }}
                disabled={!filterValue.competency}
                renderOption={(props, option, value) => {
                  // Check if option is "No options" and render accordingly
                  if (option.name === "No options") {
                    return (
                      <Box className="auto-complete-no-options">
                        <Box>{option?.name}</Box>
                      </Box>
                    );
                  }

                  const optionChecked =
                    (option.all &&
                      filterValue?.designation?.length ===
                        filterValue?.competency?.designations?.results
                          ?.length) ||
                    filterValue?.designation?.find(
                      (e) => e?.id === option?.id
                    ) != null;
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        optionChecked
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box className="multi-tag-input-list-padding">
                        <Checkbox
                          className="auto-complete-checkbox-list"
                          size="small"
                          checked={optionChecked}
                          color="secondary"
                        />
                        {`${option?.name}`}
                      </Box>
                    </li>
                  );
                }}
              />

              <TextField
                size="small"
                label="Reporting Manager Name/Email"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                value={filterValue?.reportingManagerNameOrEmail}
                onChange={(e) =>
                  handleFilterChange(
                    "reportingManagerNameOrEmail",
                    e?.target?.value
                  )
                }
              />
              <TextField
                size="small"
                label="Mentor Name/Email"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                value={filterValue?.mentorNameOrEmail}
                onChange={(e) =>
                  handleFilterChange("mentorNameOrEmail", e?.target?.value)
                }
              />
              {isAdmin && (
                <SimpleAutoComplete
                  label="Role"
                  placeholder="Select Role"
                  options={roles}
                  onChange={(event, newValue) =>
                    handleFilterChange("role", newValue)
                  }
                  getOptionLabel={(option) => ROLE_TYPES.getLabel(option?.role)}
                  value={filterValue?.role}
                  renderOption={(props, option, value) => {
                    return (
                      <li
                        {...props}
                        key={props?.id}
                        className={
                          option?.id === filterValue?.role?.id
                            ? "multi-tag-edit-list-selected"
                            : "multi-tag-style"
                        }
                      >
                        <Box lineHeight={1.1}>
                          <Box>{`${option?.role}`}</Box>
                        </Box>
                      </li>
                    );
                  }}
                />
              )}
            </Box>
          </Grid>
          <Grid item md={12} className="filter-padding filter-btn-container">
            <Button
              onClick={handleFilter}
              variant="contained"
              color="secondary"
              className="block-button filter-btn margin-right-1"
            >
              <Typography variant="outlineBtnLabel">Apply Filters</Typography>
            </Button>
            <Button
              onClick={handleFilterRemove}
              variant="outlined"
              color="secondary"
              className="block-button filter-btn"
            >
              <Typography variant="outlineBtnLabel">Reset</Typography>
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

export default FilterDrawer;
