import React, { useState } from "react";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import IconButtons from "../../components/IconButtons/IconButtons";
import {
  delete_active_icon,
  delete_disabled_icon,
  edit_active_icon,
  edit_disabled_icon,
  right_arrow_active_icon,
} from "../../constants/icons";
import {
  font_13,
  getBootcampStatus,
  getDate,
  hasAnyPermission,
} from "../../utils/utils";
import { useNavigate } from "react-router";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { HelpTexts } from "../../constants/constants";

const { ACTIVE_MODULE, CONCLUDED_MODULE, CONCLUDED_BOOTCAMP } = HelpTexts;

const BootcampModuleExpand = ({
  bootcamp,
  modules,
  handleDeleteModalOpen,
  handleEditBootcampModule,
  isHr,
  expandBootcampId,
  isBootcampConcluded,
}) => {
  const navigate = useNavigate();

  //////////// handle view more ////////////////////////////////
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const [viewMore, setViewMore] = useState(false);
  const [viewMoreId, setViewMoreId] = useState(null);
  const handleViewMoreClick = (value) => {
    if (viewMoreId === value) {
      setViewMoreId(-1);
      setViewMore(false);
    } else {
      setViewMoreId(value);
      setViewMore(true);
    }
  };

  /////////////////////////////////////////////////////////////

  return (
    <>
      {modules?.data?.results?.length > 0 ? (
        modules?.data?.results?.map((mod, index) => {
          const attendeeCountForBootcamp = !!mod?.batchAttendees[
            expandBootcampId
          ]
            ? mod?.batchAttendees[expandBootcampId]
            : 0;
          const permission = mod?.permission;
          const moduleIsActive = mod?.status === "ACTIVE";
          const moduleIsConcluded = mod?.status === "COMPLETED";
          const isModuleNSessionListAllowed =
            isHr || hasAnyPermission(permission);
          const isAttendeeListAllowed =
            mod?.permission?.spoc || mod?.permission?.mentor || isHr;

          const wordCount = mod?.description?.length;
          const countLines = mod?.description?.split(/\r|\r\n|\n/);
          const lineLength = countLines?.length;
          const moduleStatus = getBootcampStatus(mod?.status);
          return (
            <Box className="expanded-module-container" key={index}>
              <Box
                className="sub-module-left-head-container"
                sx={{
                  backgroundColor: moduleStatus?.color,
                  color: moduleStatus?.text,
                }}
              >
                <Typography
                  variant="font_15_bold"
                  fontWeight={500}
                  className="sub-module-left-head sub-module-left-head-nowrap"
                >
                  {moduleStatus?.name}
                </Typography>
              </Box>
              <Box
                className={`sub-module-middle-container sub-module-${moduleStatus?.name.toLowerCase()}`}
              >
                <Box className="sub-module-head-container">
                  <Box className="sub-module-heading">
                    <Typography
                      variant="font_12_bold"
                      color="secondary"
                      className="sub-module-head"
                    >
                      {mod?.name}
                    </Typography>
                  </Box>
                  <Box className="sub-module-buttons">
                    {mod?.permission?.spoc && (
                      <>
                        <IconButtons
                          width={font_13}
                          height={font_13}
                          image={edit_active_icon}
                          handleClick={() =>
                            handleEditBootcampModule(bootcamp, mod)
                          }
                          classList="margin-right-1"
                          tooltip="Edit Module"
                          disabledImage={edit_disabled_icon}
                          disabled={isBootcampConcluded || moduleIsConcluded}
                          disabledTooltip={
                            isBootcampConcluded
                              ? CONCLUDED_BOOTCAMP
                              : moduleIsConcluded
                              ? CONCLUDED_MODULE
                              : null
                          }
                        />
                        <IconButtons
                          width={font_13}
                          height={font_13}
                          image={delete_active_icon}
                          handleClick={(e) =>
                            handleDeleteModalOpen(e, mod, "module")
                          }
                          tooltip="Delete Module"
                          classList="margin-right-1"
                          disabledImage={delete_disabled_icon}
                          disabled={
                            isBootcampConcluded ||
                            moduleIsConcluded ||
                            moduleIsActive
                          }
                          disabledTooltip={
                            isBootcampConcluded
                              ? CONCLUDED_BOOTCAMP
                              : moduleIsConcluded
                              ? CONCLUDED_MODULE
                              : moduleIsActive
                              ? ACTIVE_MODULE
                              : null
                          }
                        />
                      </>
                    )}
                    {isModuleNSessionListAllowed && (
                      <IconButtons
                        width={font_13}
                        height={font_13}
                        image={right_arrow_active_icon}
                        tooltip="View Module"
                        handleClick={() => {
                          navigate(
                            `/bootcamp/${bootcamp?.id}/module/${mod?.id}`
                          );
                        }}
                      />
                    )}
                  </Box>
                </Box>
                <Grid
                  container
                  gap={0}
                  spacing={0}
                  className="sub-modal-cells-container"
                >
                  <Grid item md={5.46} className="sub-modal-cells">
                    <Typography
                      variant="font_11_bold"
                      className="sub-modal-cell-subtitle"
                    >
                      Description
                    </Typography>
                    <Collapse
                      className={
                        !(wordCount > 200 || lineLength > 3)
                          ? "adjusted-height"
                          : null
                      }
                      in={viewMore && viewMoreId === mod?.id}
                      collapsedSize={
                        wordCount > 200 || lineLength > 3 ? 50 : "auto"
                      }
                    >
                      <Typography
                        variant="font_11_bold"
                        sx={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        {mod?.description}
                      </Typography>
                    </Collapse>
                    {wordCount > 200 || lineLength > 3 ? (
                      <Box
                        onClick={() => handleViewMoreClick(mod?.id)}
                        className="expand-more"
                      >
                        <Typography
                          variant="font_9_bold"
                          color="secondary"
                          cursor="pointer"
                          sx={{ fontWeight: 600 }}
                        >
                          {viewMore && viewMoreId === mod?.id
                            ? "VIEW LESS"
                            : "VIEW MORE"}
                        </Typography>
                        <ExpandMore
                          expand={viewMoreId === mod?.id}
                          sx={{ padding: 0, verticalAlign: "sub" }}
                        >
                          <ExpandMoreIcon
                            color="secondary"
                            sx={{
                              fontSize: "1rem",
                            }}
                          />
                        </ExpandMore>
                      </Box>
                    ) : null}
                  </Grid>
                  <Grid item md={1.46} className="sub-modal-cells">
                    <Typography
                      variant="font_11_bold"
                      className="sub-modal-cell-subtitle"
                    >
                      Start Date
                    </Typography>
                    <Typography variant="font_11_bold">
                      {getDate(mod?.startDate)}
                    </Typography>
                  </Grid>
                  <Grid item md={1.46} className="sub-modal-cells">
                    <Typography
                      variant="font_11_bold"
                      className="sub-modal-cell-subtitle"
                    >
                      End Date
                    </Typography>
                    <Typography variant="font_11_bold">
                      {getDate(mod?.endDate)}
                    </Typography>
                  </Grid>

                  <Grid item md={2.16} className="sub-modal-cells">
                    <Typography
                      variant="font_11_bold"
                      className="sub-modal-cell-subtitle"
                    >
                      Current Bootcamp's Attendees
                    </Typography>
                    <Box>
                      {isAttendeeListAllowed && attendeeCountForBootcamp ? (
                        <>
                          <Typography
                            variant="font_11_bold"
                            color={
                              isAttendeeListAllowed &&
                              attendeeCountForBootcamp &&
                              "secondary"
                            }
                            {...(isAttendeeListAllowed &&
                              attendeeCountForBootcamp && {
                                onClick: () => {
                                  navigate(
                                    `/bootcamp/${bootcamp?.id}/module/${mod?.id}/attendees`
                                  );
                                },
                              })}
                            className={`${
                              isAttendeeListAllowed && attendeeCountForBootcamp
                                ? "cursor-pointer"
                                : ""
                            }`}
                          >
                            <u>
                              <BootstrapTooltip title="Show Attendees">
                                <span>{attendeeCountForBootcamp}</span>
                              </BootstrapTooltip>
                            </u>
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="font_11_bold" component="span">
                          {attendeeCountForBootcamp}/
                          {mod?.batchAttendees?.total}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item md={1.46} className="sub-modal-cells">
                    <Typography
                      variant="font_11_bold"
                      className="sub-modal-cell-subtitle"
                    >
                      Total Sessions
                    </Typography>
                    <Typography
                      color={isModuleNSessionListAllowed && "secondary"}
                      variant="font_11_bold"
                      {...(isModuleNSessionListAllowed && {
                        onClick: () => {
                          navigate(
                            `/bootcamp/${bootcamp?.id}/module/${mod?.id}/sessions`
                          );
                        },
                      })}
                      className={`${
                        isModuleNSessionListAllowed ? "cursor-pointer" : ""
                      }`}
                    >
                      {isModuleNSessionListAllowed ? (
                        <u>
                          <BootstrapTooltip title="Show Sessions">
                            {mod?.sessions?.totalResults}
                          </BootstrapTooltip>
                        </u>
                      ) : (
                        mod?.sessions?.totalResults
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          );
        })
      ) : (
        <Grid container>
          <NoDataAvailable
            imgHeight={4}
            imgWidth={4.5}
            message="No Modules Available"
          />
        </Grid>
      )}
    </>
  );
};

export default BootcampModuleExpand;
