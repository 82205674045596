import React, { useEffect, useRef, useState } from "react";
import "./home.scss";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import HomeTable from "./HomeTable";
import IconButtons from "../../components/IconButtons/IconButtons";
import {
  filter_active_icon,
  asc_sort_icon,
  desc_sort_icon,
} from "../../constants/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addBootcamp,
  deleteBootcamp,
  editBootcamp,
  getBootcamp,
  getCompetencies,
  getModules,
  getBootcampById,
  clearModules,
  getCreateEditBootcampOptions,
  getCreateEditModuleOptions,
  getFiltersForBootcamp,
  clearBootcamps,
} from "../../store/bootcamp";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import CreateBootcamp from "./CreateBootcamp";
import {
  addBootcampModule,
  deleteBootcampModule,
  editBootcampModule,
} from "../../store/bootcampModule";
import CreateBootcampModule from "./CreateBootcampModule";
import FilterBootcamp from "./FilterBootcamp";
import dayjs, { Dayjs } from "dayjs";
import Loader from "../../components/Loader/Loader";
import {
  ROLE_TYPES,
  calculateFilterCount,
  checkSpecialChar,
  encode,
  font_13_icon,
  font_17,
  sortByFullName,
  getMaxDate,
  toWorkingDay,
  getChangedIds,
} from "../../utils/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom/dist";
import Constants from "../../constants/constants";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);
const tz = "Asia/Kolkata";

const initialBootcampDetails = {
  bootcampId: "",
  name: "",
  startDate: toWorkingDay(
    dayjs().add(1, "day").set("hour", 9).set("minute", 0).set("second", 0)
  ),
  competencyId: null,
  spocIds: [],
  moduleIds: [],
  nameEditedByUser: false,
};

const initialBootcampModule = {
  moduleId: "",
  name: "",
  startDate: null,
  endDate: null,
  description: "",
  mode: "ONLINE",
  bootcampIds: [],
};

const initialFilterBootcamp = {
  name: "",
  status: "",
  competencyId: null,
  startDate: null,
  endDate: null,
};
const bootcampsToAppear = () => {
  const screenSize = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  const MaxData = (screenSize?.height - 176) / 100 + 2;
  return MaxData;
};

const { ACTIVE, COMPLETED, SCHEDULED } = Constants;

const Home = () => {
  const location = useLocation();
  const bootcampTiles = bootcampsToAppear();
  const navigate = useNavigate();
  const tilesCount = bootcampTiles > 10 ? Math.floor(bootcampTiles) : 10;
  const security = useSelector((state) => state?.security);
  const roles = security?.roles;
  const isHr = ROLE_TYPES?.isHr(roles);
  const isHrOnly = ROLE_TYPES.isOnlyRole(roles, "HR");
  const isHrAndAdmin = ROLE_TYPES.isAdmin(roles) && ROLE_TYPES.isHr(roles);
  const userCompetency = security?.loggedInUser?.competency;
  const isRoleSpoc = ROLE_TYPES.isCompetencySpoc(roles);
  const dispatch = useDispatch();
  const getBootcampData = useSelector((state) => {
    return state?.bootcamp;
  });
  const getBootcampModuleData = useSelector((state) => {
    return state?.bootcampModule;
  });
  const bootcampDataResults = getBootcampData?.bootcampData?.data?.results;
  const [offset, setOffset] = useState(0);

  const bootcampCount = getBootcampData?.bootcampData?.data?.totalResults;
  const moduleLoading = getBootcampData?.moduleLoading;
  const editBootcampLoading = getBootcampData?.editBootcampLoading;
  const addBootcampLoading = getBootcampData?.addBootcamploading;
  const addBootcampModuleLoading =
    getBootcampModuleData?.addBootcampModuleLoading;
  const editBootcampModuleLoading =
    getBootcampModuleData?.editBootcampModuleLoading;
  const loading = getBootcampData?.loading;
  const [isLoading, setIsLoading] = useState(false);
  const [bootcampDetails, setBootcampDetails] = useState(
    initialBootcampDetails
  );

  const [dataLoading, setDataLoading] = useState(false);
  const [expandIndex, setExpandIndex] = useState(null);
  const [expandBootcampId, setExpandBootcampId] = useState();
  const [createBootcampOpen, setCreateBootcampOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteForObject, setDeleteForObject] = useState(null);
  const [deleteFor, setDeleteFor] = useState("");
  const [isEdit, setIsEdit] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [filterActivated, setFilterActivated] = useState(false);
  const [bootcampForModuleModal, setBootcampForModuleModal] = useState(null);
  const [bootcampApiError, setBootcampApiError] = useState(false);
  const [bootcampModuleApiError, setBootcampModuleApiError] = useState(false);
  const [moduleDeleteModalOpen, setModuleDeleteModalOpen] = useState(false);
  const [newModules, setNewModules] = useState([]);
  const [oldModules, setOldModules] = useState([]);
  const [toBeDeletedModules, setToBeDeletedModules] = useState([]);
  const [bootcampNameLengthError, setBootcampNameLengthError] = useState(false);
  const [editName, setEditName] = useState(false);
  const [oldName, setOldName] = useState("");
  /////////// disabled Bootcamps Id on edit module ///////////
  const [disabledBootcampsId, setDisabledBootcampsId] = useState([]);
  const [disabledModulesId, setDisabledModulesId] = useState([]);
  const [isModuleActive, setIsModuleActive] = useState(false);
  const [moduleInBootcamps, setModuleInBootcamps] = useState([]);
  const [modulesForCreateEditBootcamp, setModulesForCreateEditBootcamp] =
    useState([]);
  const [bootcampsForCreateEditModule, setBootcampsForCreateEditModule] =
    useState([]);
  /////////// handle bootcamp on add and delete module///////////////
  const updatedBootcamp = getBootcampData?.getBootcampDataById;
  /////////// check bootcamp spoc ///////////////
  const [bootcampSpoc, setBootcampSpoc] = useState(false);
  const [prefilledModules, setPrefilledModules] = useState([]);

  const isUserOnly =
    !ROLE_TYPES.isHr(roles) &&
    !ROLE_TYPES.isCompetencySpoc(roles) &&
    !ROLE_TYPES.isCompetencyLead(roles) &&
    !ROLE_TYPES.isAdmin(roles);
  ROLE_TYPES.isUser(roles);

  const bootcampRefs = useRef([]);

  const handleExpandSubModule = async (bootcampId, index) => {
    if (bootcampId === expandIndex) {
      setExpandIndex(-1);
      setExpandBootcampId(-1);
    } else {
      await dispatch(clearModules());
      await setExpandIndex(+bootcampId);
      await setExpandBootcampId(+bootcampId);
      dispatch(
        getModules({
          bootcampId: bootcampId,
        })
      );
      const expandedAccordionItem = bootcampRefs.current[index];
      if (expandedAccordionItem) {
        setTimeout(() => {
          expandedAccordionItem?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }, 500);
      }
    }
  };

  /// Create Bootcamp ////////////////////////////////////////////
  const getBootcampName = (startDate, competency) => {
    const month = dayjs(startDate)?.format("MMM");
    const year = dayjs(startDate)?.format("YYYY");
    return `${competency?.name} Bootcamp | ${month} - ${year}`;
  };

  const handleCreateBootcampOpen = async () => {
    setIsEdit(false);
    setCreateBootcampOpen(true);
    setBootcampDetails(initialBootcampDetails);
    const name = getBootcampName(bootcampDetails?.startDate, userCompetency);
    setOldName(name);
    let bootcampSpocs;
    if (
      bootcampDetails?.startDate &&
      dayjs(bootcampDetails?.startDate).isValid()
    ) {
      bootcampSpocs = await dispatch(
        getCreateEditBootcampOptions({
          startDate: encode(dayjs(bootcampDetails?.startDate).tz(tz).format()),
        })
      );
    }
    const createBootcampSpocs = await bootcampSpocs?.payload?.data?.spocs
      ?.results;
    const filteredSpocs = createBootcampSpocs?.filter((item) =>
      item?.roles?.results?.some((role) => role?.role === "COMPETENCY_SPOC")
    );
    const sortFiltered = sortByFullName(filteredSpocs);
    setBootcampDetails({
      ...bootcampDetails,
      spocIds: !isHr ? sortFiltered : [],
      competencyId: userCompetency,
      name: name,
    });
  };
  const handleCreateBootcampClose = () => {
    setBootcampDetails(initialBootcampDetails);
    setOldName("");
    setIsEdit(false);
    setEditName(false);
    setBootcampSpeChar(false);
    setModulesForCreateEditBootcamp([]);
    setBootcampNameLengthError(false);
    setCreateBootcampOpen(false);
    setDisabledModulesId([]);
    setBootcampApiError(false);
    setOldModules([]);
  };

  const [bootcampSpeChar, setBootcampSpeChar] = useState(false);
  const handleBootcampDetailsChange = (name, value) => {
    setBootcampApiError(false);
    if (name === "moduleIds") {
      const newModuleIds = value?.map((it) => it?.id);
      const getModulesWithSingleBootcamp = () =>
        bootcampDetails?.moduleIds?.filter(
          (it) =>
            !newModuleIds?.includes(it?.id) &&
            it?.bootcamps?.totalResults == 1 &&
            oldModules?.map((it) => it?.id)?.includes(it?.id)
        );

      let modules = bootcampDetails?.moduleIds;
      const modulesWithSingleBootcamp = getModulesWithSingleBootcamp();
      const filteredScheduledModules = modulesWithSingleBootcamp
        ?.filter((it) => it?.status !== ACTIVE && it?.status !== COMPLETED)
        ?.map((it) => it);
      if (value?.length < bootcampDetails?.moduleIds?.length) {
        if (filteredScheduledModules?.length > 0) {
          setToBeDeletedModules(filteredScheduledModules);
          setNewModules(value);
          setModuleDeleteModalOpen(true);
        } else {
          modules = value;
        }
      } else {
        modules = value;
      }
      if (isEdit && !value?.length) {
        const toRetain = bootcampDetails?.moduleIds?.filter((item) =>
          disabledModulesId?.includes(item?.id)
        );
        if (!filteredScheduledModules?.length) {
          modules = [...toRetain];
        }
      }
      setBootcampDetails((prev) => {
        return {
          ...prev,
          [name]: modules,
        };
      });
    } else if (name === "startDate") {
      if (dayjs(value).isValid()) {
        setModulesForCreateEditBootcamp(
          getBootcampData?.getCreateEditBootcampOptions?.data?.modules?.results?.filter(
            (it) =>
              dayjs(it?.startDate).isAfter(dayjs(value), "date") ||
              dayjs(it?.startDate).isSame(dayjs(value), "date")
          )
        );

        if (!bootcampDetails.nameEditedByUser) {
          const newName = getBootcampName(value, bootcampDetails?.competencyId);
          setOldName(newName);
          setBootcampDetails((prev) => {
            return {
              ...prev,
              name: newName,
            };
          });
        }
        setBootcampDetails((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    } else if (name === "name") {
      if (value?.trim().length <= 60) {
        setBootcampNameLengthError(false);
        setBootcampSpeChar(checkSpecialChar(value));
        setBootcampDetails((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      } else {
        setBootcampNameLengthError(true);
      }
    } else {
      setBootcampDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };
  const handleEditNameToggle = () => {
    setEditName(!editName);
  };
  const handleEditNameCancel = () => {
    if (editName === true) {
      setBootcampDetails((prev) => {
        return {
          ...prev,
          name: oldName,
        };
      });
    }
    setEditName(!editName);
  };

  const handleEditNameAccept = () => {
    if (editName === true) {
      setOldName(bootcampDetails?.name.trim());
      setBootcampDetails((prev) => {
        return {
          ...prev,
          name: bootcampDetails?.name.trim(),
        };
      });
    }
    setEditName(!editName);
  };
  //////////// Edit bootcamp ///////////////////////////
  const handleEditBootcamp = async (event, bootcamp) => {
    if (bootcamp?.status === ACTIVE) {
      setDisabledModulesId(
        bootcamp?.modules?.results
          ?.filter(
            (item) => item.status === ACTIVE || item.status === COMPLETED
          )
          ?.map((item) => item?.id)
      );
    }

    setPrefilledModules(
      bootcamp?.modules?.results?.map((module) => {
        return module?.id;
      })
    );
    setBootcampSpoc(bootcamp?.permission?.spoc);
    event.stopPropagation();
    setIsEdit(true);
    setBootcampApiError(false);
    await dispatch(getModules({ bootcampId: bootcamp?.id }));
    await setBootcampDetails((prevDetails) => {
      const editSpocs = [
        ...(!isHr
          ? [
              ...(prevDetails.spocIds ?? []),
              ...(bootcamp?.spocs?.results ?? []).filter(
                (spoc) =>
                  !prevDetails.spocIds?.some(
                    (existingSpoc) => existingSpoc.id === spoc.id
                  )
              ),
            ]
          : bootcamp?.spocs?.results),
      ]?.filter((it) => it?.deleted === false);

      const newSpocs = sortByFullName(editSpocs);
      return {
        ...prevDetails,
        bootcampId: bootcamp?.id,
        name: bootcamp?.name,
        startDate: dayjs(bootcamp?.startDate),
        competencyId: bootcamp?.competency,
        spocIds: newSpocs,
        moduleIds: bootcamp?.modules?.results,
        nameEditedByUser: bootcamp?.nameEditedByUser,
      };
    });
    setOldModules(bootcamp?.modules?.results);
    if (bootcamp?.startDate && dayjs(bootcamp?.startDate).isValid()) {
      dispatch(
        getCreateEditBootcampOptions({
          startDate: encode(dayjs(bootcamp?.startDate).tz(tz).format()),
          bootcampId: bootcamp?.id,
        })
      );
    }
    setOldName(bootcamp?.name);
    setCreateBootcampOpen(true);
  };

  const handleSubmitBootcamp = async () => {
    let apiValue;
    const { bootcampName, bootcampId } = location?.state?.propsToPass || {};
    const getbootcampdetails = {
      ...filterBootcamp,
      sortBy: sortBy,
      sortOrder: orderBy,
      offset: 0,
      max: tilesCount,
      startDate:
        isEdit && filterBootcamp?.startDate
          ? encodeURIComponent(filterBootcamp?.startDate?.format())
          : null,
      endDate:
        isEdit && filterBootcamp?.endDate
          ? encodeURIComponent(filterBootcamp?.endDate?.format())
          : null,
      bootcampId: bootcampId,
      name:
        isEdit && (filterBootcamp?.name || bootcampName)
          ? encodeURIComponent(filterBootcamp?.name || bootcampName)
          : null,
    };
    setOffset(0);
    const addDeleteData = {
      bootcampId: bootcampDetails?.bootcampId,
      name: oldName?.trim(),
      spocIds: bootcampDetails?.spocIds?.map((spoc) => spoc?.id) || [],
      competencyId: !isHr
        ? userCompetency?.id
        : bootcampDetails?.competencyId?.id,
      moduleIds: bootcampDetails?.moduleIds?.map((module) => module?.id) || [],
      startDate:
        bootcampDetails?.startDate != null &&
        bootcampDetails?.startDate.$d != "Invalid Date"
          ? dayjs(bootcampDetails?.startDate)
              .tz(tz)
              .set("hour", 9)
              .set("minute", 0)
              .set("second", 0)
              ?.format()
          : null,
      sortOrder: !isEdit ? "DESC" : orderBy,
      nameEditedByUser: bootcampDetails?.nameEditedByUser,
      offset: 0,
    };
    const data = {
      addDeleteData,
      getbootcampdetails,
    };

    if (!bootcampSpeChar) {
      setNewData(true);
      if (!isEdit) {
        apiValue = await dispatch(addBootcamp(data));
        setFilterCount(0);
        await setFilterActivated(false);
        await setFilterBootcamp(initialFilterBootcamp);
      } else {
        apiValue = await dispatch(editBootcamp(data));
      }
      if (apiValue?.error?.message === "Rejected") {
        setBootcampApiError(true);
      } else {
        handleCreateBootcampClose();
        setInitialTurn(false);
        setExpandIndex(-1);
        const { moduleIds } = data?.addDeleteData;
        const prefilledLength = prefilledModules?.length;
        const moduleIdsLength = moduleIds?.length;
        const hasSameLength = prefilledLength === moduleIdsLength;

        const noChangeInModules =
          hasSameLength &&
          prefilledModules?.every((item) => moduleIds?.includes(item));

        if (!noChangeInModules) {
          await dispatch(getModules({ bootcampId: addDeleteData?.bootcampId }));
        }
      }
    }
  };

  ///////////////////////////////////////////////////////
  // create bootcamp module /////////////////////////////
  const [bootcampModuleDetails, setBootcampModuleDetails] = useState(
    initialBootcampModule
  );
  const [createBootcampModuleOpen, setCreateBootcampModuleOpen] =
    useState(false);

  const handleCreateBootcampModuleOpen = async (bootcamp) => {
    setModuleSpeChar(false);
    const moduleStartDate = toWorkingDay(
      getMaxDate([
        ...bootcamp?.modules?.results?.map((it) =>
          dayjs(it?.endDate).add(1, "day")
        ),
        dayjs().add(1, "day"),
        bootcamp?.startDate,
      ])
    );
    if (moduleStartDate?.isValid()) {
      dispatch(
        getCreateEditModuleOptions({
          startDate: encode(moduleStartDate.format()),
        })
      );
    }
    setBootcampForModuleModal(bootcamp);
    await setBootcampModuleDetails((prev) => {
      return {
        ...prev,
        id: expandBootcampId,
        bootcampIds: [bootcamp],
        startDate: moduleStartDate,
      };
    });
    setCreateBootcampModuleOpen(true);
  };
  const handleCreateBootcampModuleClose = () => {
    setBootcampModuleDetails(initialBootcampModule);
    setCreateBootcampModuleOpen(false);
    setDisabledBootcampsId([]);
    setModuleNameLengthError(false);
    setBootcampForModuleModal(null);
    setIsEdit(false);
    setBootcampsForCreateEditModule([]);
    setBootcampModuleApiError(false);
  };

  const [moduleSpeChar, setModuleSpeChar] = useState(false);
  const [moduleNameLengthError, setModuleNameLengthError] = useState(false);

  const handleBootcampModuleDetailsChange = (name, value) => {
    setBootcampModuleApiError(false);
    if (name === "startDate" && dayjs(value).isValid()) {
      setBootcampsForCreateEditModule(
        getBootcampData?.getCreateEditModuleOptions?.data?.bootcamps?.results.filter(
          (it) => !dayjs(it?.startDate).tz(tz).isAfter(dayjs(value).tz(tz))
        )
      );
      let endDate = dayjs(bootcampModuleDetails?.endDate);
      if (endDate.isValid() && !endDate.isAfter(dayjs(value))) {
        endDate = toWorkingDay(dayjs(value).add(1, "day"));
      }
      setBootcampModuleDetails((prev) => {
        return {
          ...prev,
          bootcampIds: bootcampModuleDetails?.bootcampIds
            ? bootcampModuleDetails?.bootcampIds.filter(
                (it) =>
                  !dayjs(it?.startDate).tz(tz).isAfter(dayjs(value).tz(tz))
              )
            : null,
          endDate: endDate.isValid() ? endDate : null,
          [name]: value,
        };
      });
    } else if (name === "bootcampIds") {
      let bootcampIds = value;
      if (
        !isEdit &&
        !bootcampIds.map((it) => it?.id).includes(bootcampForModuleModal?.id)
      ) {
        bootcampIds = [...bootcampIds, bootcampForModuleModal];
      } else if (isEdit && !bootcampIds?.length) {
        const toRetain = bootcampModuleDetails?.bootcampIds?.filter(
          (item) =>
            item?.status === "ACTIVE" && disabledBootcampsId?.includes(item?.id)
        );
        bootcampIds = [...toRetain];
      }
      setBootcampModuleDetails((prev) => {
        return {
          ...prev,
          [name]: bootcampIds,
        };
      });
    } else if (name === "name") {
      if (value?.trim()?.length <= 50) {
        setModuleSpeChar(checkSpecialChar(value));
        setModuleNameLengthError(false);
        setBootcampModuleDetails((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      } else {
        setModuleNameLengthError(true);
      }
    } else {
      setBootcampModuleDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  // Edit bootcamp Module //////////////////////////////
  const handleEditBootcampModule = async (bootcamp, module) => {
    setModuleInBootcamps(module?.bootcamps?.results);
    setIsModuleActive(module?.status === ACTIVE);
    if (module?.status === ACTIVE) {
      setDisabledBootcampsId(
        module?.bootcamps?.results
          ?.filter((item) => item.status === "ACTIVE")
          ?.map((item) => item?.id)
      );
    }
    const moduleDate = dayjs(module?.startDate)?.tz(tz);
    setIsEdit(true);
    setBootcampForModuleModal(bootcamp);
    await setBootcampModuleDetails({
      moduleId: module.id,
      name: module?.name,
      startDate: dayjs(module?.startDate),
      endDate: dayjs(module?.endDate),
      description: module?.description,
      mode: module?.mode,
      bootcampIds: module?.bootcamps?.results,
    });
    if (moduleDate?.isValid()) {
      dispatch(
        getCreateEditModuleOptions({
          startDate: encode(moduleDate.format()),
        })
      );
    }
    setCreateBootcampModuleOpen(true);
  };

  const handleBootcampModuleSubmit = async () => {
    let apiValue;
    const data = {
      ...bootcampModuleDetails,
      name: bootcampModuleDetails?.name?.trim(),
      bootcampIds: bootcampModuleDetails.bootcampIds.map((e) => e?.id),
      startDate:
        bootcampModuleDetails?.startDate != null &&
        bootcampModuleDetails?.startDate.$d != "Invalid Date"
          ? dayjs(bootcampModuleDetails?.startDate)
              .tz(tz)
              ?.set("hour", 9)
              .set("minute", 0)
              .set("second", 0)
              ?.format()
          : null,
      endDate: dayjs(bootcampModuleDetails?.endDate)
        .set("hour", 23)
        .set("minute", 59)
        .set("second", 59)
        ?.format(),
    };
    if (!moduleSpeChar) {
      if (!isEdit) {
        apiValue = await dispatch(addBootcampModule(data));
      } else {
        apiValue = await dispatch(editBootcampModule(data));
      }

      if (apiValue?.error?.message === "Rejected") {
        setBootcampModuleApiError(true);
      } else {
        handleCreateBootcampModuleClose();
        await dispatch(
          getModules({
            bootcampId: expandBootcampId,
          })
        );
        setBootcampModuleApiError(false);
        if (!isEdit) {
          await dispatch(
            getBootcampById({
              bootcampId: expandBootcampId,
            })
          );
        }
      }
    }
    ///// To update bootcapms containing shared module
    const previousModuleBootcampIds = moduleInBootcamps.map((e) => e?.id);
    const currentModuleBootcampIds = bootcampModuleDetails.bootcampIds.map(
      (e) => e?.id
    );
    const userCompetencyBootcampIds = items?.map((bootcamp) => bootcamp?.id);
    const changedBootcampIds = previousModuleBootcampIds
      .filter((element) => !currentModuleBootcampIds.includes(element))
      .concat(
        currentModuleBootcampIds.filter(
          (element) => !previousModuleBootcampIds.includes(element)
        )
      );
    const updateBootcamps = changedBootcampIds.filter((element) =>
      userCompetencyBootcampIds.includes(element)
    );
    if (updateBootcamps?.length) {
      updateBootcamps?.forEach((bootcampId) => {
        dispatch(
          getBootcampById({
            bootcampId: bootcampId,
          })
        );
      });
    }
  };
  /////////////////////////////////////////////////////
  // module delete modal //
  const handleModuleDeleteModalSubmit = () => {
    setModuleDeleteModalOpen(false);
    setBootcampDetails((prev) => {
      const toRetain = bootcampDetails?.moduleIds?.filter((item) =>
        disabledModulesId?.includes(item?.id)
      );
      const changeInIds = getChangedIds(newModules, bootcampDetails?.moduleIds);
      return {
        ...prev,
        ["moduleIds"]:
          toRetain?.length && changeInIds?.length > 1
            ? [...newModules, ...toRetain]
            : newModules,
      };
    });
    setNewModules([]);
    setToBeDeletedModules([]);
  };

  const handleModuledeleteModalClose = () => {
    setModuleDeleteModalOpen(false);
    setNewModules([]);
    setToBeDeletedModules([]);
  };

  /////////////////////////////////////////////////////
  // delete modal //
  const handleDeleteModalOpen = (event, obj, deleteFor) => {
    event.stopPropagation();
    setDeleteForObject(obj);
    setDeleteFor(deleteFor);
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteBootcamp = async () => {
    if (deleteFor === "bootcamp") {
      const getbootcampdetails = {
        ...filterBootcamp,
        competencyId: filterBootcamp?.competencyId?.id,
        sortBy: sortBy,
        sortOrder: orderBy,
        offset: 0,
        max: tilesCount,
      };
      setOffset(0);
      const data = {
        getId: deleteForObject?.id,
        getbootcampdetails,
      };
      setNewData(true);
      await dispatch(deleteBootcamp(data));
      setDeleteModalOpen(false);
    } else if (deleteFor === "module") {
      await dispatch(deleteBootcampModule(deleteForObject?.id));
      await dispatch(
        getModules({
          bootcampId: expandBootcampId,
        })
      );
      dispatch(
        getBootcampById({
          bootcampId: expandBootcampId,
        })
      );
    }
    setDeleteModalOpen(false);
  };

  //////////////////////////////////////////////////////
  ////// sort bootcamp //////////////////////////////
  const [sortBy, setSortBy] = useState("startDate");
  const [orderBy, setOrderBy] = useState("DESC");

  const handleSort = () => {
    setSortBy(sortBy);
    setOrderBy(orderBy === "ASC" ? "DESC" : "ASC");
    setHasMore(true);
    setExpandIndex(-1);
    setExpandBootcampId(-1);
  };

  //////////////////////////////////////////////////////
  ////// filter bootcamp //////////////////////////////
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterBootcamp, setFilterBootcamp] = useState(initialFilterBootcamp);
  const handleFilterOpen = () => {
    setFilterOpen(true);
    if (!getBootcampData?.filtersForBootcamp?.data?.competencies?.results) {
      dispatch(getFiltersForBootcamp());
    }
  };
  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  const handleFilterBootcampSubmit = async () => {
    setExpandIndex(-1);
    setExpandBootcampId(-1);
    setIsLoading(true);
    const filterCount = calculateFilterCount(filterBootcamp);
    const { bootcampName, bootcampId } = location?.state?.propsToPass || {};
    if (bootcampId || bootcampName) {
      clearProps();
    }
    const data = {
      ...filterBootcamp,
      name: encodeURIComponent(filterBootcamp?.name?.trim()),
      competencyId: filterBootcamp?.competencyId?.id,
      sortBy: sortBy,
      sortOrder: orderBy,
      offset: 0,
      max: tilesCount,
      startDate: dayjs(filterBootcamp?.startDate).isValid()
        ? dayjs(filterBootcamp?.startDate)
            ?.set("hour", 9)
            .set("minute", 0)
            .set("second", 0)
            ?.toISOString()
        : null,
      endDate: dayjs(filterBootcamp?.endDate).isValid()
        ? dayjs(filterBootcamp?.endDate)
            ?.set("hour", 23)
            .set("minute", 59)
            .set("second", 59)
            ?.toISOString()
        : null,
    };
    setOffset(0);

    if (filterCount || filterActivated) {
      setNewData(true);
      await dispatch(getBootcamp(data));
      setFilterCount(filterCount);
      setFilterActivated(filterCount);
    }
    setFilterOpen(false);
    setIsLoading(false);
  };
  const handleFilterBootcampRemove = async () => {
    setExpandIndex(-1);
    setExpandBootcampId(-1);
    setIsLoading(true);
    await setFilterBootcamp(initialFilterBootcamp);
    if (filterCount) {
      setNewData(true);
      await dispatch(
        getBootcamp({
          max: tilesCount,
          offset: 0,
          sortBy: sortBy,
          sortOrder: orderBy,
        })
      );
      setFilterCount(0);
      setFilterActivated(false);
    }
    setFilterOpen(false);
    clearProps();
    setIsLoading(false);
  };

  const clearProps = () => {
    const currentState = window.history.state;
    if (currentState) {
      const newState = {
        ...currentState,
        usr: {
          ...currentState.usr,
          propsToPass: {
            ...currentState.usr.propsToPass,
            bootcampId: null,
            bootcampName: null,
          },
        },
      };
      navigate("", { state: newState });
    }
  };
  const handleChangeFilterBootcamp = (name, value) => {
    setFilterBootcamp({ ...filterBootcamp, [name]: value });
  };

  //////////////////////////////////////////////////////
  useEffect(() => {
    if (!(isEdit && bootcampDetails?.nameEditedByUser)) {
      setBootcampDetails((prev) => {
        return {
          ...prev,
          nameEditedByUser: bootcampDetails?.name?.trim() !== oldName?.trim(),
        };
      });
    }
  }, [bootcampDetails?.name]);

  useEffect(() => {
    setInitialTurn(false);

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  ////////////////////////Before Unload//////////////////////////////
  const handleBeforeUnload = () => {
    if (window.location.pathname === "/") {
      const currentState = window.history.state;
      if (currentState) {
        const newState = {
          ...currentState,
          usr: {
            ...currentState.usr,
            propsToPass: null,
          },
        };
        window.history.replaceState(newState, "");
      }
    }
  };

  useEffect(() => {
    setModulesForCreateEditBootcamp(
      getBootcampData?.getCreateEditBootcampOptions?.data?.modules?.results?.filter(
        (it) =>
          dayjs(it?.startDate).isAfter(
            dayjs(bootcampDetails?.startDate),
            "date"
          ) ||
          dayjs(it?.startDate).isSame(dayjs(bootcampDetails?.startDate), "date")
      )
    );
  }, [getBootcampData?.getCreateEditBootcampOptions?.data?.modules?.results]);

  useEffect(() => {
    setBootcampsForCreateEditModule(
      getBootcampData?.getCreateEditModuleOptions?.data?.bootcamps?.results
    );
  }, [getBootcampData?.getCreateEditModuleOptions?.data?.bootcamps?.results]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setNewData(true);
      const { bootcampName, bootcampId } = location?.state?.propsToPass || {};

      if (bootcampName && bootcampId) {
        await dispatch(
          getBootcamp({
            ...filterBootcamp,
            sortBy: sortBy,
            sortOrder: orderBy,
            max: tilesCount,
            offset: 0,
            bootcampId: bootcampId,
            startDate: filterBootcamp?.startDate
              ? encodeURIComponent(filterBootcamp?.startDate?.format())
              : null,
            endDate: filterBootcamp?.endDate
              ? encodeURIComponent(filterBootcamp?.endDate?.format())
              : null,
            name: filterBootcamp?.name
              ? encodeURIComponent(filterBootcamp?.name)
              : bootcampName
              ? encodeURIComponent(bootcampName)
              : null,
          })
        );
        handleExpandSubModule(bootcampId);

        setFilterBootcamp({
          ...filterBootcamp,
          name: bootcampName,
        });
        setFilterCount(1);
        setFilterActivated(true);
      } else {
        await dispatch(
          getBootcamp({
            ...filterBootcamp,
            sortBy: sortBy,
            sortOrder: orderBy,
            max: tilesCount,
            offset: 0,
            startDate: filterBootcamp?.startDate
              ? encodeURIComponent(filterBootcamp?.startDate?.format())
              : null,
            endDate: filterBootcamp?.endDate
              ? encodeURIComponent(filterBootcamp?.endDate?.format())
              : null,
            name: filterBootcamp?.name
              ? encodeURIComponent(filterBootcamp?.name)
              : bootcampName
              ? encodeURIComponent(bootcampName)
              : null,
          })
        );
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, orderBy]);

  const [initialTurn, setInitialTurn] = useState(true);
  useEffect(() => {
    setDataLoading(true);
    if (bootcampDataResults && !initialTurn) {
      const newItems = bootcampDataResults || [];
      if (newData) {
        setItems(newItems);
        setDataLoading(false);
        setNewData(false);
        setOffset(tilesCount);
      } else {
        const filteredNewItems = newItems.filter(
          (newItem) => !items.some((prevItem) => prevItem.id === newItem.id)
        );
        if (filteredNewItems.length > 0) {
          setItems((prevItems) => [...prevItems, ...filteredNewItems]);
          setDataLoading(false);
          setOffset(offset + tilesCount);
        }
      }

      setDataLoading(false);
      setHasMore(true);
      setLoad(false);
      if (newItems.length === 0) {
        setHasMore(false);
      }
    } else if (bootcampDataResults?.length === 0) {
      setDataLoading(false);
    }
  }, [bootcampDataResults]);

  /////////// handle bootcamp on add and delete module///////////////
  const replaceItemFromPayload = (payloadData) => {
    setItems((prevItems) =>
      prevItems.map((item) => (item.id === payloadData.id ? payloadData : item))
    );
  };

  useEffect(() => {
    if (updatedBootcamp) {
      replaceItemFromPayload(updatedBootcamp?.data);
    }
  }, [updatedBootcamp]);

  /////////////////// infinite scrolling /////////////////////////////////////
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [load, setLoad] = useState(false);
  const [newData, setNewData] = useState(true);
  const fetchMoreData = async () => {
    setLoad(true);
    const newOffset = offset;
    const data = {
      ...filterBootcamp,
      name: encodeURIComponent(filterBootcamp?.name?.trim()),
      competencyId: filterBootcamp?.competencyId?.id,
      sortBy: sortBy,
      sortOrder: orderBy,
      offset: newOffset,
      max: tilesCount,
      startDate: dayjs(filterBootcamp?.startDate).isValid()
        ? dayjs(filterBootcamp?.startDate)
            ?.set("hour", 9)
            .set("minute", 0)
            .set("second", 0)
            ?.toISOString()
        : null,
      endDate: dayjs(filterBootcamp?.endDate).isValid()
        ? dayjs(filterBootcamp?.endDate)
            ?.set("hour", 23)
            .set("minute", 59)
            .set("second", 59)
            ?.toISOString()
        : null,
    };
    await dispatch(getBootcamp(data));
  };

  ////////////////////////////////////////////////////////////////////////////////
  if ((loading && isLoading) || dataLoading) return <Loader />;
  return (
    <>
      <Grid container className="main-container">
        <Grid item className="header-container" md={12}>
          <Typography variant="font_21_bold">Bootcamp Modules</Typography>
          <Box className="header-right-icons">
            {isRoleSpoc && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCreateBootcampOpen}
                className="margin-right-1"
              >
                <Typography variant="outlineBtnLabel">
                  Create Bootcamp
                </Typography>
              </Button>
            )}
            <IconButtons
              width={font_17}
              height={font_17}
              image={orderBy === "ASC" ? desc_sort_icon : asc_sort_icon}
              tooltip={
                orderBy === "ASC" ? "Sort by Descending" : "Sort by Ascending"
              }
              handleClick={handleSort}
            />
            <Box
              className="filterButton margin-left-1"
              sx={{
                border: filterActivated
                  ? "2px solid #de1186"
                  : "1px solid #de118680",
              }}
            >
              <IconButtons
                width={font_13_icon}
                height={font_13_icon}
                image={filter_active_icon}
                handleClick={handleFilterOpen}
                tooltip="Add Filters"
                count={filterCount}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} className="body-container">
          <Grid container>
            <Paper
              elevation={0}
              className="home-paper-container"
              id="homeContainer"
            >
              {!loading && !items?.length ? (
                <Grid container className="no-data-container">
                  <NoDataAvailable
                    imgHeight={4}
                    imgWidth={4.5}
                    message="No Data Available"
                  />
                </Grid>
              ) : (
                <Grid className="home-main-container">
                  {items && (
                    <InfiniteScroll
                      dataLength={items?.length}
                      next={fetchMoreData}
                      hasMore={
                        items?.length === bootcampCount ? false : hasMore
                      }
                      loader={load && <Loader />}
                      scrollableTarget="homeContainer"
                    >
                      <HomeTable
                        bootcampRefs={bootcampRefs}
                        bootcampData={items}
                        subModules={getBootcampData?.modules}
                        expandIndex={expandIndex}
                        handleExpandSubModule={handleExpandSubModule}
                        handleDeleteModalOpen={handleDeleteModalOpen}
                        handleCreateBootcampModalOpen={
                          handleCreateBootcampModuleOpen
                        }
                        handleEditBootcamp={handleEditBootcamp}
                        handleEditBootcampModule={handleEditBootcampModule}
                        moduleLoading={moduleLoading}
                        isLoading={isLoading}
                        isHr={isHr}
                        isHrOnly={isHrOnly}
                        isHrAndAdmin={isHrAndAdmin}
                        isRoleSpoc={isRoleSpoc}
                        expandBootcampId={expandBootcampId}
                      />
                    </InfiniteScroll>
                  )}
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <CreateBootcamp
        isEdit={isEdit}
        userCompetency={userCompetency}
        open={createBootcampOpen}
        onClose={handleCreateBootcampClose}
        getBootcampData={getBootcampData}
        bootcampDetails={bootcampDetails}
        handleBootcampDetailsChange={handleBootcampDetailsChange}
        handleSubmitBootcamp={handleSubmitBootcamp}
        loader={editBootcampLoading || addBootcampLoading}
        bootcampApiError={bootcampApiError}
        bootcampSpeChar={bootcampSpeChar}
        editName={editName}
        handleEditNameCancel={handleEditNameCancel}
        handleEditNameToggle={handleEditNameToggle}
        bootcampNameLengthError={bootcampNameLengthError}
        moduleList={modulesForCreateEditBootcamp}
        handleEditNameAccept={handleEditNameAccept}
        disabledModulesId={disabledModulesId}
      />

      <CreateBootcampModule
        isEdit={isEdit}
        open={createBootcampModuleOpen}
        onClose={handleCreateBootcampModuleClose}
        handleBootcampModuleDetailsChange={handleBootcampModuleDetailsChange}
        getBootcampData={bootcampsForCreateEditModule}
        handleBootcampModuleSubmit={handleBootcampModuleSubmit}
        bootcampModuleDetails={bootcampModuleDetails}
        bootcampModuleApiError={bootcampModuleApiError}
        moduleSpeChar={moduleSpeChar}
        loader={addBootcampModuleLoading || editBootcampModuleLoading}
        bootcamp={bootcampForModuleModal}
        bootcampModuleList={getBootcampData?.modules?.data?.results}
        moduleNameLengthError={moduleNameLengthError}
        disabledBootcampsId={disabledBootcampsId}
        isModuleActive={isModuleActive}
      />

      <DeleteModal
        open={deleteModalOpen}
        content={
          deleteFor === "module" && deleteForObject?.bootcamps?.totalResults > 1
            ? {
                text: "On deletion of this module, it will also be removed from the following Bootcamps:",
                items: deleteForObject?.bootcamps?.results
                  ?.filter((item) => item?.id !== expandBootcampId) // Exclude the item with matching id
                  .map((item) => item?.name),
              }
            : null
        }
        handleClose={handleDeleteModalClose}
        handleDelete={handleDeleteBootcamp}
      />

      <DeleteModal
        open={moduleDeleteModalOpen}
        title={
          toBeDeletedModules?.length == 1
            ? toBeDeletedModules?.[0]?.name?.toString() +
              " is not a shared module and it will be deleted from the system"
            : toBeDeletedModules
                ?.map((it) => it?.name)
                .toString()
                .split(",")
                .join(", ") +
              " are not shared modules and will be deleted from the system"
        }
        buttonText="OK"
        handleClose={handleModuledeleteModalClose}
        handleDelete={handleModuleDeleteModalSubmit}
      />

      <FilterBootcamp
        open={filterOpen}
        onClose={handleFilterClose}
        filterBootcamp={filterBootcamp}
        getBootcampData={getBootcampData}
        handleChangeFilterBootcamp={handleChangeFilterBootcamp}
        handleFilterBootcampSubmit={handleFilterBootcampSubmit}
        handleFilterBootcampRemove={handleFilterBootcampRemove}
      />
    </>
  );
};

export default Home;
