import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoDataAvailable from "../../../components/NoDataAvailable/NoDataAvailable";

const DetailsTabExercise = ({ exercises }) => {
  return (
    <Grid item md={12}>
      <Accordion
        className="accordion"
        sx={{
          "&.MuiAccordion-root.Mui-expanded:last-of-type": {
            marginBottom: "1rem",
          },
        }}
      >
        <AccordionSummary
          className="accordion-summary"
          sx={{ height: "3.938rem !important" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="font_15_bold_600">Questions</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          {exercises?.totalResults ? (
            exercises?.results?.map((exercise, index) => {
              return (
                <Box key={index} className="exercise-details">
                  <Typography variant="font_14" color="lightGrayText.main">
                    {`Q${index + 1})`}
                  </Typography>
                  <Typography variant="font_14" sx={{ paddingLeft: "1rem" }}>
                    {exercise?.question}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <Grid container className="no-data-container">
              <NoDataAvailable
                imgHeight={4}
                imgWidth={4.5}
                message="No Questions Available"
              />
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default DetailsTabExercise;
