import React, { useEffect, useRef, useState } from "react";
import "./topic.scss";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";

import TableWithPagination from "../../components/Table/TableWithPagination";
import TopicInputModal from "./TopicInputModal";
import SimpleModal from "../../components/Table/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import {
  addResource,
  addTopic,
  clearResources,
  clearTopicData,
  deleteResource,
  deleteTopic,
  editTopic,
  getResourceByTopicId,
  getTopic,
} from "../../store/topic";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import AddResourceModal from "../../components/AddResourceModal/AddResourceModal";
import ResourceTableModal from "../../components/ResourceTableModal/ResourceTableModal";
import { useNavigate } from "react-router-dom";
import Constants from "../../constants/constants";
import Loader from "../../components/Loader/Loader";
import { isValidUrlFormat } from "../../utils/utils";

const initialTopicData = {
  id: "",
  name: "",
  description: "",
  agenda: "",
  offset: 0,
  filterBy: "",
};

const initialResourceDetails = {
  json: {
    id: "",
    name: "",
    format: "URL",
    type: Constants.TOPIC,
    url: null,
  },
  files: null,
  offset: 0,
  filterBy: "",
};

const Topic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const topic = useSelector((state) => {
    return state.topic;
  });
  const isResourceLoading = topic?.resourcesLoading;
  const getTopicData = topic?.topicData;
  const loading = topic?.loading;
  const [isEmptyMsg, setIsEmptyMsg] = useState("");
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const handlePageChange = (value) => {
    setCurrentPage(value);
    setOffset(value - 1);
  };
  const totalResult = getTopicData?.data?.totalResults;

  const [topicData, setTopicData] = useState(initialTopicData);

  const [filterBy, setFilterBy] = useState("");
  const inputRef = useRef(null);
  const [timeoutId, setTimeoutId] = useState(null);

  //////////////////////////////////////// Permission handling ////////////////////////////////////////

  const { permission } = useSelector((state) => state?.security);
  const isSpoc = permission?.spoc;

  const accessToCreate = isSpoc;
  const accessToAddResources = isSpoc;
  const accessToActions = isSpoc;
  const accessToResourcesTableAction = isSpoc;

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleFilterChange = (value) => {
    setFilterBy(value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      dispatch(getTopic({ offset, name: value })).then(() => {
        setTimeout(() => {
          inputRef?.current?.focus();
        }, 500);
      });
    }, 500);
    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(getTopic({ offset, name: filterBy }));
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, offset]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  useEffect(() => {
    return () => {
      dispatch(clearTopicData());
      dispatch(clearResources());
    };
  }, []);

  const [isAgenda, setIsAgenda] = useState(false);
  const [isEditTopic, setIsEditTopic] = useState(false);
  const [isTableInputModalOpen, setIsTableInputModalOpen] = useState(false);
  const [textModalOpen, setTextModalOpen] = useState(false);
  const [textModalData, setTextModalData] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [getListId, setGetListId] = useState(0);
  const [isDeleteFor, setIsDeleteFor] = useState(null);
  const [openResourceModal, setOpenResourceModal] = useState(false);
  const [openResourceTable, setOpenResourceTable] = useState(false);
  const [resourceDetails, setResourceDetails] = useState(
    initialResourceDetails
  );

  const handleDeleteModalOpen = (id, deleteFor) => {
    setIsDeleteFor(deleteFor);
    setGetListId(id);
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    if (isDeleteFor === "Topic") {
      await dispatch(deleteTopic({ getListId, offset, filterBy }));
    } else if (isDeleteFor === "Resource") {
      await dispatch(deleteResource({ getListId, offset, filterBy }));
      setOpenResourceTable(false);
    }
    setDeleteModalOpen(false);
  };

  const handleExercise = (id) => {
    navigate(`/topic/${id}/questions`);
  };

  const handleSessions = (id) => {
    navigate(`/topic/${id}/sessions`);
  };

  const tableInputModalOpen = (event, data) => {
    setApiError(false);
    setErrors(initialErrorState);
    setIsEditTopic(false);
    setIsTableInputModalOpen(true);
  };
  const tableInputModalClose = () => {
    setApiError(false);
    setErrors(initialErrorState);
    setIsTableInputModalOpen(false);
    setTopicData(initialTopicData);
  };

  const handleTextModalOpen = (event, data, message, isAgenda) => {
    setIsEmptyMsg(message);
    setTextModalOpen(true);
    setTextModalData(`${data}`);
    setIsAgenda(isAgenda);
  };
  const handleTextModalClose = () => {
    setTextModalOpen(false);
  };

  /////////////////////////////////////////////////////////////
  ////////////////// Add Resource Modal ///////////////////////
  const [resourceApiError, setResourceApiError] = useState(false);
  const handleResourceModalOpen = (id) => {
    setResourceApiError(false);
    setGetListId(id);
    setOpenResourceModal(true);
  };
  const handleResourceModalClose = () => {
    setResourceApiError(false);
    setOpenResourceModal(false);
    setResourceDetails(initialResourceDetails);
  };
  const handleResourceDataChange = (name, value) => {
    setResourceDetails((previousDetails) => {
      if (name.startsWith("json.")) {
        const nestedProperty = name.split(".")[1];
        return {
          ...previousDetails,
          json: {
            ...previousDetails.json,
            [nestedProperty]: value,
          },
          offset,
          filterBy,
        };
      } else {
        return {
          ...previousDetails,
          [name]: value,
        };
      }
    });
  };
  const handleResourceSubmit = async () => {
    let apiValue;
    const isValidUrl = () => {
      return (
        !resourceDetails?.json?.url ||
        isValidUrlFormat(resourceDetails?.json?.url)
      );
    };

    if (isValidUrl()) {
      resourceDetails.json.id = getListId;
      apiValue = await dispatch(addResource(resourceDetails));
    }

    if (apiValue?.error?.message === "Rejected") {
      setResourceApiError(true);
    } else {
      setOpenResourceModal(false);
      setResourceDetails(initialResourceDetails);
    }
  };
  //////////////////////////////////////////////////////////////////

  const checkLimitedSpecialChar = (value) =>
    /[!@#$%^*+=[\]{};'"\\<>?~]/.test(value);
  const handleResourceTableOpen = (e, topicId, count) => {
    dispatch(getResourceByTopicId(topicId));
    setOpenResourceTable(true);
  };
  const handleResourceTableClose = () => {
    setOpenResourceTable(false);
  };

  const handleTopicDataChange = (name, value) => {
    setApiError(false);
    setErrors((prev) => {
      return {
        ...prev,
        [name]: false,
      };
    });
    setTopicData((previousDetails) => ({
      ...previousDetails,
      [name]: value,
    }));
  };

  const handleSubmitTopic = async () => {
    let apiValue;
    if (
      validateFields(topicData) &&
      !checkLimitedSpecialChar(topicData?.name)
    ) {
      const { id } = topicData;
      if (id) {
        apiValue = await dispatch(
          editTopic({
            ...topicData,
            name: topicData?.name?.trim(),
          })
        );
      } else {
        apiValue = await dispatch(
          addTopic({
            ...topicData,
            name: topicData?.name?.trim(),
          })
        );
      }

      if (apiValue?.error?.message === "Rejected") {
        setApiError(true);
      } else {
        tableInputModalClose();
      }
    }
  };

  const handleEditInputModalOpen = (id, name, description, agenda) => {
    setIsTableInputModalOpen(true);
    setIsEditTopic(true);
    setTopicData({
      id: id,
      name: name,
      description: description,
      agenda: agenda,
      offset,
      filterBy: filterBy,
    });
  };

  //////////////////// validate session /////////////////////////
  const initialErrorState = {
    name: "",
    description: "",
    agenda: "",
  };
  const [errors, setErrors] = useState(initialErrorState);
  const [apiError, setApiError] = useState(false);

  const validateFields = (topicDetails) => {
    const { name, description, agenda } = topicDetails;
    let trimmedName = name.trim();
    const newErrors = {
      name: trimmedName ? "" : "Name is required",
      description: description ? "" : "Description is required",
      agenda: agenda && agenda !== "<p><br></p>" ? "" : "Agenda is required",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  ///////////////////////////////////////////////////////////////////

  if (loading && isLoading) return <Loader />;

  return (
    <>
      {getTopicData?.loading ? (
        <Box sx={{ display: "flex" }}>
          <Loader />
        </Box>
      ) : (
        <Grid container className="main-container">
          <Grid item className="header-container" md={12}>
            <Typography variant="font_21_bold">Topics</Typography>
            <Box className="header-right-icons">
              <TextField
                size="small"
                placeholder="Search"
                color="secondary"
                value={filterBy}
                onChange={(e) => handleFilterChange(e?.target?.value)}
                className="margin-right-1 topic-search-input"
                type="search"
                inputRef={inputRef}
              />
              {accessToCreate && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={tableInputModalOpen}
                  sx={{ height: "1.875rem" }}
                >
                  <Typography color="secondary" variant="outlineBtnLabel">
                    Create Topic
                  </Typography>
                </Button>
              )}
            </Box>
          </Grid>

          <Grid item md={12} className="body-container">
            <Grid container className="table-parent-container">
              <Paper
                elevation={0}
                className="flex-container-column topic-paper-container"
              >
                {!getTopicData?.data?.results?.length ? (
                  <Grid container className="no-data-container">
                    <NoDataAvailable
                      imgHeight={4}
                      imgWidth={4.5}
                      message="No Topics Available"
                    />
                  </Grid>
                ) : (
                  <TableWithPagination
                    totalResult={totalResult}
                    handleDeleteModalOpen={handleDeleteModalOpen}
                    topicData={getTopicData}
                    handleTextModalOpen={handleTextModalOpen}
                    handleExercise={handleExercise}
                    handleSessions={handleSessions}
                    handleEditInputModalOpen={handleEditInputModalOpen}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    handleResourceModalOpen={handleResourceModalOpen}
                    handleResourceTableOpen={handleResourceTableOpen}
                    currentPage={currentPage}
                    accessToAddResources={accessToAddResources}
                    accessToActions={accessToActions}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      )}

      <TopicInputModal
        open={isTableInputModalOpen}
        topicDetails={topicData}
        handleClose={tableInputModalClose}
        handleTopicDataChange={handleTopicDataChange}
        handleSubmitTopic={handleSubmitTopic}
        isEditTopic={isEditTopic}
        errors={errors}
        apiError={apiError}
        checkLimitedSpecialChar={checkLimitedSpecialChar}
      />

      <SimpleModal
        message={isEmptyMsg}
        open={textModalOpen}
        handleClose={handleTextModalClose}
        data={textModalData}
        isAgenda={isAgenda}
      />

      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        handleDelete={handleDelete}
      />
      <AddResourceModal
        open={openResourceModal}
        onClose={handleResourceModalClose}
        value={resourceDetails}
        handleChange={handleResourceDataChange}
        handleResourceSubmit={handleResourceSubmit}
        resourceApiError={resourceApiError}
      />

      <ResourceTableModal
        open={openResourceTable}
        handleClose={handleResourceTableClose}
        tableData={topic?.resources?.data}
        handleDeleteModalOpen={handleDeleteModalOpen}
        isResourceLoading={isResourceLoading}
        accessToResourcesTableAction={accessToResourcesTableAction}
      />
    </>
  );
};

export default Topic;
