import React, { useEffect, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import "./App.scss";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
import AppSwitch from "./routes/routes";
import { useDispatch, useSelector } from "react-redux";

import { getLoggedInUser, validateAuthToken } from "./store/security";
import Loader from "./components/Loader/Loader";
import { Toaster } from "react-hot-toast";
import { getConstants } from "./store/utility";
const { LOCAL_STORAGE_KEYS } = require("./constants/constants");

const App = () => {
  const dispatch = useDispatch();
  const { isUserLoggedIn, loading } = useSelector((state) => state?.security);
  const [componentLoading, setComponentLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  // Refresh the page on switching user
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (
        event.key === LOCAL_STORAGE_KEYS.BOOTCAMP_SWITCH_USER ||
        (event.key === LOCAL_STORAGE_KEYS.USER_LOGOUT &&
          localStorage.getItem(LOCAL_STORAGE_KEYS.USER_LOGOUT) === "true")
      ) {
        window.location.href = "/";
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    (async () => {
      setComponentLoading(true);
      await dispatch(validateAuthToken());
      setComponentLoading(false);
    })();
  }, [dispatch]);

  useEffect(
    () =>
      document.documentElement.style.setProperty(
        "--sidebar-width",
        collapsed ? "5.6rem" : "13rem"
      ),
    [collapsed]
  );

  return (
    <>
      {!isUserLoggedIn ? (
        <AppSwitch isUserLoggedIn={isUserLoggedIn} />
      ) : (
        <Box className="home-layout">
          <Toaster />
          <Navbar />
          <Stack direction="row" className="home-container">
            {loading && componentLoading ? (
              <Box className="main-page-loader">
                <Loader />
              </Box>
            ) : (
              <>
                <Grid
                  className="side-navigation"
                  sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                >
                  <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
                </Grid>
                <Grid className="main-page-container">
                  <AppSwitch isUserLoggedIn={isUserLoggedIn} />
                </Grid>
              </>
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default App;
