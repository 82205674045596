import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import IconButtons from "../../components/IconButtons/IconButtons";
import {
  delete_active_icon,
  delete_disabled_icon,
  edit_active_icon,
  edit_disabled_icon,
} from "../../constants/icons";
import { font_13_icon } from "../../utils/utils";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HelpTexts } from "../../constants/constants";

const { BATCH_CONTAINS_ATTENDEE, CONCLUDED_MODULE, CONCLUDED_BOOTCAMP } =
  HelpTexts;

const BMVTable = ({
  moduleBatch,
  handleDeleteModalOpen,
  handleBatchOpen,
  enableActions,
  isAttendeeListAllowed,
  isModuleCompleted,
  isBootcampCompleted,
}) => {
  const { bootcampId } = useParams();
  const navigate = useNavigate();
  return (
    <>
      <TableContainer
        className="table-container"
        sx={{
          marginBottom: "0.6rem",
          overflow: "scroll",
          width: "100%",
          height: "100%",
        }}
      >
        {!moduleBatch || !moduleBatch.length ? (
          <NoDataAvailable
            imgHeight={4}
            imgWidth={4.5}
            message="No Batches Available"
          />
        ) : (
          <Table>
            <TableHead className="table-head-container">
              <TableRow className="table-head-row">
                <TableCell sx={{ minWidth: "12rem" }}>
                  <Typography
                    variant="font_13_bold"
                    sx={{ paddingLeft: "1rem" }}
                  >
                    Name
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: "6rem" }}>
                  <Typography variant="font_13_bold">Mode</Typography>
                </TableCell>
                <TableCell sx={{ minWidth: "24rem" }}>
                  <Typography variant="font_13_bold">
                    Meeting Room/Meeting URL
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: "13rem" }}>
                  <Typography className="padding-left-1" variant="font_13_bold">
                    Attendees for Bootcamp
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: "8rem" }}>
                  <Typography variant="font_13_bold">
                    Total Attendees
                  </Typography>
                </TableCell>
                {enableActions ? (
                  <TableCell sx={{ minWidth: "5rem" }} align="center">
                    <Typography
                      variant="font_13_bold"
                      sx={{ paddingRight: "1rem" }}
                    >
                      Actions
                    </Typography>
                  </TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {moduleBatch?.map((batch, index) => {
                const totalAttendees = !!batch?.countOfAttendees?.total
                  ? batch?.countOfAttendees?.total
                  : 0;
                const attendeeCountForBootcamp = !!batch?.countOfAttendees[
                  Number(bootcampId)
                ]
                  ? batch?.countOfAttendees[Number(bootcampId)]
                  : 0;
                return (
                  <TableRow className="table-body-row" key={index}>
                    <TableCell>
                      <Typography
                        variant="font_12"
                        sx={{ paddingLeft: "1rem" }}
                      >
                        {batch?.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="font_12">{batch?.mode}</Typography>
                    </TableCell>
                    {batch?.mode === "ONLINE" ? (
                      <TableCell>
                        <Link
                          to={`${batch?.meetingUrl}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Typography
                            color="secondary"
                            variant="font_12"
                            textDecoration="underline"
                            sx={{ wordBreak: "break-all !important" }}
                          >
                            {batch?.meetingUrl}
                          </Typography>
                        </Link>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Typography variant="font_12">
                          {batch?.room?.name || ""}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell className="padding-left-1">
                      {isAttendeeListAllowed &&
                      Number(attendeeCountForBootcamp) ? (
                        <BootstrapTooltip title="Show Attendees">
                          <Typography
                            color="secondary"
                            variant="font_12"
                            onClick={() => {
                              navigate(
                                `/bootcamp/${bootcampId}/module/${batch?.module?.id}/attendees`,
                                {
                                  state: { batchtoFilter: batch },
                                }
                              );
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <u>{attendeeCountForBootcamp}</u>
                          </Typography>
                        </BootstrapTooltip>
                      ) : (
                        <Typography variant="font_12">
                          {attendeeCountForBootcamp}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography variant="font_12">
                        {totalAttendees}
                      </Typography>
                    </TableCell>
                    {enableActions ? (
                      <TableCell
                        align="center"
                        sx={{ paddingRight: "1rem !important" }}
                      >
                        <IconButtons
                          width={font_13_icon}
                          height={font_13_icon}
                          image={edit_active_icon}
                          classList="margin-right-1"
                          tooltip="Edit Batch"
                          handleClick={() => handleBatchOpen(batch)}
                          disabled={isModuleCompleted}
                          disabledImage={edit_disabled_icon}
                          disabledTooltip={
                            isBootcampCompleted
                              ? CONCLUDED_BOOTCAMP
                              : isModuleCompleted
                              ? CONCLUDED_MODULE
                              : null
                          }
                        />
                        <IconButtons
                          width={font_13_icon}
                          height={font_13_icon}
                          image={delete_active_icon}
                          tooltip="Delete Batch"
                          handleClick={() =>
                            handleDeleteModalOpen("batch", batch?.id)
                          }
                          disabled={isModuleCompleted || totalAttendees}
                          disabledTooltip={
                            isBootcampCompleted
                              ? CONCLUDED_BOOTCAMP
                              : isModuleCompleted
                              ? CONCLUDED_MODULE
                              : totalAttendees
                              ? BATCH_CONTAINS_ATTENDEE
                              : null
                          }
                          disabledImage={delete_disabled_icon}
                        />
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
};

export default BMVTable;
