import React from "react";
import "./singleDropdownModal.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { cross_default_icon } from "../../constants/icons";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { ROLE_TYPES } from "../../utils/utils";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
import SimpleAutoComplete from "../SimpleAutoComplete/SimpleAutoComplete";
import utility from "./../../store/utility";

const SingleDropdownModal = ({
  open,
  title,
  handleClose,
  menuItems,
  handleDropdownChange,
  dropdownValue,
  id,
  handleSubmit,
  error,
}) => {
  const loader = useSelector((state) => state?.users.addUserRoleLoading);

  return (
    <>
      {loader && <Loader />}
      <Dialog
        open={open}
        onClose={handleClose}
        className="single-input-modal-container"
        PaperProps={{
          style: {
            height: "14.313rem",
            width: "25.125rem",
            zIndex: 1300,
            borderRadius: "1rem",
            boxShadow: "0px 6px 30px 0px rgba(0, 0, 0, 0.05)",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="add-role-title">{title}</DialogTitle>
        <DialogContent className="add-role-dropdown">
          <SimpleAutoComplete
            label="Role"
            placeholder="Select Role"
            options={menuItems}
            onChange={(event, newValue) => handleDropdownChange(newValue)}
            getOptionLabel={(option) => option?.value}
            value={dropdownValue}
            error={error}
            helperText={error ? "Select Role" : null}
            renderOption={(props, option, value) => {
              return (
                <li
                  {...props}
                  key={props?.id}
                  className={
                    option?.value === dropdownValue?.value
                      ? "multi-tag-edit-list-selected"
                      : "multi-tag-style"
                  }
                >
                  <Box lineHeight={1.1}>
                    <Box>{`${option?.value}`}</Box>
                  </Box>
                </li>
              );
            }}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "0rem 1.875rem 1.875rem" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            className="add-role-btn"
          >
            <Typography variant="outlineBtnLabel">Cancel</Typography>
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            className="add-role-btn"
          >
            <Typography variant="outlineBtnLabel">Save</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SingleDropdownModal;
