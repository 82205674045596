export const ROUTE_CONSTANTS = {
  LOGIN: "/login",
  HOME: "/",
  DASHBOARD: "/dashboard",
  LOCATIONS_AND_ROOMS: "locations-and-rooms",
  TOPIC: "topic",
  USERS: "users",
  SESSIONS: "/bootcamp/:bootcampId/module/:moduleId/sessions",
  TOPIC_SESSIONS: "/topic/:topicId/sessions",
  TOPIC_SESSION_VIEW: "/topic/:topicId/sessions/:sessionId",
  QUESTION_VIEW: "topic/:topicId/questions",
  MODULE_SESSIONS_QUESTION_VIEW:
    "/bootcamp/:bootcampId/module/:moduleId/sessions/:sessionId/questions",
  SESSIONS_QUESTION_VIEW: "topic/:topicId/sessions/:sessionId/questions",
  SESSION_VIEW: "/bootcamp/:bootcampId/module/:moduleId/sessions/:sessionId",
  BOOTCAMP_MODULE: "/bootcamp/:bootcampId/module/:moduleId",
  ATTENDEES_BOOTCAMP: "/bootcamp/:bootcampId/attendees",
  ATTENDEES_MODULE: "/bootcamp/:bootcampId/module/:moduleId/attendees",
  EXERCISES: "/exercises/:user",
  ACCESS_DENIED: "/error",
};
