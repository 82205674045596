import dayjs from "dayjs";
import config from "../config/config";
import { attendance } from "./../constants/constants";
import { createFilterOptions } from "@mui/material";
import validator from "validator";

const { parse, format } = require("date-fns");
var minMax = require("dayjs/plugin/minMax");
dayjs.extend(minMax);
const tz = "Asia/Kolkata";

//const DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";
export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

export const font_21 = 1.313;
export const font_20 = 1.25;
export const font_17 = 1.063;
export const font_16 = 1;
export const font_15 = 0.938;
export const font_14 = 0.875;
export const font_13 = 0.813;
export const font_13_icon = 0.836;
export const font_12 = 0.75;
export const font_11 = 0.688;
export const font_10 = 0.625;
export const font_9 = 0.563;

export const getYear = () => {
  return new Date().getFullYear();
};

export function getAbbreviation(inputString) {
  if (typeof inputString !== "string") {
    return "";
  }
  const words = inputString.replace(/[^a-zA-Z ]/g, "").split(" ");
  const abbreviation = words.map((word) => word.charAt(0)).join("");
  return abbreviation.toUpperCase();
}

export const getDate = (date) => {
  return dayjs(date).isValid() ? dayjs(date).format("DD/MM/YYYY") : null;
};

export const getLoginEndpoint = () => {
  if (config.enableJugaadLogin) {
    return `${config.backendBaseUrl}/auth/jugaadLogin?email=vishnu.aggarwal@tothenew.com`;
  } else {
    return `${config.backendBaseUrl}/auth/login`;
  }
};

export const getNewersWorldProfileUrl = (employeeCode) => {
  return `https://newersworld.tothenew.com/#/unifiedEmployeeView/${employeeCode}`;
};

export const getImpersonateUserEndpoint = (email) => {
  return `${config.backendBaseUrl}/auth/impersonateUser?email=${email}`;
};

export const ROLE_TYPES = {
  ADMIN: {
    role: "ADMIN",
    check: (userRoles) =>
      userRoles?.some((roleObj) => roleObj?.role === "ADMIN") || false,
  },
  HR: {
    role: "HR",
    check: (userRoles) =>
      userRoles?.some((roleObj) => roleObj?.role === "HR") || false,
  },
  COMPETENCY_SPOC: {
    role: "COMPETENCY_SPOC",
    check: (userRoles) =>
      userRoles?.some((roleObj) => roleObj?.role === "COMPETENCY_SPOC") ||
      false,
  },
  COMPETENCY_LEAD: {
    role: "COMPETENCY_LEAD",
    check: (userRoles) =>
      userRoles?.some((roleObj) => roleObj?.role === "COMPETENCY_LEAD") ||
      false,
  },
  USER: {
    role: "USER",
    check: (userRoles) =>
      userRoles?.some((roleObj) => roleObj?.role === "USER") || false,
  },
  isAdmin: (userRoles) => ROLE_TYPES?.ADMIN?.check(userRoles) || false,
  isHr: (userRoles) => ROLE_TYPES?.HR?.check(userRoles) || false,
  isCompetencySpoc: (userRoles) =>
    ROLE_TYPES?.COMPETENCY_SPOC?.check(userRoles) || false,
  isCompetencyLead: (userRoles) =>
    ROLE_TYPES?.COMPETENCY_LEAD?.check(userRoles) || false,
  isUser: (userRoles) => ROLE_TYPES?.USER?.check(userRoles) || false,

  getLabel: (role) => {
    if (role === ROLE_TYPES?.USER?.role) return "User";
    else if (role === ROLE_TYPES?.ADMIN?.role) return "Admin";
    else if (role === ROLE_TYPES?.COMPETENCY_SPOC?.role)
      return "Competency SPOC";
    else if (role === ROLE_TYPES?.COMPETENCY_LEAD?.role)
      return "Competency Lead";
    else if (role === ROLE_TYPES?.HR?.role) return "HR";
    else return "";
  },

  isOnlyRole: (userRoles, roleToCheck) => {
    const rolesWithoutUser = userRoles.filter(
      (roleObj) => roleObj.role !== "USER"
    );
    const roleExists = rolesWithoutUser.some(
      (roleObj) => roleObj.role === roleToCheck
    );
    return roleExists && rolesWithoutUser.length === 1;
  },
};

export const hasAnyPermission = (permission) => {
  for (let key in permission) {
    if (permission[key] === true) {
      return true;
    }
  }
  return false;
};

export const permissionAllowedTo = (permission) => {
  const truePermissions = [];
  for (let key in permission) {
    if (permission[key] === true) {
      truePermissions.push(key);
    }
  }
  return truePermissions;
};

export const isOnlyTrueForPermissionKey = (permission, key) => {
  if (!permission || !(key in permission)) {
    return false;
  }

  return (
    Object.values(permission).filter((value) => value).length === 1 &&
    permission[key]
  );
};

const itemsToCheck = [
  "spoc",
  "sessionPresenter",
  "mentor",
  "attendee",
  "exerciseReviewer",
];

export const isItemIncludedExcluding = (excludedItems, finalArrayToCheck) => {
  return itemsToCheck.some(
    (item) => item !== excludedItems && finalArrayToCheck.includes(item)
  );
};

export const parseDate = (date) => parse(date, DATE_TIME_FORMAT, null);

export const formatDate = (date) => format(date, DATE_TIME_FORMAT);

export const encode = (text) => encodeURIComponent(text);

export const todayDate = () => {
  const today = new Date();
  const todayDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1
  );
  todayDate.setHours(0);
  todayDate.setMinutes(0);
  todayDate.setSeconds(0);
  todayDate.setMilliseconds(0);

  dayjs().startOf("day");
  return todayDate;
};

export const regex = /^[0-9]{1,5}$/;

export const calculateFilterCount = (filterObject) => {
  const count = Object.values(filterObject).filter((value) => {
    return (
      value !== "" &&
      value !== undefined &&
      value !== null &&
      !(Array.isArray(value) && value.length === 0) &&
      !(typeof value === "boolean" && value === false)
    );
  }).length;

  return count;
};

export const checkSpecialChar = (value) => {
  const charRegex = /[`!@#$%^&*()+=[\]{};':"\\,.<>\/?~]/;
  if (charRegex.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const isValidArray = (arr) => {
  return (
    arr !== undefined && arr !== null && arr.length > 0 && Array.isArray(arr)
  );
};

export const isValidString = (str) => {
  if (str === undefined || str === null || !(typeof str === "string")) {
    return false;
  }
  str = str.trim();
  return str.length > 0;
};

export function checkDateTimeStatus(startDate, endDate) {
  const today = dayjs();
  if (dayjs(startDate).isBefore(today) && dayjs(endDate).isAfter(today)) {
    return "ongoing";
  } else if (
    dayjs(startDate).isBefore(today) &&
    dayjs(endDate).isBefore(today)
  ) {
    return "past";
  } else if (dayjs(startDate).isAfter(today)) {
    return "future";
  }
}

export const getLatestDate = (date) => {
  const today = dayjs();
  const date2 = dayjs(date);
  return date2.isBefore(today, "date") ? today : date2;
};

export const getMaxDate = (dates) => {
  if (dates?.length === 0) {
    return null;
  }
  return dayjs.max(
    [...dates]?.map((it) => dayjs(it))?.filter((it) => it.isValid())
  );
};

export const getMinDate = (dates) => {
  if (dates?.length === 0) {
    return null;
  }
  return dayjs.min(
    [...dates]?.map((it) => dayjs(it))?.filter((it) => it.isValid())
  );
};

export const getBootcampStatus = (value) => {
  switch (value) {
    case "ACTIVE":
      return { name: "ACTIVE", color: "#3087E9", text: "#FFF" };
    case "SCHEDULED":
      return { name: "SCHEDULED", color: "#FFAF00", text: "#FFF" };
    case "COMPLETED":
      return { name: "COMPLETED", color: "#05B14E", text: "#FFF" };
    default:
      return { name: "Unknown", color: "red", text: "#FFF" };
  }
};

export const filterRolesDropDown = (value, allRoles) => {
  const checkCompetency = (value) => {
    let competency;
    switch (value?.name) {
      case "HR SPOC":
      case "HR Operations":
      case "HR Business Partner":
        return (competency = "HR");
      default:
        return (competency = "User");
    }
  };
  const filterBy = checkCompetency(value);
  const listRoles = allRoles;
  let filteredRoles;
  if (filterBy === "HR") {
    filteredRoles = listRoles?.filter(
      (role) => role.key === "ADMIN" || role.key === "HR" || role.key === "USER"
    );
  } else {
    filteredRoles = listRoles?.filter(
      (role) =>
        role.key !== "HR" &&
        role.key !== "COMPETENCY_LEAD" &&
        role.key !== "USER"
    );
  }
  return filteredRoles;
};

export const shouldDisableWeekends = (date) => {
  const day = date.day();
  // Disable Saturday (6) and Sunday (0)
  return day === 0 || day === 6;
};

export const SingleWordCount = (str) => {
  return str.split(" ").length;
};
export const exerciseStatusChip = (value) => {
  switch (value) {
    case "PENDING":
      return { name: "Pending", theme: "PENDING" };
    case "IN_REVIEW":
      return { name: "In Review", theme: "IN_REVIEW" };
    case "REVIEWED":
      return { name: "Reviewed", theme: "REVIEWED" };
    case "REOPENED":
      return { name: "Reopened", theme: "REOPENED" };
    default:
      return { name: "Unknown", theme: "RED" };
  }
};

export const attendanceStatusChip = (value) => {
  switch (value) {
    case "NOT_MARKED":
      return { name: "Not Marked", theme: "BLUE-TEXT" };
    case "PRESENT":
      return { name: "Present", theme: "GREEN-TEXT" };
    case "ABSENT":
      return { name: "Absent", theme: "RED-TEXT" };
    default:
      return { name: "Unknown", theme: "RED" };
  }
};

export const hasDetailsChanged = (initialDetails, currentDetails) => {
  return JSON.stringify(initialDetails) !== JSON.stringify(currentDetails);
};

export const sortByFullName = (data) => {
  const newData = [...data];
  return newData?.sort((a, b) => {
    const getNameComponents = (fullName) => {
      let [firstName, middleName, lastName] = fullName.split(" ");
      lastName = lastName || "";
      middleName = middleName || "";
      return { firstName, middleName, lastName };
    };

    const nameA = getNameComponents(a.fullName);
    const nameB = getNameComponents(b.fullName);

    if (nameA.firstName < nameB.firstName) return -1;
    if (nameA.firstName > nameB.firstName) return 1;

    if (nameA.middleName < nameB.middleName) return -1;
    if (nameA.middleName > nameB.middleName) return 1;

    if (nameA.lastName < nameB.lastName) return -1;
    if (nameA.lastName > nameB.lastName) return 1;

    return 0;
  });
};

export const toWorkingDay = (date) => {
  let finaldate = dayjs(date).tz(tz);
  if (finaldate.day() === 0) {
    finaldate = finaldate.add(1, "day");
  } else if (finaldate.day() === 6) {
    finaldate = finaldate.add(2, "day");
  }
  return finaldate;
};

export const filterOptionsWithSelectAllDesignations = (
  options,
  params,
  resultsLength,
  optionsWithAllDesignation
) => {
  const filter = createFilterOptions();
  const filtered = filter(options, params);

  if (resultsLength === 0) {
    return [{ name: "No options", all: false }];
  }

  return [
    {
      name: "All",
      id: Date.now(),
      users: {
        results: optionsWithAllDesignation,
      },
    },
    ...filtered,
  ];
};

/////////////////////////////////////////////////////
// To check changedValues in dropdown//
export const getChangedIds = (currentValues, detailsToCheck) => {
  const previousIds = detailsToCheck.map((e) => e?.id);
  const currentIds = currentValues.map((e) => e?.id);
  const changedIds = previousIds
    .filter((element) => !currentIds.includes(element))
    .concat(currentIds.filter((element) => !previousIds.includes(element)));
  return changedIds;
};

// To check if it contains the disabledIds in dropdown//
export const allValuesDisabled = (details, disabledIds) => {
  // Check if lengths are the same
  if (details?.length !== disabledIds?.length) {
    return false;
  }

  // Check if each id in disabledIds is in details
  const itemIdsToBeChecked = details?.map((item) => item?.id);
  const allIdsExist = disabledIds?.every((id) =>
    itemIdsToBeChecked?.includes(id)
  );

  return allIdsExist;
};

/////////////////////////////////////////////////////
// To check URL Format //
const invalidWWWPattern = /^www\.[A-Za-z]+$/;
const gitUrlPattern = /^(git@)[\w.-]+(:|\/)[\w./-]+\.git$/;

export const isValidUrlFormat = (url) => {
  const options = {
    require_tld: true, // Require a valid top-level domain (TLD)
    require_protocol: false, // Do not require a protocol (http, https)
    allow_protocol_relative_urls: false, // Do not allow protocol-relative URLs (e.g., //example.com)
  };
  if (invalidWWWPattern.test(url)) {
    return false;
  }
  // Check if it's a valid HTTP/HTTPS URL
  if (validator?.isURL(url, options)) {
    return true;
  }
  // Check if it's a valid Git SSH URL
  return gitUrlPattern?.test(url);
};
