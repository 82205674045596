import React from "react";
import "./table.scss";
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import IconButtons from "../IconButtons/IconButtons";
import {
  clipboard_active_icon,
  delete_active_icon,
  delete_disabled_icon,
  edit_active_icon,
  plus_dashed_active_icon,
} from "../../constants/icons";
import TablePagination from "../TablePagination/TablePagination";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
import { font_13, font_13_icon, font_21 } from "../../utils/utils";
import Constants from "../../constants/constants";

const TableWithPagination = ({
  topicData,
  handleExercise,
  handleSessions,
  handleTextModalOpen,
  handleEditInputModalOpen,
  handleDeleteModalOpen,
  totalPages,
  handlePageChange,
  handleResourceModalOpen,
  handleResourceTableOpen,
  totalResult,
  currentPage,
  accessToAddResources,
  accessToActions,
}) => {
  return (
    <>
      <TableContainer className="table-container">
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="table-head-container">
            <TableRow className="table-head-row">
              <TableCell
                align="left"
                sx={{ minWidth: "13rem", maxWidth: "13rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Name</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "13rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Description</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ minWidth: "6rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Agenda</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ minWidth: "6rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Resources</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ minWidth: "6rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Questions</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ minWidth: "6rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Sessions</Typography>
              </TableCell>
              {accessToActions && (
                <TableCell
                  align="center"
                  sx={{ minWidth: "6rem" }}
                  className="padding-left-1 padding-right-1"
                >
                  <Typography variant="font_13_bold">Actions</Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {topicData?.data?.results.map(
              (
                {
                  id,
                  name,
                  agenda,
                  description,
                  exerciseQuestions,
                  deleted,
                  resources,
                  sessions,
                },
                index
              ) => {
                return (
                  <TableRow className="table-body-row" hover key={id}>
                    <TableCell align="left" className="padding-left-1">
                      <Typography
                        variant="font_12"
                        sx={{ textOverflow: "ellipsis" }}
                      >
                        {name.length > 50
                          ? name.substring(0, 50) + "..."
                          : name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="padding-left-1"
                      align="left"
                      onClick={(e) =>
                        handleTextModalOpen(
                          e,
                          description,
                          "Description not available",
                          false
                        )
                      }
                    >
                      <Typography
                        variant="font_12"
                        className="topic-description"
                      >
                        {description.length > 50
                          ? description.substring(0, 50) + "..."
                          : description}
                      </Typography>
                    </TableCell>
                    <TableCell className="padding-left-1" align="center">
                      <IconButtons
                        width={font_13}
                        height={font_13}
                        image={clipboard_active_icon}
                        tooltip="Show Agenda"
                        handleClick={(e) =>
                          handleTextModalOpen(
                            e,
                            agenda,
                            "No agenda Available",
                            true
                          )
                        }
                      />
                    </TableCell>
                    <TableCell className="padding-left-1" align="center">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <BootstrapTooltip title="Show Resources">
                          <Button
                            sx={{ minWidth: 0 }}
                            onClick={(e) =>
                              handleResourceTableOpen(
                                e,
                                id,
                                resources?.totalResults
                              )
                            }
                          >
                            <Typography
                              variant="font_14_bold"
                              color="secondary"
                            >
                              <u>{resources?.totalResults}</u>
                            </Typography>
                          </Button>
                        </BootstrapTooltip>
                        {accessToAddResources && (
                          <IconButtons
                            width={font_21}
                            height={font_21}
                            image={plus_dashed_active_icon}
                            tooltip="Add Resource"
                            handleClick={() => handleResourceModalOpen(id)}
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell className="padding-left-1" align="center">
                      <BootstrapTooltip title="Show Questions">
                        <Button
                          sx={{ minWidth: 0 }}
                          size="small"
                          onClick={() => handleExercise(id)}
                        >
                          <Typography variant="font_14_bold" color="secondary">
                            <u>{exerciseQuestions?.totalResults}</u>
                          </Typography>
                        </Button>
                      </BootstrapTooltip>
                    </TableCell>
                    <TableCell align="center" className="padding-left-1">
                      <BootstrapTooltip title="Show Sessions">
                        <Button
                          sx={{ minWidth: 0 }}
                          size="small"
                          onClick={() => handleSessions(id)}
                        >
                          <Typography variant="font_14_bold" color="secondary">
                            <u>{sessions?.totalResults}</u>
                          </Typography>
                        </Button>
                      </BootstrapTooltip>
                    </TableCell>
                    {accessToActions && (
                      <TableCell
                        align="center"
                        className="padding-left-1 padding-right-1"
                      >
                        <Box className="topic-action-icons">
                          <IconButtons
                            width={font_13_icon}
                            height={font_13_icon}
                            image={edit_active_icon}
                            tooltip="Edit Topic"
                            classList="margin-right-1"
                            handleClick={() =>
                              handleEditInputModalOpen(
                                id,
                                name,
                                description,
                                agenda,
                                exerciseQuestions?.results || []
                              )
                            }
                          />
                          {deleted ? null : (
                            <IconButtons
                              width={font_13_icon}
                              height={font_13_icon}
                              image={delete_active_icon}
                              disabledImage={delete_disabled_icon}
                              tooltip="Delete Topic"
                              handleClick={() =>
                                handleDeleteModalOpen(id, "Topic")
                              }
                              disabled={sessions?.totalResults > 0}
                              disabledTooltip="Cannot delete: This topic has sessions associated with it."
                            />
                          )}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container className="table-pagination-container">
        <Typography variant="font_16">Total {totalResult} Records</Typography>
        <TablePagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>
    </>
  );
};

export default TableWithPagination;
