import React, { useEffect, useMemo, useState } from "react";
import "./questions.scss";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import QuestionTable from "./QuestionTable";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteQuestion,
  getQuestionList,
  addQuestion,
  editQuestion,
  getAssociateQuestions,
  associateQuestions,
  getTopicViaId,
  clearQuestionList,
  disassociateQuestion,
} from "../../store/questions";
import DeleteModal from "../DeleteModal/DeleteModal";
import QuestionModal from "../QuestionModal/QuestionModal";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";
import AssociateQuestions from "./AssociateQuestions";
import Constants from "../../constants/constants";
import Loader from "../Loader/Loader";
import {
  getBootcampById,
  getBootcampModuleView,
} from "../../store/bootcampModuleView";
import { getDetails } from "../../store/SessionView/detailsTab";
import { checkDateTimeStatus, encode } from "../../utils/utils";
import { useNavigate } from "react-router-dom/dist";
import dayjs from "dayjs";
import DisassociateQuestionModal from "./DisassociateQuestionModal";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";

const Questions = () => {
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [getListId, setGetListId] = useState(0);
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIds, setSelectedIds] = useState([]);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [associateQuestionsModalOpen, setAssociateQuestionsModalOpen] =
    useState(false);
  const [isEditQuestion, setIsEditQuestion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [questionDetails, setQuestionDetails] = useState({
    exercise: [],
  });

  const dispatch = useDispatch();
  const question = useSelector((state) => state?.questionList);

  const questionList = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * 20;
    const lastPageIndex = firstPageIndex + 20;
    return question?.questionList?.data?.results?.slice(
      firstPageIndex,
      lastPageIndex
    );
  }, [currentPage, question]);

  const loading = question?.loading;

  const associateQuestionsList = question?.associateQuestionsList;

  const totalResult = question?.questionList?.data?.totalResults;
  const handlePageChange = (value) => {
    setOffset(value - 1);
    setCurrentPage(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let bootcampApiValue,
        moduleApiValue,
        detailsApiValue,
        topicApiValue,
        questionApiValue;
      if (
        viaSession &&
        !isNaN(+bootcampId) &&
        !isNaN(+sessionId) &&
        !isNaN(+moduleId)
      ) {
        detailsApiValue = await dispatch(
          getDetails({ bootcampId, moduleId, topicId, sessionId })
        );
        if (
          detailsApiValue?.error?.message === "Rejected" &&
          detailsApiValue.payload.includes("Access Denied")
        ) {
          navigate("/error", {
            replace: true,
            state: { isAccessDenied: true },
          });
          return;
        }
        if (viaModule) {
          if (
            bootcampApiValue?.error?.message === "Rejected" &&
            bootcampApiValue.payload.includes("Access Denied")
          ) {
            navigate("/error", {
              replace: true,
              state: { isAccessDenied: true },
            });
            return;
          }
          moduleApiValue = await dispatch(
            getBootcampModuleView({ bootcampId, moduleId })
          );
          if (
            moduleApiValue?.error?.message === "Rejected" &&
            moduleApiValue.payload.includes("Access Denied")
          ) {
            navigate("/error", {
              replace: true,
              state: { isAccessDenied: true },
            });
            return;
          }
        }
      }
      else if (
        topicId && sessionId
          ? !isNaN(+topicId) && !isNaN(+sessionId)
          : topicId
          ? !isNaN(+topicId)
          : null
      ) {
        if (topicId) {
          if (!viaSession) {
            topicApiValue = await dispatch(getTopicViaId({ id: +topicId }));
          }
          if (
            topicApiValue?.error?.message === "Rejected" &&
            topicApiValue.payload.includes(
              "no non-deleted topic found for the Id"
            )
          ) {
            navigate("/error", { replace: true });
            return;
          }
        }
        if (sessionId) {
          detailsApiValue = await dispatch(
            getDetails({ bootcampId, moduleId, topicId, sessionId })
          );
          if (
            detailsApiValue?.error?.message === "Rejected" &&
            detailsApiValue.payload.includes("Access Denied")
          ) {
            navigate("/error", {
              replace: true,
              state: { isAccessDenied: true },
            });
            return;
          }
        }
      } else {
        navigate("/error", { replace: true });
        return;
      }
      questionApiValue = await dispatch(
        getQuestionList({
          id,
          type,
          offset,
          bootcampId,
          moduleId,
          sessionId,
          topicId,
        })
      );
      if (
        questionApiValue?.error?.message === "Rejected" &&
        questionApiValue.payload.includes("No data found for this id")
      ) {
        navigate("/error", { replace: true });
        return;
      }
      setIsLoading(false);
    };

    if (security?.loggedInUser?.id) {
      fetchData();
    }
  }, [offset]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  const moduleView = useSelector((state) => {
    return state?.bootcampModuleView;
  });
  const Details = useSelector((state) => {
    return state?.detailsTab;
  });
  const security = useSelector((state) => state?.security);
  const isSpoc = security?.loggedInUser?.permission?.spoc;

  const moduleData = moduleView?.moduleData?.data;
  const moduleName = moduleData?.name;
  const moduleBootcamps = moduleData?.bootcamps?.results;
  const sessionName = Details?.details?.data?.name;
  const sessionPermission = Details?.details?.data?.permission;
  const isAccessible =
    sessionPermission?.sessionPresenter || sessionPermission?.spoc;
  const moduleIsConcluded =
    Details?.details?.data?.module?.status === "COMPLETED";

  const sessionIsScheduled =
    checkDateTimeStatus(Details?.details?.data?.startDate) === "future";
  const associatedTopicId = Details?.details?.data?.topic?.id;
  const topicName = question?.topicData?.data?.name;

  const params = useParams();
  const { topicId, sessionId, moduleId, bootcampId } = params;
  const viaSession = !!params?.sessionId || false;
  const viaModule = !!params?.moduleId || false;
  const [bootcampName, setBootcampName] = useState(null);

  const viaTopic = !!params?.topicId && !viaSession;

  const type = viaModule || viaSession ? "SESSION" : "TOPIC";
  const id = viaModule || viaSession ? sessionId : topicId;

  ///////////////////////  handle error ////////////////////////////////
  const initialError = {
    question: "",
  };
  const [questionApiError, setQuestionApiError] = useState(false);
  const [error, setError] = useState(initialError);

  const validateFields = (questionDetails) => {
    const { exercise } = questionDetails;
    const newErrors = {
      question:
        exercise?.length && exercise[0]?.trim() !== ""
          ? ""
          : "Question is required",
    };
    setError(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };
  //////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const matchedBootcamp = moduleBootcamps?.find(
      (item) => item.id === +bootcampId
    );
    if (matchedBootcamp) {
      setBootcampName(matchedBootcamp?.name);
    }
  }, [moduleBootcamps]);

  useEffect(() => {
    return () => {
      dispatch(clearQuestionList());
    };
  }, []);

  const breadcrumbs = viaModule
    ? [
        { label: "Home", url: "/" },
        {
          label: bootcampName ? bootcampName : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: moduleName ? moduleName : "Module",
          url: `/bootcamp/${bootcampId}/module/${moduleId}` || "/",
          isModule: true,
          moduleId: moduleId,
        },
        {
          label: "Sessions",
          url: `/bootcamp/${bootcampId}/module/${moduleId}/sessions` || "/",
          isSession: true,
          sessionId: sessionId,
        },
        { label: "Questions" },
      ]
    : viaSession && !viaModule
    ? [
        {
          label: "Topics",
          url: "/topic",
          isTopic: true,
          topicId: topicId,
        },
        {
          label: "Sessions",
          url: `/topic/${topicId}/sessions`,
          isSession: true,
          sessionId: sessionId,
        },
        { label: "Questions" },
      ]
    : [
        {
          label: "Topics",
          url: "/topic",
          isTopic: true,
          topicId: topicId,
        },
        { label: "Questions" },
      ];

  const handleDeleteModalOpen = (QuestionId) => {
    setGetListId(QuestionId);
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const data = {
      questId: getListId,
      id,
      type,
      offset,
    };
    await dispatch(deleteQuestion(data));
    setDeleteModalOpen(false);
  };

  const handleQuestionModalOpen = () => {
    setQuestionApiError(false);
    setError(initialError);
    setIsEditQuestion(false);
    setQuestionModalOpen(true);
  };

  const handleQuestionModalClose = () => {
    setQuestionModalOpen(false);
    setGetListId(0);
    setQuestionApiError(false);
    setError(initialError);
    setQuestionDetails({
      exercise: [],
    });
  };

  const handleQuestionDetails = (exercise) => {
    if (questionApiError || error) {
      setQuestionApiError(false);
      setError(initialError);
    }
    setQuestionDetails((prevDetails) => ({
      ...prevDetails,
      exercise: [exercise],
    }));
  };

  const handleQuestionSubmit = async () => {
    if (validateFields(questionDetails)) {
      let apiValue = null;
      if (isEditQuestion) {
        apiValue = await dispatch(
          editQuestion({
            id,
            quesId: getListId,
            type,
            offset,
            exercise: questionDetails.exercise[0],
          })
        );
      } else {
        apiValue = await dispatch(
          addQuestion({
            id,
            type,
            offset,
            exercise: questionDetails.exercise,
          })
        );
      }
      if (apiValue?.error?.message === "Rejected") {
        setQuestionApiError(true);
      } else {
        await dispatch(getQuestionList({ id, type, offset }));
        handleQuestionModalClose();
      }
    }
  };

  const handleEditQuestionModalOpen = (questionId, questionText) => {
    setIsEditQuestion(true);
    setGetListId(questionId);
    setQuestionModalOpen(true);
    setQuestionDetails({
      exercise: [questionText],
      questionId,
    });
  };

  const handleAssociateQuestionsModalClose = () => {
    setAssociateQuestionsModalOpen(false);
  };

  const handleAssociateQuestionsDetails = (associatedId) => {
    const isSelected = selectedIds.includes(associatedId);
    if (isSelected) {
      setSelectedIds(
        selectedIds.filter((selectedId) => selectedId !== associatedId)
      );
    } else {
      setSelectedIds([...selectedIds, associatedId]);
    }
  };

  const selectAllChange = (event) => {
    if (event.currentTarget.checked) {
      setSelectedIds(
        associateQuestionsList?.data?.results?.map((question) => question.id)
      );
    } else {
      setSelectedIds([]);
    }
  };

  const handleAssociateQuestionSubmit = async (selectedQuestions) => {
    setIsLoading(true);
    await dispatch(
      associateQuestions({
        associateExerciseIds: selectedQuestions,
        sessionId: id,
        type,
        offset,
      })
    );
    setIsLoading(false);
    handleAssociateQuestionsModalClose();
    setSelectedIds([]);
  };

  const handleAssociateQuestionsModalOpen = async () => {
    setAssociateQuestionsModalOpen(true);

    await dispatch(
      getAssociateQuestions({
        id: associatedTopicId,
        type: Constants.TOPIC,
        offset,
      })
    );
  };

  /////////////////// Disassociate question modal /////////////////////////////
  const [openQuestionRemove, setOpenQuestionRemove] = useState(false);
  const [disassociateId, setDisassociateId] = useState(null);
  const handleOpenDisassociate = (value) => {
    setDisassociateId(value);
    setOpenQuestionRemove(true);
  };
  const handleCloseDisassociate = () => {
    setOpenQuestionRemove(false);
  };

  const handleDisassociateQuesiton = async () => {
    let apiValue = null;
    const data = {
      disassociateExerciseId: disassociateId,
      sessionId: Number(params?.sessionId),
    };
    apiValue = await dispatch(disassociateQuestion(data));
    if (apiValue?.error?.message === "Rejected") {
      setOpenQuestionRemove(false);
    } else {
      await dispatch(
        getQuestionList({
          id,
          type,
          offset,
          bootcampId,
          moduleId,
          sessionId,
          topicId,
        })
      );
      setOpenQuestionRemove(false);
    }
  };

  /////////////////////////////////////////////////////////////////////////////

  if ((loading && isLoading) || isLoading) return <Loader />;

  return (
    <Grid container className="main-container">
      <BreadCrumb breadcrumbs={breadcrumbs} />

      <Grid item className="header-container" md={12}>
        <Typography variant="font_21_bold">
          {viaModule || viaSession ? sessionName : topicName}
        </Typography>
        <Box className="header-right-icons">
          {viaSession && isAccessible && (
            <BootstrapTooltip
              title={moduleIsConcluded ? "Module is completed" : null}
            >
              <Box>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={handleAssociateQuestionsModalOpen}
                  disabled={moduleIsConcluded}
                >
                  <Typography variant="outlineBtnLabel">
                    associate questions
                  </Typography>
                </Button>
              </Box>
            </BootstrapTooltip>
          )}

          {((viaTopic && isSpoc) || (viaSession && isAccessible)) && (
            <BootstrapTooltip
              title={
                !viaTopic && moduleIsConcluded ? "Module is completed" : null
              }
            >
              <Box>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  className="margin-left-2"
                  onClick={handleQuestionModalOpen}
                  disabled={!viaTopic && moduleIsConcluded}
                >
                  <Typography variant="outlineBtnLabel">
                    Add question
                  </Typography>
                </Button>
              </Box>
            </BootstrapTooltip>
          )}
        </Box>
      </Grid>
      <Grid item md={12} className="body-container">
        <Grid container className="table-parent-container">
          <Paper
            elevation={0}
            className="flex-container-column question-paper-container"
          >
            {questionList && questionList.length ? (
              <QuestionTable
                questionList={questionList}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                handleDeleteModalOpen={handleDeleteModalOpen}
                handleQuestionModalOpen={handleQuestionModalOpen}
                questionEditModal={(questionId, questionText) =>
                  handleEditQuestionModalOpen(questionId, questionText)
                }
                canViewActions={
                  (viaTopic && isSpoc) || (viaSession && isAccessible)
                }
                isDisabled={viaTopic ? false : moduleIsConcluded}
                viaSession={viaSession}
                exerciseCount={totalResult}
                handleOpenDisassociate={handleOpenDisassociate}
              />
            ) : (
              <Grid container className="no-data-container">
                <NoDataAvailable
                  imgHeight={4}
                  imgWidth={4.5}
                  message="No Questions Available"
                />
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>

      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        handleDelete={handleDelete}
      />

      <QuestionModal
        handleClose={handleQuestionModalClose}
        isEditQuestion={isEditQuestion}
        open={questionModalOpen}
        questionDetails={questionDetails}
        questionDetailsChange={handleQuestionDetails}
        handleQuestionSubmit={handleQuestionSubmit}
        questionApiError={questionApiError}
        error={error}
      />

      <AssociateQuestions
        handleClose={handleAssociateQuestionsModalClose}
        open={associateQuestionsModalOpen}
        associateQuestionsDetails={associateQuestionsList}
        associateQuestionsChange={(associatedId) =>
          handleAssociateQuestionsDetails(associatedId)
        }
        handleAssociateQuestionSubmit={(selectedQuestions) =>
          handleAssociateQuestionSubmit(selectedQuestions)
        }
        selectedIds={selectedIds}
        selectAllChange={(e) => selectAllChange(e)}
        questionsList={question?.questionList?.data?.results}
        setSelectedIds={setSelectedIds}
      />

      <DisassociateQuestionModal
        open={openQuestionRemove}
        handleClose={handleCloseDisassociate}
        handleRemove={handleDisassociateQuesiton}
      />
    </Grid>
  );
};

export default Questions;
