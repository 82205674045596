import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import "./bootcampModuleView.scss";
import {
  circle_save_change_icon,
  cross_red_icon,
} from "../../constants/icons";
import IconButtons from "../../components/IconButtons/IconButtons";
import {
  checkSpecialChar,
  font_13_icon,
  getNewersWorldProfileUrl,
  isValidUrlFormat,
  isValidString,
} from "../../utils/utils";
import { edit_active_icon } from "./../../constants/icons";
import AutoCompleteTagInput from "../../components/AutoCompleteTagInput/AutoCompleteTagInput";
import TablePagination from "../../components/TablePagination/TablePagination";
import ImageModal from "../../components/ImageModal";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import SimpleAutoComplete from "../../components/SimpleAutoComplete/SimpleAutoComplete";
import dayjs from "dayjs";
import { HelpTexts } from "../../constants/constants";

const { ENTER_VALID_URL } = HelpTexts;

const CreateBatchModal = ({
  open,
  onClose,
  createBatchDetails,
  handleCreateBatchSubmit,
  createBatchErrors,
  createBatchApiError,
  createBatchSpecialChar,
  getBootcampData,
  handleCreateBatchDetailsChange,
  handleCreateBatchDetailsBlur,
  attendeesList,
  checkedRows,
  editName,
  handleEditNameCancel,
  handleEditNameToggle,
  setCheckedRows,
  roomsList,
  loader,
  handleCreateBatchNameAccept,
}) => {
  const sortBootcamps = (getBootcampData) => {
    if (getBootcampData && getBootcampData?.length) {
      let bootcamps = [...getBootcampData];
      return bootcamps.sort((bootcamp1, bootcamp2) => {
        const startDate1 = dayjs(bootcamp1?.startDate);
        const startDate2 = dayjs(bootcamp2?.startDate);
        if (startDate1.isValid() && startDate2.isValid()) {
          if (startDate1.isAfter(startDate2)) {
            return -1;
          } else if (startDate1.isBefore(startDate2)) {
            return 1;
          } else {
            if (
              bootcamp1?.name?.toLowerCase() > bootcamp2.name?.toLowerCase()
            ) {
              return -1;
            } else if (
              bootcamp1?.name?.toLowerCase() < bootcamp2.name?.toLowerCase()
            ) {
              return 1;
            }
          }
        }
        return 0;
      });
    } else {
      return [];
    }
  };
  const modalApiLoad = useSelector((state) => {
    return state?.bootcamp;
  });
  const modalApiLoading = modalApiLoad?.ModalApiLoading;
  const modeOptions = useSelector(
    (state) => state?.utility?.constants?.bootcampModes
  );

  const [imageOpen, setImageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const handleOpen = (imgUrl) => {
    if (isValidString(imgUrl)) {
      setImageUrl(imgUrl);
      setImageOpen(true);
    } else {
      handleClose();
    }
  };
  const handleClose = () => {
    setImageUrl(null);
    setImageOpen(false);
  };

  const handleModalClose = () => {
    onClose();
    setCurrentPage(1);
    setCheckAll(false);
  };

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const totalResult = attendeesList?.totalResults || 0;
  const handlePageChange = (value) => {
    setCurrentPage(value);
  };
  const handleCheckboxChange = (attendee, isChecked) => {
    if (isChecked) {
      setCheckedRows((prev) => [...prev, attendee]);
    } else {
      setCheckedRows((prev) =>
        prev.filter((prevAtt) => prevAtt?.id !== attendee?.id)
      );
    }
  };

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      // Create a set of currently checked IDs
      const checkedIds = new Set(checkedRows?.map((row) => row.id));

      // Add only the attendees who are not already checked
      const newCheckedRows = [
        ...checkedRows,
        ...(tableList || [])?.filter(
          (attendee) => !checkedIds.has(attendee.id)
        ),
      ];
      setCheckAll(true);
      setCheckedRows(newCheckedRows);
    } else {
      // If "select all" is unchecked, remove all attendees in the list from the checked rows
      setCheckAll(false);
      setCheckedRows((prevCheckedRows) =>
        prevCheckedRows.filter(
          (checked) =>
            !(tableList || []).some((attendee) => attendee.id === checked.id)
        )
      );
    }
  };

  const [isCheckAll, setCheckAll] = useState(false);

  useEffect(() => {
    let checkAll = tableList?.every((attendee) =>
      checkedRows?.map((it) => it?.id).includes(attendee?.id)
    );
    if (checkAll) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [currentPage, checkedRows]);

  const tableList = useMemo(() => {
    setTotalPages(Math.ceil(totalResult / 20));
    const firstPageIndex = (currentPage - 1) * 20;
    const lastPageIndex = firstPageIndex + 20;
    return attendeesList?.results?.slice(firstPageIndex, lastPageIndex);
  }, [attendeesList?.results, currentPage, totalResult]);

  return (
    <>
      {(modalApiLoading || loader) && <Loader />}
      <Dialog
        className="modal-drawer-container"
        open={open}
        onClose={handleModalClose}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "1rem",
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            zIndex: 1300,
            maxWidth: "40rem",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="dialog-title">Create Batch</DialogTitle>
        <DialogContent className="dialog-content">
          <Box className="width-100">
            {editName ? (
              <Box className="batch-modal-name">
                <TextField
                  label="Name"
                  color="secondary"
                  fullWidth
                  className="filter-inputs"
                  margin="dense"
                  variant="outlined"
                  size="small"
                  value={createBatchDetails?.name}
                  onChange={(e) => {
                    handleCreateBatchDetailsChange("name", e?.target?.value);
                  }}
                  required={true}
                  error={
                    !createBatchDetails.name ||
                    !!createBatchErrors.name ||
                    createBatchApiError ||
                    checkSpecialChar(createBatchDetails?.name)
                  }
                  helperText={
                    createBatchDetails?.name
                      ? checkSpecialChar(createBatchDetails?.name)
                        ? "Cannot contain special characters"
                        : createBatchErrors.name
                      : "Name is required"
                  }
                  sx={{ marginRight: "1rem" }}
                />
                <IconButtons
                  tooltip="Save Batch Name"
                  width={font_13_icon}
                  height={font_13_icon}
                  image={circle_save_change_icon}
                  handleClick={() => {
                    if (
                      createBatchDetails?.name &&
                      !checkSpecialChar(createBatchDetails?.name)
                    ) {
                      handleCreateBatchNameAccept();
                    }
                  }}
                  classList="padding-right-1"
                />
                <IconButtons
                  tooltip="Cancel Edit Batch Name"
                  width={font_13_icon}
                  height={font_13_icon}
                  image={cross_red_icon}
                  handleClick={handleEditNameCancel}
                />
              </Box>
            ) : (
              <Box className="batch-modal-name" sx={{ marginBottom: "1rem" }}>
                <Typography>{createBatchDetails?.name}</Typography>
                <IconButtons
                  tooltip="Edit Batch Name"
                  width={font_13_icon}
                  height={font_13_icon}
                  image={edit_active_icon}
                  handleClick={handleEditNameToggle}
                />
              </Box>
            )}

            <Box display="flex">
              <Box flexBasis="30%" mr={2}>
                <SimpleAutoComplete
                  label="Mode"
                  placeholder="Select Mode"
                  options={modeOptions}
                  onChange={(event, newValue) => {
                    handleCreateBatchDetailsChange("mode", newValue);
                  }}
                  getOptionLabel={(option) => (option ? option.value : "")}
                  value={createBatchDetails.mode}
                  required={true}
                  error={!!createBatchErrors.mode || createBatchApiError}
                  helperText={createBatchErrors.mode}
                  disableClearable={true}
                  renderOption={(props, option, value) => {
                    return (
                      <li
                        {...props}
                        key={props?.id}
                        className={
                          option?.value === createBatchDetails?.mode?.value
                            ? "multi-tag-edit-list-selected"
                            : "multi-tag-style"
                        }
                      >
                        <Box lineHeight={1.1}>
                          <Box>{`${option?.value}`}</Box>
                        </Box>
                      </li>
                    );
                  }}
                />
              </Box>

              <Box flexBasis="70%" marginLeft="auto">
                {createBatchDetails.mode?.key === "ONLINE" ? (
                  <TextField
                    label="Meeting URL"
                    color="secondary"
                    disabled={createBatchDetails.mode?.key !== "ONLINE"}
                    fullWidth
                    className="filter-inputs"
                    margin="dense"
                    variant="outlined"
                    size="small"
                    required={true}
                    value={createBatchDetails.meetingUrl}
                    onChange={(e) =>
                      handleCreateBatchDetailsChange(
                        "meetingUrl",
                        e.target.value
                      )
                    }
                    error={
                      (createBatchDetails.meetingUrl
                        ? !isValidUrlFormat(createBatchDetails.meetingUrl)
                        : false) ||
                      !!createBatchErrors.meetingUrl ||
                      createBatchApiError
                    }
                    helperText={
                      (createBatchDetails.meetingUrl
                        ? !isValidUrlFormat(createBatchDetails.meetingUrl)
                          ? ENTER_VALID_URL
                          : ""
                        : "") || createBatchErrors.meetingUrl
                    }
                  />
                ) : (
                  <SimpleAutoComplete
                    disabled={createBatchDetails.mode?.key !== "OFFLINE"}
                    label="Meeting Room"
                    placeholder="Select Room"
                    options={roomsList}
                    onChange={(event, newValue) => {
                      handleCreateBatchDetailsChange("room", newValue);
                    }}
                    getOptionLabel={(option) => (option ? option.name : "")}
                    value={createBatchDetails.room}
                    required={true}
                    error={!!createBatchErrors.room || createBatchApiError}
                    helperText={createBatchErrors.room}
                    renderOption={(props, option, value) => {
                      return (
                        <li
                          {...props}
                          key={props?.id}
                          className={
                            option?.id === createBatchDetails?.room?.id
                              ? "multi-tag-edit-list-selected"
                              : "multi-tag-style"
                          }
                        >
                          <Box lineHeight={1.1}>
                            <Box>{`${option?.name}`}</Box>
                          </Box>
                        </li>
                      );
                    }}
                  />
                )}
              </Box>
            </Box>
            <AutoCompleteTagInput
              label="Bootcamps"
              placeholder={
                createBatchDetails?.bootcampIds?.length
                  ? null
                  : "Select Bootcamp"
              }
              getOptionLabel={(option) => option.name}
              options={sortBootcamps(getBootcampData)}
              onChange={(event, newValue) => {
                const idCounts = newValue.reduce((counts, { id }) => {
                  counts[id] = (counts[id] || 0) + 1;
                  return counts;
                }, {});
                const uniqueNewValue = newValue.filter(({ id }) => {
                  return idCounts[id] === 1;
                });
                if (newValue.find((option) => option.all)) {
                  return handleCreateBatchDetailsChange(
                    "bootcampIds",
                    createBatchDetails?.bootcampIds?.length ===
                      getBootcampData?.length
                      ? []
                      : getBootcampData
                  );
                }
                handleCreateBatchDetailsChange("bootcampIds", uniqueNewValue);
              }}
              onBlur={() => {
                const newValue = createBatchDetails?.bootcampIds;
                handleCreateBatchDetailsBlur("bootcampIds", newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return (
                    <Chip
                      {...getTagProps(index)}
                      key={index}
                      label={option.name}
                      onDelete={false}
                    />
                  );
                })
              }
              selectedValue={createBatchDetails?.bootcampIds}
              required={true}
              renderOption={(props, option, { selected }) => {
                // Check if option is "No options" and render accordingly
                if (option.name === "No options") {
                  return (
                    <Box className="auto-complete-no-options">
                      <Box>{option?.name}</Box>
                    </Box>
                  );
                }
                const optionChecked =
                  (option.all &&
                    createBatchDetails?.bootcampIds?.length ===
                      getBootcampData?.length) ||
                  createBatchDetails?.bootcampIds?.find(
                    (e) => e.name === option.name
                  )
                    ? true
                    : false;
                return (
                  <li
                    {...props}
                    key={props?.id}
                    className={
                      optionChecked
                        ? "multi-tag-edit-list-selected"
                        : "multi-tag-style"
                    }
                  >
                    <Box className="multi-tag-input-list-padding">
                      <Checkbox
                        className="auto-complete-checkbox-list"
                        size="small"
                        checked={optionChecked}
                        color="secondary"
                      />
                      {`${option?.name}`}
                    </Box>
                  </li>
                );
              }}
              filterOptions={(options, params) => {
                // <<<--- inject the Select All option
                const filter = createFilterOptions();
                const filtered = filter(options, params);
                if (getBootcampData.length === 0) {
                  return [{ name: "No options", all: false }];
                }
                return [{ name: "Select All", all: true }, ...filtered];
              }}
            />

            {tableList?.length || checkedRows?.length ? (
              <Box sx={{ marginTop: "1rem" }}>
                <Grid container className="create-batch-table">
                  <TableContainer className="table-container create-batch-modal-table-container">
                    <Table stickyHeader>
                      <TableHead className="table-head-container">
                        <TableRow className="table-head-row">
                          <TableCell align="left">
                            <Typography
                              variant="font_13_bold"
                              className="padding-left-1"
                              sx={{ minWidth: "12rem" }}
                            >
                              Name & Email
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="padding-left-1"
                            sx={{ minWidth: "8rem" }}
                          >
                            <Typography variant="font_13_bold">
                              Bootcamp
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant="font_13_bold"
                              className="padding-left-1"
                              sx={{ minWidth: "4rem" }}
                            >
                              <Checkbox
                                color="secondary"
                                label="Select All"
                                checked={isCheckAll}
                                onChange={handleSelectAllChange}
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(tableList && tableList.length
                          ? tableList
                          : checkedRows && checkedRows?.length
                          ? checkedRows
                          : []
                        ).map((attendee, index) => {
                          const name = attendee?.attendee?.attendee?.fullName;
                          const email = attendee?.attendee?.attendee?.email;
                          const profilePictureUrl =
                            attendee?.attendee?.attendee?.profilePictureUrl;

                          const employeeCode =
                            attendee?.attendee?.attendee?.employeeCode;
                          const bootcamp = attendee?.attendee?.bootcamp?.name;
                          const handleCheckbox = (event) => {
                            handleCheckboxChange(
                              attendee,
                              event.target.checked
                            );
                          };
                          const isChecked = checkedRows
                            ?.map((it) => it?.id)
                            .includes(attendee?.id);
                          return (
                            <TableRow className="table-body-row" key={index}>
                              <TableCell
                                align="left"
                                className="padding-left-1"
                              >
                                <Box className="user-cell">
                                  <Avatar
                                    alt="user img"
                                    className="user-avatar"
                                    src={
                                      profilePictureUrl
                                        ? profilePictureUrl
                                        : null
                                    }
                                    onClick={() =>
                                      handleOpen(profilePictureUrl)
                                    }
                                  />
                                  <Box className="user-cell-info">
                                    <Link
                                      href={`${getNewersWorldProfileUrl(
                                        employeeCode
                                      )}`}
                                      target={"_blank"}
                                      rel="noopener noreferrer"
                                      color="inherit"
                                      sx={{
                                        textDecoration: "none",
                                        "&:hover": { textDecoration: "none" },
                                      }}
                                    >
                                      <Typography variant="font_12">
                                        {name}
                                      </Typography>
                                    </Link>
                                    <Typography variant="grayEmail">
                                      {email}
                                    </Typography>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell
                                align="left"
                                className="padding-left-1"
                              >
                                <Typography variant="font_10">
                                  {bootcamp}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="padding-left-1"
                              >
                                <Checkbox
                                  color="secondary"
                                  checked={isChecked}
                                  onChange={handleCheckbox}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container className="table-pagination-container">
                    <Typography variant="font_16">
                      {`Total ${totalResult} ${
                        totalResult > 1 ? "Results" : "Result"
                      }`}
                    </Typography>
                    <TablePagination
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                      currentPage={currentPage}
                    />
                  </Grid>
                  {!!createBatchErrors?.attendeeIds && (
                    <Typography
                      sx={{ paddingLeft: "1rem", marginBottom: "1rem" }}
                      variant="font_12"
                      color="error"
                    >
                      {createBatchErrors?.attendeeIds}
                    </Typography>
                  )}
                </Grid>
              </Box>
            ) : (
              <Grid className="no-data-container">
                <NoDataAvailable
                  imgHeight={4}
                  imgWidth={4.5}
                  message={
                    createBatchDetails?.bootcampIds?.length
                      ? "No Attendees Available"
                      : "No Bootcamp Selected"
                  }
                />
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "0rem 2rem 1.875rem" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleModalClose}
            className="block-button"
          >
            <Typography variant="outlineBtnLabel">Cancel</Typography>
          </Button>
          <Button
            onClick={() => {
              if (
                (createBatchDetails?.meetingUrl
                  ? isValidUrlFormat(createBatchDetails?.meetingUrl)
                  : true) &&
                createBatchDetails?.name &&
                !checkSpecialChar(createBatchDetails?.name)
              ) {
                handleCreateBatchSubmit();
              }
            }}
            variant="contained"
            color="secondary"
            className="block-button"
            disabled={!tableList?.length}
          >
            <Typography variant="outlineBtnLabel">Save</Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <ImageModal
        open={imageOpen}
        handleClose={handleClose}
        imageUrl={imageUrl}
      />
    </>
  );
};

export default CreateBatchModal;
