import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { cross_default_icon } from "../../constants/icons";
import IconButtons from "../../components/IconButtons/IconButtons";
import SimpleAutoComplete from "../../components/SimpleAutoComplete/SimpleAutoComplete";
import Loader from "../../components/Loader/Loader";
import { font_15 } from "../../utils/utils";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const FilterExercises = ({
  open,
  onClose,
  filterExercises,
  handleChangeFilterExercises,
  handleFilterExercisesSubmit,
  handleFilterExercisesRemove,
  bootcampList,
  isAttendee,
  submittedByList,
}) => {
  const [loader, setLoader] = useState(false);

  const onSubmit = async () => {
    setLoader(true);
    await handleFilterExercisesSubmit();
    setLoader(false);
  };

  const sortOptions = useSelector(
    (state) => state?.utility?.constants?.sessionExerciseStatuses
  );
  return (
    <>
      <Drawer
        className="filter-drawer-container"
        anchor="right"
        open={open}
        onClose={onClose}
      >
        <Grid container className="filter-main-container">
          <Grid item md={12} pt={2} className="drawer-input-container">
            <Box className="filter-head-close">
              <IconButtons
                handleClick={onClose}
                height={font_15}
                width={font_15}
                image={cross_default_icon}
                tooltip="Close Filter"
              />
            </Box>
            <Box className="filter-head filter-padding">
              <Typography variant="font_16" className="filter-title ">
                Filters
              </Typography>
            </Box>
            <Box
              className="filter-padding textfield-box"
              sx={{
                width: "100%",
              }}
            >
              <SimpleAutoComplete
                label="Status"
                placeholder="Select Status"
                options={sortOptions}
                onChange={(event, newValue) =>
                  handleChangeFilterExercises("status", newValue)
                }
                getOptionLabel={(option) => option?.value}
                value={filterExercises?.status}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.value === filterExercises?.status?.value
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.value}`}</Box>
                      </Box>
                    </li>
                  );
                }}
              />
              <Box mb={1} mt={1} className="width-100">
                <SimpleAutoComplete
                  label="Bootcamp"
                  placeholder="Select Bootcamp"
                  options={bootcampList}
                  onChange={(event, newValue) =>
                    handleChangeFilterExercises("bootcampId", newValue)
                  }
                  getOptionLabel={(option) => option?.name}
                  value={filterExercises?.bootcampId}
                  renderOption={(props, option, value) => {
                    return (
                      <li
                        {...props}
                        key={props?.id}
                        className={
                          option?.id === filterExercises?.bootcampId?.id
                            ? "multi-tag-edit-list-selected"
                            : "multi-tag-style"
                        }
                      >
                        <Box className="flex-options">
                          <Box>{option?.name}</Box>
                          <Box>
                            {option?.startDate
                              ? dayjs(option?.startDate)?.format("DD/MM/YYYY")
                              : "-"}
                          </Box>
                        </Box>
                      </li>
                    );
                  }}
                  tooltipTitle={filterExercises?.bootcampId?.name}
                  disableClearable={true}
                />
              </Box>
              <Box mb={1} mt={1} className="width-100">
                <SimpleAutoComplete
                  label="Module"
                  placeholder="Select Module"
                  disabled={!filterExercises?.bootcampId}
                  options={filterExercises?.bootcampId?.modules?.results}
                  onChange={(event, newValue) =>
                    handleChangeFilterExercises("moduleId", newValue)
                  }
                  getOptionLabel={(option) => option?.name}
                  value={filterExercises?.moduleId}
                  renderOption={(props, option, value) => {
                    return (
                      <li
                        {...props}
                        key={props?.id}
                        className={
                          option?.id === filterExercises?.moduleId?.id
                            ? "multi-tag-edit-list-selected"
                            : "multi-tag-style"
                        }
                      >
                        <Box className="flex-options">
                          <Box>{option?.name}</Box>
                          <Box>
                            {dayjs(option?.startDate)?.format("DD/MM/YYYY")}
                          </Box>
                        </Box>
                      </li>
                    );
                  }}
                />
              </Box>
              <Box mb={1} mt={1} className="width-100">
                <SimpleAutoComplete
                  label="Session"
                  placeholder="Select Session"
                  disabled={!filterExercises?.moduleId}
                  options={filterExercises?.moduleId?.sessions?.results}
                  onChange={(event, newValue) =>
                    handleChangeFilterExercises("sessionId", newValue)
                  }
                  getOptionLabel={(option) => option?.name}
                  value={filterExercises?.sessionId}
                  renderOption={(props, option, value) => {
                    return (
                      <li
                        {...props}
                        key={props?.id}
                        className={
                          option?.id === filterExercises?.sessionId?.id
                            ? "multi-tag-edit-list-selected"
                            : "multi-tag-style"
                        }
                      >
                        <Box className="flex-options">
                          <Box>{option?.name}</Box>
                          <Box>
                            {dayjs(option?.startDate)?.format("DD/MM/YYYY")}
                          </Box>
                        </Box>
                      </li>
                    );
                  }}
                />
              </Box>
              {!isAttendee && (
                <Box mb={1} mt={1} className="width-100">
                  <SimpleAutoComplete
                    label="Submitted By"
                    placeholder="Select Attendee"
                    options={submittedByList}
                    onChange={(event, newValue) =>
                      handleChangeFilterExercises("submittedById", newValue)
                    }
                    getOptionLabel={(option) => option?.fullName || ""}
                    value={filterExercises?.submittedById}
                    renderOption={(props, option, value) => {
                      return (
                        <li
                          {...props}
                          key={props?.id}
                          className={
                            option?.id === filterExercises?.submittedById?.id
                              ? "multi-tag-edit-list-selected"
                              : "multi-tag-style"
                          }
                        >
                          <Box lineHeight={1.1}>
                            <Box>{`${option?.fullName}`}</Box>
                          </Box>
                        </li>
                      );
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item md={12} className="filter-padding filter-btn-container">
            <Button
              onClick={onSubmit}
              variant="contained"
              color="secondary"
              className="block-button filter-btn margin-right-1"
              disabled={loader}
            >
              {loader ? (
                <Loader />
              ) : (
                <Typography variant="outlineBtnLabel">Apply Filters</Typography>
              )}
            </Button>

            <Button
              onClick={handleFilterExercisesRemove}
              variant="outlined"
              color="secondary"
              className="block-button filter-btn"
            >
              <Typography variant="outlineBtnLabel">Reset</Typography>
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

export default FilterExercises;
