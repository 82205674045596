import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import {
  addBootcampModulesApi,
  deleteBootcampModuleApi,
  editBootcampModuleApi,
  getBootcampModulesApi,
} from "../services/bootcampModule";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";
import CustomToaster from "../components/Toaster";

const initialState = {
  bootcampModule: [],
  loading: false,
  addBootcampModuleLoading: false,
  editBootcampModuleLoading: false,
  error: null,
};

export const getBootcampModule = createAsyncThunk(
  "getBootcampModule",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBootcampModulesApi(data);
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const addBootcampModule = createAsyncThunk(
  "addBootcampModule",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await addBootcampModulesApi(data);
      toast.custom(
        <CustomToaster
          message="Bootcamp Module Added Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBootcampModule = createAsyncThunk(
  "deleteBootcampModule",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await deleteBootcampModuleApi(data);
      toast.custom(
        <CustomToaster
          message="Bootcamp Module Deleted Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editBootcampModule = createAsyncThunk(
  "editBootcampModule",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await editBootcampModuleApi(data);
      toast.custom(
        <CustomToaster
          message="Bootcamp Module Updated Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bootcampModule = createSlice({
  name: "bootcampModule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBootcampModule.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBootcampModule.fulfilled, (state, action) => {
        state.loading = false;
        state.bootcampModule = action.payload;
      })
      .addCase(getBootcampModule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addBootcampModule.pending, (state) => {
        state.addBootcampModuleLoading = true;
      })
      .addCase(addBootcampModule.fulfilled, (state, action) => {
        state.addBootcampModuleLoading = false;
        state.bootcampModule = action.payload;
      })
      .addCase(addBootcampModule.rejected, (state, action) => {
        state.addBootcampModuleLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteBootcampModule.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBootcampModule.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteBootcampModule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editBootcampModule.pending, (state) => {
        state.editBootcampModuleLoading = true;
      })
      .addCase(editBootcampModule.fulfilled, (state) => {
        state.editBootcampModuleLoading = false;
      })
      .addCase(editBootcampModule.rejected, (state, action) => {
        state.editBootcampModuleLoading = false;
        state.error = action.payload;
      });
  },
});

export default bootcampModule.reducer;
