import React, { useState } from "react";
import { Autocomplete, TextField, Stack } from "@mui/material";

const AutoCompleteTagInput = ({
  options,
  label,
  placeholder,
  selectedValue,
  disabled,
  getOptionLabel,
  onChange,
  onBlur,
  renderOption,
  error,
  helperText,
  required,
  filterOptions,
  loading,
  renderTags,
  getOptionDisabled,
  disableClearable,
}) => {
  const [initialValue, setInitialValue] = useState(selectedValue);
  return (
    <Stack spacing={3} className="width-100">
      <Autocomplete
        disabled={disabled}
        filterOptions={filterOptions}
        className="width-100"
        multiple
        size="small"
        color="secondary"
        disableCloseOnSelect
        options={options || []}
        value={selectedValue}
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        {...(onBlur && {
          onFocus: () => {
            setInitialValue(selectedValue);
          },
          onBlur: () => {
            const hasChanged =
              JSON.stringify(initialValue) !== JSON.stringify(selectedValue);
            if (hasChanged) {
              onBlur(selectedValue);
            }
          },
        })}
        getOptionDisabled={getOptionDisabled}
        renderTags={renderTags}
        renderOption={renderOption}
        componentsProps={{ paper: { sx: { wordBreak: "break-word" } } }}
        renderInput={(params) => (
          <TextField
            {...params}
            color="secondary"
            label={label}
            placeholder={placeholder}
            margin="dense"
            size="small"
            required={required}
            error={error}
            helperText={helperText}
          />
        )}
        loading={loading}
        disableClearable={disableClearable}
      />
    </Stack>
  );
};

export default AutoCompleteTagInput;
